const OdemeveTeslimat = (props) => {
    return (
        <div className="container">
            <div className="lh-lg py-5">
                <h2 className="fw-bold mb-4">Ödeme ve Teslimat</h2>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">Ödeme</h4>
                    <li>
                        Vadeli satışların sadece bankalara ait kredi kartları
                        ile yapılması nedeniyle, ilgili faiz oranlarını ve
                        temerrüt faizi ile ilgili bilgileri bankasından ayrıca
                        teyit edeceğini, yürürlükte bulunan mevzuat hükümleri
                        gereğince faiz ve temerrüt faizi ile ilgili hükümlerin
                        banka ve ALICI arasındaki Kredi Kartı Sözleşmesi
                        kapsamında uygulanacağını kabul, beyan ve taahhüt eder.
                    </li>
                    <li>
                        Taksitlendirme işlemlerinde, müşteri ve banka arasında
                        imzalamış bulunan sözleşmenin ilgili hükümleri
                        geçerlidir. Kredi kartı ödeme tarihi banka ile
                        aranızdaki sözleşme hükümlerince belirlenir. Bankanın
                        tarafınıza gönderdiği hesap özetinden taksit sayısını ve
                        ödemelerini takip edebilirsiniz.
                    </li>
                </div>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">Sevkiyat</h4>
                    <li>
                        Sipariş onayı mailinin gönderilmesi, stoğun müsait
                        olması ve ürün bedelinin ödenmesi ile, ürün/ürünler
                        anlaşmalı olduğumuz kargo şirketine verilir ve
                        tarafınızdan belirlenmiş olan adrese teslim edilir.
                    </li>
                    <li>
                        Alışveriş sepetine eklenen ürünlerin KDV dahil TL tutarı
                        (taksitli işlemlerde toplam taksit tutarları) müşteri
                        tarafından onaylandıktan sonra, ilgili banka kartının
                        posu üzerinden işleme alınır. Bu nedenle siparişler,
                        sevk edilmeden önce müşteriye sipariş onay maili
                        gönderilir. Sipariş onay maili gönderilmeden sevkiyat
                        yapılmaz.
                    </li>
                </div>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">Teslimat</h4>
                    <li>
                        Siparişiniz en geç 24 saat içerisinde hazırlanır (Hafta
                        sonları hariç), faturası düzenlenir ve ardından kargo
                        firmasına teslim edilir.
                    </li>
                    <li>
                        Siparişiniz kargo firmasına teslim edildiği gün içinde,
                        saat 18:00'dan sonra e-posta adresinize kargo takip
                        detayları iletilir.
                    </li>
                    <li>
                        Ürünlerinizin teslimat süresi, teslimatın yapılacağı
                        adrese ve kargo firmasının teslimat süresine göre
                        değişkenlik gösterebilir. Kargonuzun durumunu size
                        gönderilen e-posta'daki kargo takip linki üzerinden
                        takip edebilirsiniz.
                    </li>
                    <li>
                        Kargo masrafı müşteri tarafından ödenir. Ancak ücretsiz
                        kargo kampanyalarında tarafımıza aittir.
                    </li>
                    <li>
                        Ürünler, kargo şirketinin adres teslimatı yapmadığı
                        bölgelere telefon ihbarlı olarak gönderilir.
                    </li>
                    <li>
                        Teslim anında adresinizde olmamanız durumunda, edimini
                        tam ve eksiksiz olarak yerine getirmiş kabul
                        edilecektir. Tarafımız sorumlu değildir.
                    </li>
                    <li>
                        Ürün başka bir kişi/kuruluşa teslim edilecek ise, teslim
                        edilecek kişi/kuruluşun teslimatı kabul etmemesinden
                        tarafımız sorumlu değildir. Bu nedenle, ürünü geç teslim
                        almanızdan kaynaklanan her türlü zarar ile ürünün kargo
                        şirketinde beklemiş olması ve/veya kargonun bize iade
                        edilmesinden dolayı oluşan giderler de size aittir.
                    </li>
                    <li>
                        Kargo şirketinin haftada bir gün teslimat yaptığı
                        bölgelerde, sevk bilgilerindeki yanlışlık ve eksiklik
                        olduğu hallerde, bazı sosyal olaylar ve doğal afetler
                        gibi durumlarda belirtilen gün süresinde sarkma
                        olabilir. Bu sarkmalardan dolayı herhangi bir
                        sorumluluğumuz yoktur.
                    </li>
                    <li>
                        Belirtilen günler içeriğinde ürün/ürünler elinize
                        ulaşmadıysa teslimat problemleri için{' '}
                        <abbr title="takssiparis@tesan.com.tr">
                            takssiparis@tesan.com.tr
                        </abbr>{' '}
                        e-posta adresi üzerinden tarafımıza bildirebilirsiniz.
                    </li>
                    <li>
                        Zarar görmüş paket durumunda; zarar görmüş paketler
                        teslim alınmayarak kargo şirketi yetkilisine tutanak
                        tutturulmalıdır.
                    </li>
                    <li>
                        Eğer teslimatçı firma yetkilisi paketin hasarlı
                        olmadığını düşünüyorsa, orada paketin açılarak ürünlerin
                        hasarsız teslim edildiğini kontrol ettirme ve durumun
                        yine bir tutanakla tespit edilmesini isteme hakkınız
                        vardır. Ancak paket tarafınızdan teslim alındıktan sonra
                        teslimatçı firmanın görevini layıkıyla yaptığı kabul
                        edilmiş sayılır.
                    </li>
                    <li>
                        Paket kabul edilmemiş ve tutanak tutulmuş ise, durum
                        tutanağın sizde kalan kopyasıyla birlikte en kısa
                        zamanda bize bildirilmelidir.
                    </li>
                </div>
            </div>
        </div>
    );
};
export default OdemeveTeslimat;
