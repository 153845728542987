import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

const FeaturedProductContent = (props) => {
    const productIdHandle = (id) => {
        props.openedProductId(id);
    };
    const currentProductMapping = props;
    const prices =
        currentProductMapping.product.prices &&
        currentProductMapping.product.prices.filter((p) => p.type === 1);
    const discountedPrices =
        currentProductMapping.product.prices &&
        currentProductMapping.product.prices.filter((p) => p.type === 2);
    const price = prices[0] && prices[0].value;
    var discountedPrice =
        discountedPrices[0] && discountedPrices[0].value != null
            ? discountedPrices[0].value
            : price;

    if (props && props.userInfo && props.userInfo.isEmployee == true) {
        const personalPrice = currentProductMapping.product.prices.filter(
            (p) => p.type === 3
        );
        // discountedPrice = prices[0].value / 2;
        discountedPrice = personalPrice[0].value;
    }

    var discountPercent = 0;
    if (discountedPrice) {
        discountPercent = ((price - discountedPrice) / price) * 100;
    }
    var priceItems = '';
    if (discountedPrice !== price) {
        priceItems = (
            <div className="price isdiscount flex-grow-1 flex-shrink-0">
                <div className="priceGroup">
                    <div className="text-danger small">
                        <strong>%{discountPercent.toFixed(0)}</strong> İNDİRİM
                    </div>
                    <div className="mainprice text-dark">
                        <span>{price.toFixed(2)} TL</span>
                    </div>
                    <div className="discountprice">
                        <span>{discountedPrice.toFixed(2)} TL</span>
                    </div>
                </div>
            </div>
        );
    } else {
        priceItems = (
            <div className="price flex-grow-1 flex-shrink-0">
                <div className="mainprice text-dark">
                    <span>{price.toFixed(2)} TL</span>
                </div>
            </div>
        );
    }
    //Variants
    var variantsIdList = [];
    var variantItems = [];
    currentProductMapping.product.productProductAttributeValueMappings &&
        currentProductMapping.product.productProductAttributeValueMappings
            .sort((a, b) =>
                a.productAttributeValueId > b.productAttributeValueId ? 1 : -1
            )
            .sort((a) => (a.productAttributeValueId == 38 ? -1 : 1))
            .filter(
                (pavm) =>
                    pavm.productId === currentProductMapping.product.id &&
                    pavm.productAttributeValue.productAttribute.name ===
                        'Colors' &&
                    pavm.isActive == true
            )
            .map((pavm) => {
                if (
                    variantsIdList.indexOf(pavm.productAttributeValue.id) === -1
                ) {
                    variantsIdList.push(pavm.productAttributeValue.id);
                    variantItems.push(
                        <span
                            key={pavm.id}
                            className="variantItem"
                            style={{
                                backgroundColor:
                                    pavm.productAttributeValue.hexCode
                            }}
                            title={pavm.productAttributeValue.value}
                        ></span>
                    );
                }
            });

    const [productState, setProductState] = useState({});
    useEffect(() => {
        setProductState({ ...props.product });
    }, []);

    var productPictureSrc = '';
    var pic =
        productState.productPictureMappings &&
        productState.productPictureMappings[0] &&
        productState.productPictureMappings[0].picture;
    if (pic) {
        productPictureSrc = `${pic.virtualPath}`;
    } else {
        productPictureSrc = '/assets/images/noimage.jpg';
    }
    return (
        <div className="scroll-snap-item">
            <div className="product-item overflow-hidden rounded-3 h-100">
                {productState.productPictureMappings &&
                    (productState.productPictureMappings[0] != null ? (
                        <div className="image">
                            <Link
                                to={`detail/${productState.id}`}
                                role="button"
                                title={productState.name}
                            >
                                <img
                                    className="img-fluid"
                                    src={productPictureSrc}
                                    alt={productState.name}
                                />
                                {variantItems.length > 1 ? (
                                    <div className="isVariant colors circle">
                                        {variantItems}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Link>
                            <button
                                className="quick-add-to-cart-button"
                                //data-bs-toggle="modal"
                                data-bs-target="#quickAddModal"
                                id={`product-${productState.id}`}
                                onClick={() => {
                                    productIdHandle(productState.id);
                                }}
                            >
                                <span className="text">Sepete Ekle</span>
                                <span className="icon">
                                    <i className="bi bi-cart3 h4"></i>
                                </span>
                            </button>
                        </div>
                    ) : (
                        <div className="image">
                            <Link
                                to={`detail/${productState.id}`}
                                role="button"
                                title={productState.name}
                            >
                                <img
                                    className="img-fluid"
                                    src="assets/images/noimage.jpg"
                                    alt={productState.name}
                                />
                                {variantItems.length > 1 ? (
                                    <div className="isVariant colors circle">
                                        {variantItems}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </Link>
                            <button
                                className="quick-add-to-cart-button"
                                //data-bs-toggle="modal"
                                data-bs-target="#quickAddModal"
                                id={`product-${productState.id}`}
                                onClick={() => {
                                    productIdHandle(productState.id);
                                }}
                            >
                                <span className="text">Sepete Ekle</span>
                                <span className="icon">
                                    <i className="bi bi-cart3 h4"></i>
                                </span>
                            </button>
                        </div>
                    ))}

                <div className="detail d-flex flex-sm-row flex-column gap-3 align-items-start">
                    <h6 className="title w-100">
                        <Link
                            to={`detail/${productState.id}`}
                            role="button"
                            title={productState.name}
                        >
                            {productState.name}
                        </Link>
                    </h6>
                    {priceItems}
                </div>
            </div>
        </div>
    );
};
export default FeaturedProductContent;
