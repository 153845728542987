import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE } from '../../config/env';

const PersonalInfoNavigation = (props) => {
    const [locationPath, setLocationPath] = useState('');
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        setLocationPath(window.location.pathname);
        const userToken = localStorage.getItem('token');
        if (userToken) {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            Axios.get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            ).then((data) => {
                setUserInfo(data.data.set);
                if (data.data) {
                    if (data.data.code === '201') {
                        window.location.href = '/Login';
                    } else if (data.data.code === '200') {
                    }
                }
            });
        } else {
            window.location.href = '/Login';
        }
    }, [props]);
    const clickLogout = () => {
        localStorage.setItem('token', '');
        window.location.href = '/Login';
    };
    return (
        <div className="col-sm-3">
            <div className="personalNavigation bg-white rounded-2 overflow-hidden">
                <header className="p-3 p-sm-5">
                    <div className="d-flex flex-column gap-1 h5">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="mb-2 text-black-50">
                                Hoşgeldiniz
                            </span>
                            <button
                                onClick={() => clickLogout()}
                                className="btn box-light btn-sm"
                            >
                                <i className="bi bi-box-arrow-right me-2"></i>
                                Çıkış
                            </button>
                        </div>

                        <span className="text-primary fw-bold">
                            <a href="/Profile">
                                {userInfo &&
                                    userInfo.firstName +
                                        ' ' +
                                        userInfo.lastname}
                            </a>
                        </span>
                    </div>
                </header>
                {/* <small>Son Giriş: 14-05-2021 21:02:50</small> */}
                <nav className="navbar navbar-expand-sm mx-3 mb-3 m-sm-0">
                    <div className="container px-sm-0 pb-sm-5 pb-0 px-4">
                        <button
                            className="navbar-toggler align-items-center"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#userContentMenu"
                            aria-controls="userContentMenu"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="bi bi-person-lines-fill h3 text-black-50 me-2"></i>
                            <span className="fw-bold text-black-50">
                                ÜYE MENÜSÜ
                            </span>
                        </button>
                        <div
                            className="collapse navbar-collapse"
                            id="userContentMenu"
                        >
                            <ul className="navbar-nav">
                                <li
                                    className={`nav-item fw-bold ${
                                        locationPath === '/PersonalInfo'
                                            ? 'active'
                                            : ''
                                    }`}
                                    aria-current="true"
                                >
                                    <i className="bi bi-person text-black-50 me-1 h4"></i>
                                    <a
                                        href="/PersonalInfo"
                                        className="text-black-50 nav-link"
                                    >
                                        Üye Bilgilerim
                                    </a>
                                </li>
                                <li
                                    className={`nav-item fw-bold ${
                                        locationPath === '/MyAddressList'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <i className="bi bi-journal-text text-black-50 me-1 h4"></i>
                                    <a
                                        href="/MyAddressList"
                                        className="text-black-50 nav-link"
                                    >
                                        Adres Defteri
                                    </a>
                                </li>
                                <li
                                    className={`nav-item fw-bold ${
                                        locationPath === '/Basket'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <i className="bi bi-cart text-black-50 me-1 h4"></i>
                                    <a
                                        href="/Basket"
                                        className="text-black-50 nav-link"
                                    >
                                        Alışveriş Sepetim
                                    </a>
                                </li>
                                <li
                                    className={`nav-item fw-bold ${
                                        locationPath === '/MyOrders'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    <i className="bi bi-bag-check text-black-50 me-1 h4"></i>
                                    <a
                                        href="/MyOrders"
                                        className="text-black-50 nav-link"
                                    >
                                        Siparişlerim
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};
export default PersonalInfoNavigation;
