import {
    IconGift,
    IconLockSquareRounded,
    IconSquareRoundedPercentage,
    IconTruckDelivery
} from '@tabler/icons-react';

import { cargoPriceFree } from '../../config/env';

const SiteAdvantage = (props) => {
    return (
        <section className="site-advantage">
            <div className="container">
                <div className="row">
                    <div className="col d-none d-sm-block">
                        <div className="d-flex flex-column gap-4">
                            <h1 className="fw-bold text-dark">taks</h1>
                            <p className="text-wrap h6 lh-lg">
                                2018 yılında İstanbul'da doğan taks, uygun fiyat
                                ve fonksiyonelliği birleştiren kaliteli mobil
                                aksesuarları üstün hizmet anlayışı ile
                                müşterilerine sunmayı misyon edinmiştir.
                                <br />
                                <br /> taks, kurulduğu 1983 yılından bu yana
                                müşteri odaklı ve yenilikçi vizyonuyla
                                Türkiye’nin katma değerli teknoloji distribütörü
                                TESAN İLETİŞİM A.Ş’nin markasıdır.
                            </p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row g-2 g-sm-4">
                            <div className="col-sm-6">
                                <div className="advantage border border-light">
                                    <IconGift
                                        size={64}
                                        className="text-secondary"
                                        strokeWidth={1}
                                    />

                                    <div className="data">
                                        <div className="title text-dark">
                                            ÜCRETSİZ KARGO
                                        </div>
                                        <div className="description text-secondary">
                                            {cargoPriceFree} TL ve üzeri
                                            alışverişlerde KARGO BEDAVA!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="advantage border border-light">
                                    <IconSquareRoundedPercentage
                                        size={64}
                                        className="text-secondary"
                                        strokeWidth={1}
                                    />
                                    <div className="data">
                                        <div className="title text-dark">
                                            TAKSİT SEÇENEĞİ
                                        </div>
                                        <div className="description text-secondary">
                                            Tüm alışverişlerde vade farksız 6
                                            taksite kadar ödeme imkanı.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="advantage border border-light">
                                    <IconTruckDelivery
                                        size={64}
                                        className="text-secondary"
                                        strokeWidth={1}
                                    />
                                    <div className="data">
                                        <div className="title text-dark">
                                            24 SAATTE KARGODA
                                        </div>
                                        <div className="description text-secondary">
                                            Siparişleriniz en geç (Hafta Sonu
                                            hariç) 24 saat içerisinde kargoya
                                            verilir.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="advantage border border-light">
                                    <IconLockSquareRounded
                                        size={64}
                                        className="text-secondary"
                                        strokeWidth={1}
                                    />
                                    <div className="data">
                                        <div className="title text-dark">
                                            GÜVENLİ ÖDEME
                                        </div>
                                        <div className="description text-secondary">
                                            256 Bit SSL şifreleme ile tüm
                                            ödemeleriniz güvenli.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SiteAdvantage;
