import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getBasket } from '../../redux/actions/basket';

import NavHeader from './NavHeader';
import NavBody from './NavBody';

const Navigation = (props) => {
    const openSearch = () => {
        props.openSearch();
    };
    return (
        <Fragment>
            <NavHeader openSearch={() => openSearch()} />
            {/* <NavBody /> */}
        </Fragment>
    );
};
const mapDispatchToProps = {
    getBasket
};
const mapStateToProps = (state) => ({
    basket: state.basket
});
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
