import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE, tesanCDNURL } from '../../config/env';

const SearchProducts = (props) => {
    const [searchData, setSearchData] = useState({
        searchWord: '',
        isSearch: false,
        products: []
    });
    const [displayProp, setDisplayProp] = useState('none');
    // const searchedProduct = props.searchData.products;
    // const isSearch = props.searchData.isSearch;
    const closeSearch = () => {
        props.closeSearch();
    };
    const handleChange = async (e) => {
        const word = e.target.value;
        setSearchData({
            searchWord: word,
            isSearch: word !== ''
        });

        if (word !== '' && word.length >= 3) {
            axios
                .post(`${API_BASE}Product/SearchProductByName`, {
                    Name: word
                })
                .then((data) => {
                    setSearchData({
                        isSearch: true,
                        searchWord: word,
                        products: data.data.set
                    });
                    console.log(data.data);
                });
        }
    };
    const getProductPrice = (prices) => {
        const normalPrice = prices.filter((price) => price.type == 1);
        const discountedPrice = prices.filter((price) => price.type == 2);
        console.log(
            normalPrice.length > 0 ? normalPrice[0].value : '',
            discountedPrice.length > 0 ? discountedPrice[0].value : ''
        );
        return discountedPrice.length > 0
            ? discountedPrice[0].value.toFixed(2)
            : normalPrice[0].value.toFixed(2);
    };
    useEffect(() => {
        // console.log("Gelen değer:", props.display);
        setDisplayProp(props.display);
    }, [props.display]);
    return (
        <>
            <div
                id="overlaySearch"
                className="search-form"
                style={{ display: displayProp }}
            >
                <span
                    type="button"
                    className="close p-0 border-0"
                    onClick={() => closeSearch()}
                >
                    <i className="bi bi-x-circle"></i>
                </span>
                <div className="search-form-content container">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control border-0 px-4 shadow"
                            placeholder="Ürün adı, stok kodu veya özellik yazarak arama yapabilirsiniz..."
                            aria-label="Ürün adı, stok kodu veya özellik yazarak arama yapabilirsiniz..."
                            aria-describedby="search-product"
                            onChange={handleChange}
                            autoFocus
                        />
                        <button
                            className="btn btn-outline-secondary d-none"
                            type="submit"
                            id="quickSearchForm"
                        >
                            <i className="bi bi-search"></i>
                        </button>
                    </div>
                    {searchData &&
                    searchData.products &&
                    searchData.products.length > 0 ? (
                        <div className="quick-search-results rounded-bottom pt-5 px-4 pb-4 bg-light">
                            <div className="row g-2">
                                {searchData &&
                                    searchData.products &&
                                    searchData.products.map((prod) => (
                                        <div key={prod.id} className="col-md-6">
                                            <div className="bg-white rounded-3 p-3">
                                                <a
                                                    href={`/detail/${prod.id}`}
                                                    title={prod.name}
                                                >
                                                    <div className="product hovered">
                                                        <div className="row align-items-center">
                                                            <div className="col-4 col-md-3">
                                                                <div className="image">
                                                                    <img
                                                                        src={
                                                                            prod
                                                                                .productPictureMappings
                                                                                .length >
                                                                            0
                                                                                ? `${prod.productPictureMappings[0].picture.virtualPath}`
                                                                                : 'assets/images/noimages.jpg'
                                                                        }
                                                                        className="img-fluid"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-8 col-md-9">
                                                                <div className="text-start">
                                                                    <h6 className="fw-bold mb-1 halfclipped">
                                                                        {
                                                                            prod.name
                                                                        }
                                                                    </h6>
                                                                </div>
                                                                {/* <div className="description h6 fw-normal mb-2 text-black-50 text-start">
                                <span>{prod.shortDescription}</span>
                              </div> */}
                                                                <div className="h6 text-start">
                                                                    <div className="price text-color-dark fw-bold">
                                                                        {getProductPrice(
                                                                            prod.prices
                                                                        )}{' '}
                                                                        TL
                                                                        {/* {prod.prices.length > 0
                                    ? (prod.prices
                                        .filter((p) => p.type == 1)[0]
                                        .value.toFixed(2))
                                    : ""}{" "} */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </>
    );
};

export default SearchProducts;
