import axios from 'axios';
import { API_BASE } from '../../config/env';
export const FETCH_BASKET_PENDING = "FETCH_BASKET_PENDING";
export const FETCH_BASKET_FULFILLED = "FETCH_BASKET_FULFILLED";

export function getBasket() {
    return dispatch => {
        dispatch({
            type: "FETCH_BASKET",
            payload: axios.get(`${API_BASE}Basket/GetBasket`)
                .then(data => data.data.set)
        })

    }
}