import React, { PureComponent } from 'react'

export default class Iletisim extends PureComponent {
    render() {
        return (
            <div>
                İletişim Sayfası
            </div>
        )
    }
}
