import React, { useEffect, useState } from 'react';
import { API_BASE, tesanCDNURL } from '../../config/env';
import axios from 'axios';

function MiniBanner() {
    const [slidersAndBanners, setSlidersAndBanners] = useState([]);
    useEffect(() => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(`${API_BASE}ContentManagemenet/GetAllContent`, config)
            .then((data) => {
                //console.log(data.data.set);
                setSlidersAndBanners(data.data.set);
            });
    }, []);

    return (
        <section className="banner-section">
            <div className="container">
                <div className="row row-cols-4 g-2 scroll-snap">
                    {slidersAndBanners
                        .filter((s) => s.contentTypeId == 2)
                        .map((s, index) => {
                            return (
                                <div key={s.id} className="scroll-snap-item">
                                    <div className="banner">
                                        <a href={s.link}>
                                            <img
                                                src={`${tesanCDNURL}${s.imageUrl}`}
                                                className="img-fluid"
                                                alt={s.title}
                                                data-bannerid={s.id}
                                            />
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </section>
    );
}

export default MiniBanner;
