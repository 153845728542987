import React, { useEffect, useState, memo } from 'react';
import { API_BASE, tesanCDNURL } from '../../config/env';
import axios from 'axios';
import { BrowserView, MobileView } from 'react-device-detect';
const SliderBanner = (props) => {
    const [slidersAndBanners, setSlidersAndBanners] = useState([]);
    useEffect(() => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(`${API_BASE}ContentManagemenet/GetAllContent`, config)
            .then((data) => {
                //console.log(data.data.set);
                setSlidersAndBanners(data.data.set);
            });
    }, []);

    return (
        <section className="main-carousel bg-light">
            <div
                id="mainCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
            >
                <div className="carousel-indicators">
                    <div className="indicator-wrapper">
                        {slidersAndBanners
                            .filter((s) => s.contentTypeId == 1)
                            .map((s, i) =>
                                i == 0 ? (
                                    <button
                                        key={i}
                                        type="button"
                                        data-bs-target="#mainCarousel"
                                        data-bs-slide-to="0"
                                        className="active rounded-circle"
                                        aria-current="true"
                                        aria-label="Slide 1"
                                    ></button>
                                ) : (
                                    <button
                                        key={i}
                                        type="button"
                                        className="rounded-circle"
                                        data-bs-target="#mainCarousel"
                                        data-bs-slide-to={`${i}`}
                                    ></button>
                                )
                            )}
                    </div>
                </div>
                <div className="carousel-inner">
                    {slidersAndBanners
                        .filter((s) => s.contentTypeId == 1)
                        .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                        .map((s, i) => (
                            <div
                                key={s.id}
                                className={`main-carousel-item carousel-item ${
                                    i == 0 ? 'active' : ''
                                }`}
                            >
                                <a href={s.link}>
                                    <BrowserView>
                                        <img
                                            src={`${tesanCDNURL}${s.imageUrl}`}
                                            className="img-fluid d-sm-block d-none"
                                            alt={s.title}
                                        />
                                    </BrowserView>
                                    <MobileView>
                                        <img
                                            src={`${tesanCDNURL}${s.imageUrl}`}
                                            className="img-fluid d-sm-none d-block"
                                            alt={`${s.title}-mobile`}
                                        />
                                    </MobileView>
                                </a>
                            </div>
                        ))}
                </div>
                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#mainCarousel"
                    data-bs-slide="prev"
                >
                    <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Önceki</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#mainCarousel"
                    data-bs-slide="next"
                >
                    <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Sonraki</span>
                </button>
            </div>
        </section>
    );
};
export default memo(SliderBanner);
