import axios from 'axios';
import { API_BASE } from '../../config/env';

export const FETCH_HOMEPAGEPRODUCTS_FULFILLED = "FETCH_HOMEPAGEPRODUCTS_FULFILLED";
export const FETCH_HOMEPAGECAETGORIES_FULFILLED = "FETCH_HOMEPAGECAETGORIES_FULFILLED";

export function fetchHomepageProducts() {
    return dispatch => {
        dispatch({
            type: "FETCH_HOMEPAGEPRODUCTS",
            payload: axios.get(`${API_BASE}HomePage/GetHomePageProducts`)
                .then(data => data.data.set)
        })

    }
}
export function fetchHomePageCategories() {
    return dispatch => {
        dispatch({
            type: "FETCH_HOMEPAGECATEGORIES",
            payload: axios.get(`${API_BASE}HomePage/GetHomePageCategories`)
        })
    }
}