import { memo, useEffect, useState } from 'react';

import { API_BASE } from '../../config/env';
import Axios from 'axios';
import { BrowserView } from 'react-device-detect';
import { CookieBanner } from 'reactjs-cookie-consent';
import { IconHeadset } from '@tabler/icons-react';
import { MobileView } from 'react-device-detect';

const Footer = (props) => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        Axios.get(`${API_BASE}Category/GetAllCategories`).then((data) =>
            setCategories(data.data.set)
        );
    }, []);

    return (
        <footer className="main-footer border-light">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 mb-4">
                        <BrowserView>
                            <div className="categoryLinks row row-cols-2 g-4">
                                {categories &&
                                    categories
                                        .filter(
                                            (c) =>
                                                c.isActive == true &&
                                                c.showSubCategories == true
                                        )
                                        .map((d, i) => (
                                            <ul
                                                className="list-unstyled"
                                                key={d.id}
                                            >
                                                <h5 className="fw-bold mb-3">
                                                    {d.name}
                                                </h5>
                                                {categories &&
                                                    categories
                                                        .filter(
                                                            (dropBase) =>
                                                                dropBase.baseCategoryId ==
                                                                d.id
                                                        )
                                                        .map((dropItem) => (
                                                            <li
                                                                key={
                                                                    dropItem.id
                                                                }
                                                            >
                                                                <a
                                                                    href={`/category/${dropItem.id}`}
                                                                >
                                                                    {
                                                                        dropItem.name
                                                                    }
                                                                </a>
                                                            </li>
                                                        ))}
                                            </ul>
                                        ))}
                            </div>
                        </BrowserView>
                    </div>
                    <div className="col-sm-6">
                        <div className="supportPhone companyLinks">
                            <div className="icon">
                                <IconHeadset
                                    size={72}
                                    className="text-secondary"
                                />
                            </div>
                            <div className="text">
                                <h3 className="text-secondary">
                                    Çağrı Merkezi
                                </h3>
                                <a
                                    href="tel:00908502228832"
                                    className="h1 fw-bolder text-dark"
                                >
                                    0850 222 88 32
                                </a>
                                <p className="small text-black-50 mb-0">
                                    Hafta İçi 08:00 - 17:00
                                </p>
                            </div>
                        </div>
                        <div className="row g-sm-4 g-2 supportLink">
                            <div className="col-sm-6">
                                <a
                                    href="http://teknikservis.tesan.com.tr/"
                                    className="link btn btn-primary"
                                >
                                    Teknik Servis Ürün Sorgulama
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a
                                    href="/OdemeveTeslimat"
                                    className="link btn btn-primary"
                                >
                                    Ödeme ve Teslimat Detayları
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a
                                    href="/IptalveIade"
                                    className="link btn btn-primary"
                                >
                                    İptal ve İade Prosedürü
                                </a>
                            </div>
                            <div className="col-sm-6">
                                <a
                                    href="/KVKKPage"
                                    className="link btn btn-primary"
                                >
                                    Kişisel Verilerin Korunması
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom bg-light py-4 mt-5">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-sm-auto">
                            <ul className="list-inline d-flex align-items-center gap-4 mb-0">
                                <li className="list-inline-item">
                                    <a
                                        href="/Hakkimizda"
                                        className="text-secondary"
                                    >
                                        Hikayemiz
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a
                                        href="mailto:takssiparis@taks.com.tr"
                                        className="text-secondary"
                                    >
                                        İletişim
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-auto">
                            <div className="copyright mb-sm-0 mb-3 text-center text-dark">
                                <span>
                                    © Tüm hakları saklıdır. Kredi kartı
                                    bilgileriniz 256bit SSL sertifikası ile
                                    korunmaktadır.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrowserView>
                <CookieBanner
                    headline="Çerez Kullanımı Bildirimi"
                    headlineStyles={{ fontWeight: 'bold', fontSize: '1rem' }}
                    bannerStyles={{
                        borderRadius: '2rem 2rem 0 0',
                        padding: '2rem',
                        width: '35%',
                        left: 'initial'
                    }}
                    textStyles={{
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        lineHeight: '2',
                        fontSize: '0.75rem',
                        color: '#888'
                    }}
                    acceptBtnStyles={{
                        backgroundColor: '#fce300',
                        color: '#5a3e00',
                        borderRadius: '1rem',
                        padding: '0.5rem 1rem',
                        fontSize: '0.9rem'
                    }}
                    denyBtnStyles={{
                        color: '#656565',
                        fontSize: '0.9rem'
                    }}
                    linkStyles={{
                        color: '#5a3e00',
                        fontSize: '0.9rem',
                        paddingBottom: '0'
                    }}
                    bannerText="Sitemizin düzgün çalışmasını sağlamak, ziyaretçi verilerini analiz etmek ve kullanıcı deneyimini iyileştirmek amacıyla çerezler kullanıyoruz. 
                Bu çerezler, size kişiselleştirilmiş bir deneyim sunmamıza ve sitemizin işlevlerini yerine getirmesine yardımcı olur."
                    acceptText="Onayla"
                    denyText="Reddet"
                    ttlDays={30}
                    policyLink="/KVKKPage"
                    linkText="Daha Fazla Bilgi İçin Gizlilik Politikamızı İnceleyebilirsiniz."
                />
            </BrowserView>
            <MobileView>
                <CookieBanner
                    headline="Çerez Kullanımı Bildirimi"
                    headlineStyles={{ fontWeight: 'bold', fontSize: '1rem' }}
                    bannerStyles={{
                        borderRadius: '2rem 2rem 0 0',
                        padding: '2rem',
                        left: 'initial'
                    }}
                    textStyles={{
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        lineHeight: '2',
                        fontSize: '0.75rem',
                        color: '#888'
                    }}
                    acceptBtnStyles={{
                        backgroundColor: '#fce300',
                        color: '#5a3e00',
                        borderRadius: '1rem',
                        padding: '0.5rem 1rem',
                        fontSize: '0.9rem'
                    }}
                    denyBtnStyles={{
                        color: '#656565',
                        fontSize: '0.9rem'
                    }}
                    linkStyles={{
                        color: '#5a3e00',
                        fontSize: '0.9rem',
                        paddingBottom: '0'
                    }}
                    bannerText="Sitemizin düzgün çalışmasını sağlamak, ziyaretçi verilerini analiz etmek ve kullanıcı deneyimini iyileştirmek amacıyla çerezler kullanıyoruz. 
                Bu çerezler, size kişiselleştirilmiş bir deneyim sunmamıza ve sitemizin işlevlerini yerine getirmesine yardımcı olur."
                    acceptText="Onayla"
                    denyText="Reddet"
                    ttlDays={30}
                    policyLink="/KVKKPage"
                    linkText="Daha Fazla Bilgi İçin Gizlilik Politikamızı İnceleyebilirsiniz."
                />
            </MobileView>
        </footer>
    );
};
export default memo(Footer);
