import React from "react";

export default function MailValidationError() {
  return (
    <div className="d-flex mailValidationSuccess py-5 flex-fill align-items-center">
      <div className="container">
        <div className="d-flex justify-content-center flex-column text-center">
          <div
            className="d-flex justify-content-center align-items-center bg-danger rounded-circle mx-auto mb-4 shadow"
            style={{ width: "7rem", height: "7rem" }}
          >
            <i class="bi bi-envelope display-3 text-white"></i>
          </div>
          <h3 className="fw-bold mb-3 text-danger">Mail Adresi Doğrulanmadı</h3>
          <p className="text-black-50">
            Kayıtlı mail adresine gönderilen aktifleştirme linkini tıklayarak.
            Üyeliğinizi aktifleştiriniz.
          </p>
        </div>
      </div>
    </div>
  );
}
