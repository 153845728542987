import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_BASE, tesanCDNURL } from '../../config/env';

const NavMenuMobile = (props) => {
    const cookies = new Cookies();
    const [userInfo, setUserInfo] = useState({});
    const [searchData, setSearchData] = useState({
        searchWord: '',
        isSearch: false,
        products: []
    });
    const [navigations, setNavigations] = useState({
        navigations: [],
        isLoading: true
    });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(`${API_BASE}Navigation/GetHomeCategories`, config)
            .then((data) => {
                //console.log(data.data.set);
                setNavigations({
                    navigations: data.data.set
                });
            });
        const userToken = localStorage.getItem('token');
        if (userToken != '') {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            axios
                .get(
                    `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                    config
                )
                .then((data) => {
                    setUserInfo(data.data.set);
                });
        }
    }, []);
    const clickLogout = () => {
        localStorage.setItem('token', '');
        window.location.href = '/Login';
    };
    return (
        <div className="position-relative">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container-fluid">
                    <div className="mobileMenuContainer">
                        <div className="userHead d-flex gap-3 align-items-center">
                            <div
                                className="rounded-circle bg-primary text-white flex-shrink-0 flex-grow-0 text-center"
                                style={{
                                    width: '52px',
                                    height: '52px',
                                    lineHeight: '64px'
                                }}
                            >
                                <i className="bi bi-person h1"></i>
                            </div>
                            {userInfo && userInfo.customerId ? (
                                <div>
                                    <h4 className="fw-bold text-white-50">
                                        {userInfo.firstName +
                                            ' ' +
                                            userInfo.lastname[0] +
                                            '.'}
                                    </h4>
                                    <span className="me-2">
                                        <a
                                            href="/Profile"
                                            className="text-warning fw-bold"
                                        >
                                            <i className="bi bi-gear me-1"></i>
                                            K.Paneli
                                        </a>
                                    </span>{' '}
                                    <span>
                                        <a
                                            href="#"
                                            onClick={() => clickLogout()}
                                            className="text-warning fw-bold"
                                        >
                                            <i className="bi bi-box-arrow-left me-1"></i>
                                            Çıkış Yap
                                        </a>
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <h4 className="fw-bold text-white-50">
                                        Hoşgeldiniz
                                    </h4>
                                    <span className="me-2">
                                        <a
                                            href="/Login"
                                            className="text-warning fw-bold"
                                        >
                                            <i className="bi bi-box-arrow-right me-1"></i>
                                            Giriş Yap
                                        </a>
                                    </span>{' '}
                                    <span>
                                        <a
                                            href="/CreateCustomer"
                                            className="text-warning fw-bold"
                                        >
                                            <i className="bi bi-person-plus me-1"></i>
                                            Kayıt Ol
                                        </a>
                                    </span>
                                </div>
                            )}
                        </div>
                        <ul className="navbar-nav">
                            {navigations.navigations.map((navi) =>
                                navi.category.showSubCategories == true ? (
                                    <li
                                        key={navi.category.id}
                                        className="nav-item dropdown border-0"
                                    >
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id={`navbarDropdown-${navi.category.id}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="icons">
                                                <img
                                                    src={`${tesanCDNURL}${
                                                        navi.category.icon &&
                                                        navi.category.icon
                                                            .virtualPath
                                                    }`}
                                                    alt={navi.category.name}
                                                />
                                            </span>
                                            {navi.category.name}
                                        </a>
                                        <ul
                                            key={`subCat-${navi.category.id}`}
                                            className="dropdown-menu"
                                            aria-labelledby={`navbarDropdown-${navi.category.id}`}
                                        >
                                            {navi.category.showSubCategories ==
                                                true &&
                                                navi.subCategories.map(
                                                    (subCat) =>
                                                        subCat.isActive ==
                                                        true ? (
                                                            <li key={subCat.id}>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    to={`/category/${subCat.id}`}
                                                                >
                                                                    {
                                                                        subCat.name
                                                                    }
                                                                </Link>
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )
                                                )}
                                        </ul>
                                    </li>
                                ) : (
                                    <li
                                        key={navi.category.id}
                                        className="nav-item px-4 px-sm-0 border-0"
                                    >
                                        <Link
                                            className="nav-link"
                                            aria-current="page"
                                            to={`/category/${navi.category.id}`}
                                        >
                                            <img
                                                className="me-3 icons d-inline-block d-sm-none"
                                                src={`${tesanCDNURL}${
                                                    navi.category.icon &&
                                                    navi.category.icon
                                                        .virtualPath
                                                }`}
                                                alt={navi.category.name}
                                            />
                                            {navi.category.name}
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                        <div className="d-flex flex-column gap-2 text-center">
                            <div className="text-white-50 d-block d-sm-none">
                                <small>Çağrı Merkezi Numaramız</small>
                                <h3 className="fw-bolder">
                                    <a
                                        href="tel:08502228832"
                                        className="text-white"
                                    >
                                        0850 222 88 32
                                    </a>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavMenuMobile;
