import 'react-loading-skeleton/dist/skeleton.css';

import { API_BASE, tesanCDNURL } from '../../config/env';
import { BrowserView, MobileView } from 'react-device-detect';
import { useEffect, useState } from 'react';

import CategoryProductDetail from './CategoryProductDetail';
import { Helmet } from 'react-helmet';
import HomePageProductModal from '../homepage/HomePageProductModal';
import { IconLineHeight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { getSlug } from '../common/helpers';
import { useParams } from 'react-router-dom';

let selectedSortBy = 1;
let isEmployee = false;
let isStock = false;

const Category = (props) => {
    let params = useParams();
    const [categoryDetail, setCategoryDetail] = useState({
        loading: true,
        categoryDetail: {},
        products: []
    });
    const [selectedPageNumber, setSelectedPageNumber] = useState(1);
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedOptions, setselectedOptions] = useState([]);
    const [productCountEveryPage, setProductCountEveryPage] = useState(15);
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };

    const [userInfo, setUserInfo] = useState({});
    const [sepetButtonDisable, setSepetButtonDisable] = useState(false);

    useEffect(() => {
        setSelectedPageNumber(1);
        document.getElementById('body').className = 'category-page';

        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setUserInfo(data.data.set);
                    }
                }
            });

        var obj = {};
        obj.categoryId = params.id;
        obj.filters = selectedOptions;
        obj.pageNumber = 1;
        obj.sortBy = selectedSortBy;
        obj.isEmp = isEmployee;
        obj.isStock = isStock;

        axios
            .post(
                `${API_BASE}Category/GetCategoryProductsByFilters`,
                obj,
                config
            )
            .then((data) => {
                setCategoryDetail({ loading: false, ...data.data.set });

                var filters = [];
                var colors = { name: 'Renk', code: 'c', values: [] }; //values:[{value:"Kırmızı",count:1,attributeValueId:1},{value:"Mavi",count:1,attributeValueId:2}]
                data.data.set.productVariants.map((pv) => {
                    if (pv.productAttributeValue.productAttribute) {
                        //Renkler için ayarlama yapılıyor.
                        if (
                            pv.productAttributeValue.productAttribute.name ===
                            'Colors'
                        ) {
                            const vc = colors.values.filter(
                                (v) =>
                                    v.value === pv.productAttributeValue.value
                            );
                            //console.log(vc);
                            if (vc.length > 0) {
                                //Renk varsa count 1 artır.
                                vc[0].count++;
                            } else {
                                //renk yoksa ekle.
                                colors.values.push({
                                    value: pv.productAttributeValue.value,
                                    count: 1,
                                    attributeValueId:
                                        pv.productAttributeValue.id
                                });
                            }
                        }
                    }
                });
                filters.push(colors);

                data.data.set.productSpecifications.map((ps) => {
                    //name:Uyumluluk,values:[]
                    const filt = filters.filter(
                        (f) => f.name === ps.specificationAttributeName //Uyumluluk
                    );

                    if (filt.length > 0) {
                        //Misal Uyumluluk var diğer sorgulamaları yap.
                        var filterAttributeOptions = filt[0].values.filter(
                            (val) =>
                                val.value ===
                                ps.specificationAttributeOptionName
                        );
                        if (filterAttributeOptions.length > 0) {
                            //iPhone7 var değerini artır.
                            //console.log(filterAttributeOptions);
                            filterAttributeOptions[0].count++;
                        } else {
                            //iPhone7 değeri yok. Yeni oluştur.
                            filt[0].values.push({
                                value: ps.specificationAttributeOptionName,
                                count: 1,
                                attributeValueId:
                                    ps.specificationAttributeOptionId
                            }); //values:[{value:"iPhone7",count:1,attributeValueId:1},{value:"iPhone8",count:1,attributeValueId:2}]
                        }
                    } else {
                        //o zaman yeni oluştur.
                        filters.push({
                            name: ps.specificationAttributeName,
                            code: 'sa',
                            values: [
                                {
                                    value: ps.specificationAttributeOptionName,
                                    count: 1,
                                    attributeValueId:
                                        ps.specificationAttributeOptionId
                                }
                            ]
                        });
                    }
                });

                //console.log("filters", filters);
                setFilterOptions(filters);
            });
        if (categoryDetail.products.length >= 1) {
            var items = [];
            categoryDetail.products.map((plist, i) => {
                items.push({
                    item_id: plist.productId,
                    item_name: plist.product.name,
                    index: i
                });
            });
            window.dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items
                }
            });
        }
    }, [params.id]);

    if (userInfo && userInfo.isEmployee == true) {
        isEmployee = true;
    }
    const setSortBy = (sortBy) => {
        selectedSortBy = sortBy;
        getPageProducts(1);
    };

    const getPageProducts = (pageNumber) => {
        setSelectedPageNumber(pageNumber);
        var obj = {};
        obj.categoryId = params.id;
        obj.filters = selectedOptions;
        obj.pageNumber = pageNumber;
        obj.sortBy = selectedSortBy;
        obj.isEmp = isEmployee;
        obj.isStock = isStock;

        axios
            .post(
                `${API_BASE}Category/GetCategoryProductsByFilters`,
                obj,
                config
            )
            .then((data) => {
                setCategoryDetail({ loading: false, ...data.data.set });

                var filters = [];
                var colors = { name: 'Renk', code: 'c', values: [] }; //values:[{value:"Kırmızı",count:1,attributeValueId:1},{value:"Mavi",count:1,attributeValueId:2}]
                data.data.set.productVariants.map((pv) => {
                    if (pv.productAttributeValue.productAttribute) {
                        //Renkler için ayarlama yapılıyor.
                        if (
                            pv.productAttributeValue.productAttribute.name ===
                            'Colors'
                        ) {
                            const vc = colors.values.filter(
                                (v) =>
                                    v.value === pv.productAttributeValue.value
                            );
                            //console.log(vc);
                            if (vc.length > 0) {
                                //Renk varsa count 1 artır.
                                vc[0].count++;
                            } else {
                                //renk yoksa ekle.
                                colors.values.push({
                                    value: pv.productAttributeValue.value,
                                    count: 1,
                                    attributeValueId:
                                        pv.productAttributeValue.id
                                });
                            }
                        }
                    }
                });
                filters.push(colors);

                data.data.set.productSpecifications.map((ps) => {
                    //name:Uyumluluk,values:[]
                    const filt = filters.filter(
                        (f) => f.name === ps.specificationAttributeName //Uyumluluk
                    );

                    if (filt.length > 0) {
                        //Misal Uyumluluk var diğer sorgulamaları yap.
                        var filterAttributeOptions = filt[0].values.filter(
                            (val) =>
                                val.value ===
                                ps.specificationAttributeOptionName
                        );
                        if (filterAttributeOptions.length > 0) {
                            //iPhone7 var değerini artır.
                            //console.log(filterAttributeOptions);
                            filterAttributeOptions[0].count++;
                        } else {
                            //iPhone7 değeri yok. Yeni oluştur.
                            filt[0].values.push({
                                value: ps.specificationAttributeOptionName,
                                count: 1,
                                attributeValueId:
                                    ps.specificationAttributeOptionId
                            }); //values:[{value:"iPhone7",count:1,attributeValueId:1},{value:"iPhone8",count:1,attributeValueId:2}]
                        }
                    } else {
                        //o zaman yeni oluştur.
                        filters.push({
                            name: ps.specificationAttributeName,
                            code: 'sa',
                            values: [
                                {
                                    value: ps.specificationAttributeOptionName,
                                    count: 1,
                                    attributeValueId:
                                        ps.specificationAttributeOptionId
                                }
                            ]
                        });
                    }
                });

                //console.log("filters", filters);
                setFilterOptions(filters);
            });

        // else {
        //     axios
        //         .get(
        //             `${API_BASE}Category/GetCategoryProducts?id=${params.id}&pageNumber=${pageNumber}&sortBy=${selectedSortBy}&isEmp=${isEmployee}`,
        //             config
        //         )
        //         .then((data) =>
        //             setCategoryDetail({ loading: false, ...data.data.set })
        //         );
        // }
        categoryDetail.products.map((productMap) => {
            productMap.product.productProductAttributeValueMappings
                .sort((a, b) =>
                    a.productAttributeValueId > b.productAttributeValueId
                        ? 1
                        : -1
                )
                .sort((a) => (a.productAttributeValueId == 38 ? -1 : 1));
        });
    };
    categoryDetail.products.map((productMap) => {
        productMap.product.productProductAttributeValueMappings
            .sort((a, b) =>
                a.productAttributeValueId > b.productAttributeValueId ? 1 : -1
            )
            .sort((a) => (a.productAttributeValueId == 38 ? -1 : 1));
    });

    const showToastHandle = (productName) => {
        props.openToast({ productName: productName, isShow: true });
        //setProductState({productName:productName});
    };

    const imageVirtualPath =
        categoryDetail.categoryDetail &&
        categoryDetail.categoryDetail.picture &&
        categoryDetail.categoryDetail.picture.virtualPath;

    const paginatedItems = [];

    let pageNumberCount =
        categoryDetail.totalProductCount / productCountEveryPage;
    for (let index = 0; index < pageNumberCount; index++) {
        paginatedItems.push(
            <li
                key={index}
                className={`page-item ${
                    selectedPageNumber - 1 == index ? 'active' : ''
                }`}
                aria-current="page"
            >
                {' '}
                <button
                    className={`page-link`}
                    onClick={() => getPageProducts(index + 1)}
                >
                    {index + 1}
                </button>
            </li>
        );
    }

    useEffect(() => {
        //console.log(selectedOptions);
        //Api'ye istek gönder. selectedFilterOptions dizisini gönder. CategoryId ile birlikte. Gelen verilerle aşağıdakileri doldur.
        //setCategoryDetail({ loading: false, ...data.data.set });
        //setFilterOptions(filters);
        var obj = {};
        obj.categoryId = params.id;
        obj.filters = selectedOptions;
        obj.pageNumber = 1;
        obj.sortBy = selectedSortBy;
        obj.isEmp = isEmployee;
        obj.isStock = isStock;

        axios
            .post(
                `${API_BASE}Category/GetCategoryProductsByFilters`,
                obj,
                config
            )
            .then((data) => {
                setCategoryDetail({ loading: false, ...data.data.set });

                var filters = [];
                var colors = { name: 'Renk', code: 'c', values: [] }; //values:[{value:"Kırmızı",count:1,attributeValueId:1},{value:"Mavi",count:1,attributeValueId:2}]
                data.data.set.productVariants.map((pv) => {
                    if (pv.productAttributeValue.productAttribute) {
                        //Renkler için ayarlama yapılıyor.
                        if (
                            pv.productAttributeValue.productAttribute.name ===
                            'Colors'
                        ) {
                            const vc = colors.values.filter(
                                (v) =>
                                    v.value === pv.productAttributeValue.value
                            );
                            //console.log(vc);
                            if (vc.length > 0) {
                                //Renk varsa count 1 artır.
                                vc[0].count++;
                            } else {
                                //renk yoksa ekle.
                                colors.values.push({
                                    value: pv.productAttributeValue.value,
                                    count: 1,
                                    attributeValueId:
                                        pv.productAttributeValue.id
                                });
                            }
                        }
                    }
                });
                filters.push(colors);

                data.data.set.productSpecifications.map((ps) => {
                    //name:Uyumluluk,values:[]
                    const filt = filters.filter(
                        (f) => f.name === ps.specificationAttributeName //Uyumluluk
                    );

                    if (filt.length > 0) {
                        //Misal Uyumluluk var diğer sorgulamaları yap.
                        var filterAttributeOptions = filt[0].values.filter(
                            (val) =>
                                val.value ===
                                ps.specificationAttributeOptionName
                        );
                        if (filterAttributeOptions.length > 0) {
                            //iPhone7 var değerini artır.
                            //console.log(filterAttributeOptions);
                            filterAttributeOptions[0].count++;
                        } else {
                            //iPhone7 değeri yok. Yeni oluştur.
                            filt[0].values.push({
                                value: ps.specificationAttributeOptionName,
                                count: 1,
                                attributeValueId:
                                    ps.specificationAttributeOptionId
                            }); //values:[{value:"iPhone7",count:1,attributeValueId:1},{value:"iPhone8",count:1,attributeValueId:2}]
                        }
                    } else {
                        //o zaman yeni oluştur.
                        filters.push({
                            name: ps.specificationAttributeName,
                            code: 'sa',
                            values: [
                                {
                                    value: ps.specificationAttributeOptionName,
                                    count: 1,
                                    attributeValueId:
                                        ps.specificationAttributeOptionId
                                }
                            ]
                        });
                    }
                });

                //console.log("filters", filters);
                setFilterOptions(filters);
            });
        if (categoryDetail.products.length >= 1) {
            var items = [];
            categoryDetail.products.map((plist, i) => {
                items.push({
                    item_id: plist.productId,
                    item_name: plist.product.name,
                    index: i
                });
            });
            window.dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items
                }
            });
        }
    }, [selectedOptions]);

    const checkedIsStock = (e) => {
        isStock = e.target.checked;
        getPageProducts(1);
    };

    const checkedChanged = (e) => {
        const combined =
            e.target.dataset.attributevalueid +
            '|' +
            e.target.dataset.attributevaluename +
            '|' +
            e.target.dataset.attributename;
        const selectedFilterOption = selectedOptions.filter(
            (so) => so.name == combined
        );
        if (selectedFilterOption.length < 1) {
            setselectedOptions([
                ...selectedOptions,
                { name: combined, checked: true }
            ]);
        } else {
            setselectedOptions([
                ...selectedOptions.filter((so) => so.name != combined)
            ]);
        }
    };
    const [modalState, setModalState] = useState({
        isOpen: false,
        openProductId: 0
    });
    const [show, setShow] = useState(false);
    const handleProductId = (id) => {
        setModalState({
            isOpen: true,
            openProductId: id
        });
        setShow(true);
        //console.log(modalState);
    };

    function handleClose() {
        setModalState();
        setShow(false);
    }

    function removeFiltersClicked() {
        // setselectedOptions([]);
        var test = selectedOptions;
        test.map((s) => (s.checked = false));
        setselectedOptions([]);
        getPageProducts(1);
    }
    function clickPageNumberPN(event) {
        if (event.target.name === 'Onceki') {
            if (selectedPageNumber > 0) {
                setSelectedPageNumber(selectedPageNumber - 1);
                getPageProducts(selectedPageNumber - 1);
            }
        } else {
            if (selectedPageNumber < Math.ceil(pageNumberCount)) {
                setSelectedPageNumber(selectedPageNumber + 1);
                getPageProducts(selectedPageNumber + 1);
            }
        }
    }
    function closeButtonClicked(optionName) {
        const options = selectedOptions.filter((o) => o.name != optionName);
        setselectedOptions(options);
        setSelectedPageNumber(1);
    }
    var selectedOptionsItem = '';
    if (selectedOptions.length > 0) {
        selectedOptionsItem = (
            <div className="list d-flex flex-wrap gap-1">
                {selectedOptions.map((so) =>
                    so.checked ? (
                        <div
                            key={so.name}
                            className="alert alert-dismissible fade show item selected-property"
                            role="alert"
                        >
                            <div className="property-title small mb-0">
                                <div className="property-title text-black-50 small mb-0">
                                    {so.name.split('|')[2]}
                                </div>
                                <div className="property-value h6 fw-bold">
                                    {so.name.split('|')[1]}
                                </div>
                                <button
                                    type="button"
                                    className="property-clear"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                    onClick={() => closeButtonClicked(so.name)}
                                >
                                    <i className="bi bi-x"></i>
                                </button>
                            </div>
                        </div>
                    ) : (
                        ''
                    )
                )}
            </div>
        );
    }
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={
                        categoryDetail &&
                        categoryDetail.categoryDetail &&
                        categoryDetail.categoryDetail.description
                    }
                />
                <title>
                    {categoryDetail &&
                        categoryDetail.categoryDetail &&
                        categoryDetail.categoryDetail.name}
                </title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <section
                className="category-header"
                style={{
                    backgroundImage: `url(${
                        categoryDetail.categoryDetail &&
                        categoryDetail.categoryDetail.picture != null
                            ? tesanCDNURL + imageVirtualPath
                            : ''
                    })`
                }}
            >
                <div className="container">
                    <div className="content d-flex align-items-start flex-column flex-wrap text-white">
                        <div className="title">
                            <h2 className="fw-bold">
                                {categoryDetail &&
                                    categoryDetail.categoryDetail &&
                                    categoryDetail.categoryDetail.name}
                            </h2>
                        </div>
                        {/* <div className="description text-center">
                            <p className="m-0">
                                {categoryDetail &&
                                    categoryDetail.categoryDetail &&
                                    categoryDetail.categoryDetail.description}
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="category-control mb-sm-5 mb-3 sticky-top">
                <div className="border-bottom">
                    <div className="container">
                        <div className="py-3">
                            {/* Breadcrumb */}
                            <div className="flex-shrink-0 flex-grow-1 d-none d-sm-block">
                                <nav
                                    aria-label="breadcrumb"
                                    style={{
                                        '--bs-breadcrumb-divider':
                                            "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                                    }}
                                >
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-dark">
                                                Ana Sayfa
                                            </a>
                                        </li>

                                        {
                                            //BreadCrumb
                                            categoryDetail.baseCategory && (
                                                <li className="breadcrumb-item">
                                                    <Link
                                                        to={`/category/${categoryDetail.baseCategory.id}`}
                                                        className="text-dark"
                                                    >
                                                        {
                                                            categoryDetail
                                                                .baseCategory
                                                                .name
                                                        }
                                                    </Link>
                                                </li>
                                            )
                                        }
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            {categoryDetail.categoryDetail.name}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            {/* Filter and sort buttons */}
                            <MobileView className="row row-cols-2">
                                <div className="col">
                                    <div className="d-grid">
                                        <button
                                            className="d-sm-none bg-white border border-light rounded-2 p-3"
                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#productFilter"
                                            aria-controls="productFilter"
                                        >
                                            <i className="bi bi-sliders me-2"></i>
                                            Ürünleri Filtrele
                                        </button>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-grid">
                                        <button
                                            type="button"
                                            className="bg-white border d-flex gap-2 align-items-center rounded-2 p-3 dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <IconLineHeight
                                                size={24}
                                                strokeWidth={1}
                                            />
                                            <span>Ürünleri Sırala</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <button
                                                    className={`dropdown-item ${
                                                        selectedSortBy == 1
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    aria-current="true"
                                                    onClick={() => setSortBy(1)}
                                                    type="button"
                                                >
                                                    Yeniden Eskiye
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${
                                                        selectedSortBy == 2
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={() => setSortBy(2)}
                                                    type="button"
                                                >
                                                    Eskiden Yeniye
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${
                                                        selectedSortBy == 3
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={() => setSortBy(3)}
                                                    type="button"
                                                >
                                                    Fiyat Yükselen
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${
                                                        selectedSortBy == 4
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                    onClick={() => setSortBy(4)}
                                                    type="button"
                                                >
                                                    Fiyat Azalan
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </MobileView>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container mb-5">
                <div className="row">
                    {/* Filtre Seçenekleri WEB */}
                    <BrowserView className="col-sm-3">
                        <div className="static-property overflow-y-scroll">
                            <header className="bg-primary text-dark">
                                Ürünleri Filtrele
                            </header>
                            {categoryDetail.products.length > 0 ? (
                                <div className="form-check form-switch my-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="stockStatus"
                                        onChange={checkedIsStock}
                                    />
                                    <label
                                        className="form-check-label fw-bold text-dark"
                                        htmlFor="stockStatus"
                                    >
                                        Sadece Stoktakileri Göster
                                    </label>
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="accordion" id="filterAccordion">
                                {filterOptions &&
                                    filterOptions.map((fo) => (
                                        <div
                                            className="accordion-item property-group"
                                            key={100000 * Math.random()}
                                        >
                                            <h2
                                                className="accordion-header"
                                                id={100000 * Math.random()}
                                            >
                                                <button
                                                    className="accordion-button box-light collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={
                                                        '#' + getSlug(fo.name)
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls={getSlug(
                                                        fo.name
                                                    )}
                                                    key={fo.name}
                                                >
                                                    {fo.name}
                                                </button>
                                            </h2>
                                            <div
                                                id={getSlug(fo.name)}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={fo.name}
                                                data-bs-parent="#filterAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <div className="checkbox-group lh-lg">
                                                        {fo.values.map((fv) => (
                                                            <div
                                                                key={
                                                                    fv.key +
                                                                    '|' +
                                                                    fv.attributeValueId +
                                                                    '|' +
                                                                    fo.name
                                                                }
                                                                className="form-check d-flex align-items-center"
                                                            >
                                                                <input
                                                                    className="form-check-input me-2 mt-0"
                                                                    type="checkbox"
                                                                    id={`itemid-${fo.code}-${fv.attributeValueId}`}
                                                                    data-attributevalueid={`${fo.code}-${fv.attributeValueId}`}
                                                                    data-attributename={`${fo.name}`}
                                                                    data-attributevaluename={`${fv.value}`}
                                                                    onChange={
                                                                        checkedChanged
                                                                    }
                                                                    checked={
                                                                        selectedOptions.filter(
                                                                            (
                                                                                so
                                                                            ) =>
                                                                                so.name ==
                                                                                fo.code +
                                                                                    '-' +
                                                                                    fv.attributeValueId +
                                                                                    '|' +
                                                                                    fv.value +
                                                                                    '|' +
                                                                                    fo.name
                                                                        )[0]
                                                                            ?.checked
                                                                    }
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`itemid-${fo.code}-${fv.attributeValueId}`}
                                                                >
                                                                    {fv.value}
                                                                </label>
                                                                {/* <span className="badge bg-light text-black-50 rounded-1 ms-auto">
                                                                    {fo.name ==
                                                                    'Renk'
                                                                        ? ''
                                                                        : fv.count}
                                                                </span> */}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </BrowserView>
                    {/* Ürünler */}
                    <div className="col-sm-9">
                        <div className="d-flex justify-content-between align-items-end mb-3">
                            {/* Selected Filter */}
                            <section className="sFilter">
                                {selectedOptions.length > 0 ? (
                                    <div className="filter-propertys d-flex flex-wrap flex-fill">
                                        <div className="selected-filter-property w-100">
                                            <div className="header d-flex align-items-center justify-content-between mb-3">
                                                <h5 className="fw-bold">
                                                    Seçilen Filtreler
                                                </h5>
                                                <button
                                                    className="btn btn-link text-danger small"
                                                    onClick={
                                                        removeFiltersClicked
                                                    }
                                                >
                                                    <i className="bi bi-trash me-2"></i>
                                                    Tümünü Kaldır
                                                </button>
                                            </div>
                                            {selectedOptionsItem}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </section>
                            <BrowserView className="d-grid btn-group border rounded-2 p-3">
                                <button
                                    type="button"
                                    className="border-0 bg-transparent dropdown-toggle d-flex gap-2 align-items-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <IconLineHeight size={24} strokeWidth={1} />
                                    <span>Ürünleri Sırala</span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <button
                                            className={`dropdown-item ${
                                                selectedSortBy == 1
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            aria-current="true"
                                            onClick={() => setSortBy(1)}
                                            type="button"
                                        >
                                            Yeniden Eskiye
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={`dropdown-item ${
                                                selectedSortBy == 2
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setSortBy(2)}
                                            type="button"
                                        >
                                            Eskiden Yeniye
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={`dropdown-item ${
                                                selectedSortBy == 3
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setSortBy(3)}
                                            type="button"
                                        >
                                            Fiyat Yükselen
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className={`dropdown-item ${
                                                selectedSortBy == 4
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => setSortBy(4)}
                                            type="button"
                                        >
                                            Fiyat Azalan
                                        </button>
                                    </li>
                                </ul>
                            </BrowserView>
                        </div>
                        <section className="product-list p-0">
                            {categoryDetail.products.length == 0 ? (
                                <Skeleton
                                    width={'100%'}
                                    height={'36px'}
                                    borderRadius={'1rem'}
                                    count={'5'}
                                />
                            ) : (
                                ''
                            )}
                            <div className="row row-cols-sm-3 row-cols-2 g-4 align-items-stretch">
                                {
                                    //Products
                                    categoryDetail.products.length > 0
                                        ? categoryDetail.products.map(
                                              (productMap) => (
                                                  <CategoryProductDetail
                                                      showToast={(
                                                          productName
                                                      ) =>
                                                          showToastHandle(
                                                              productName
                                                          )
                                                      }
                                                      productMap={productMap}
                                                      key={productMap.id}
                                                      openedProductId={(id) =>
                                                          handleProductId(id)
                                                      }
                                                      userInfo={userInfo}
                                                  />
                                              )
                                          )
                                        : ''
                                }
                            </div>
                            {categoryDetail.products.length > 0 &&
                            pageNumberCount > 1 ? (
                                <nav
                                    aria-label="Category Pagination"
                                    className="my-5"
                                >
                                    <ul className="pagination justify-content-center">
                                        <li
                                            className={`page-item  ${
                                                selectedPageNumber == 1
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                        >
                                            <button
                                                className={`page-link `}
                                                name="Onceki"
                                                onClick={clickPageNumberPN}
                                            >
                                                <i className="bi bi-arrow-left"></i>
                                            </button>
                                        </li>
                                        {paginatedItems}
                                        <li
                                            className={`page-item ${
                                                selectedPageNumber ==
                                                Math.ceil(pageNumberCount)
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                        >
                                            <button
                                                className="page-link"
                                                name="Sonraki"
                                                onClick={clickPageNumberPN}
                                            >
                                                <i className="bi bi-arrow-right"></i>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            ) : (
                                <div className="my-5"></div>
                            )}
                            <HomePageProductModal
                                showToast={(productName) =>
                                    showToastHandle(productName)
                                }
                                openProductId={
                                    modalState && modalState.openProductId
                                }
                                show={show}
                                handleClose={() => handleClose()}
                                userInfo={userInfo}
                            />
                        </section>
                    </div>
                </div>
            </div>
            {/* Filter Panel */}
            <div
                className="offcanvas offcanvas-start py-4"
                tabIndex="-1"
                id="productFilter"
                aria-labelledby="productFilterLabel"
            >
                <div className="container">
                    <div className="offcanvas-header">
                        <h4
                            className="offcanvas-title fw-bold"
                            id="productFilterLabel"
                        >
                            <i className="bi bi-search me-3 text-primary"></i>
                            Ürünleri Filtrele
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div className="offcanvas-body">
                        <div className="d-flex flex-column property-group mb-3">
                            <div className="form-check form-switch my-4">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="stockStatus"
                                    onChange={checkedIsStock}
                                />
                                <label
                                    className="form-check-label fw-bold text-primary"
                                    htmlFor="stockStatus"
                                >
                                    Sadece Stoktakileri Göster
                                </label>
                            </div>
                        </div>

                        <div className="static-property overflow-y-scroll">
                            <div className="d-flex flex-column">
                                <div className="accordion" id="filterAccordion">
                                    {filterOptions &&
                                        filterOptions.map((fo) => (
                                            <div
                                                className="accordion-item property-group"
                                                key={100000 * Math.random()}
                                            >
                                                <h2
                                                    className="accordion-header"
                                                    id={100000 * Math.random()}
                                                >
                                                    <button
                                                        className="accordion-button box-light collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={
                                                            '#' +
                                                            getSlug(fo.name)
                                                        }
                                                        aria-expanded="true"
                                                        aria-controls={getSlug(
                                                            fo.name
                                                        )}
                                                        key={fo.name}
                                                    >
                                                        {fo.name}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={getSlug(fo.name)}
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby={fo.name}
                                                    data-bs-parent="#filterAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <div className="checkbox-group lh-lg">
                                                            {fo.values.map(
                                                                (fv) => (
                                                                    <div
                                                                        key={
                                                                            fv.key +
                                                                            '|' +
                                                                            fv.attributeValueId +
                                                                            '|' +
                                                                            fo.name
                                                                        }
                                                                        className="form-check d-flex align-items-center"
                                                                    >
                                                                        <input
                                                                            className="form-check-input me-2 mt-0"
                                                                            type="checkbox"
                                                                            id={`itemid-${fo.code}-${fv.attributeValueId}`}
                                                                            data-attributevalueid={`${fo.code}-${fv.attributeValueId}`}
                                                                            data-attributename={`${fo.name}`}
                                                                            data-attributevaluename={`${fv.value}`}
                                                                            onChange={
                                                                                checkedChanged
                                                                            }
                                                                            checked={
                                                                                selectedOptions.filter(
                                                                                    (
                                                                                        so
                                                                                    ) =>
                                                                                        so.name ==
                                                                                        fo.code +
                                                                                            '-' +
                                                                                            fv.attributeValueId +
                                                                                            '|' +
                                                                                            fv.value +
                                                                                            '|' +
                                                                                            fo.name
                                                                                )[0]
                                                                                    ?.checked
                                                                            }
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={`itemid-${fo.code}-${fv.attributeValueId}`}
                                                                        >
                                                                            {
                                                                                fv.value
                                                                            }
                                                                        </label>
                                                                        {/* <span className="badge bg-light text-black-50 rounded-1 ms-auto">
                                                                            {fo.name ==
                                                                            'Renk'
                                                                                ? ''
                                                                                : fv.count}
                                                                        </span> */}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Category;
