import React, { useState } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_BASE } from '../../config/env';
import CustomerPolicy from '../common/CustomerPolicy';
import PersonalDataPolicy from '../common/PersonalDataPolicy';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

registerLocale('tr', tr);

const CreateCustomer = () => {
    const [userState, setUserState] = useState({
        email: '',
        username: '',
        password: '',
        lastIpAddress: '',
        telNo: '',
        tcNo: '',
        adminComment: '',
        isSystemAccount: true,
        active: true,
        name: '',
        surname: '',
        passwordConf: '',
        isIysConfirmed: true,
        isCustomerPolicyConfirmed: false,
        isPersonalDataPolicyConfirmed: false
    });

    function KeyCheck(event) {
        if (document.getElementById('telNo').value.length <= 2) {
            document.getElementById('telNo').value = '+90';
        } else {
            customerFormsChange(event);
        }
    }

    function customerFormsChange(e) {
        if (e.target.name == 'telNo') {
            e.target.value = e.target.value.replace(/\s+/g, '');
            e.target.value = e.target.value.slice(2);
        }
        setUserState({
            ...userState,
            [e.target.name]: e.target.value
        });
    }

    const btnCreateClicked = (e) => {
        e.preventDefault();
        if (
            !userState.name ||
            !userState.surname ||
            !userState.email ||
            !userState.telNo ||
            !userState.tcNo ||
            !userState.password ||
            !userState.passwordConf
        ) {
            Swal.fire({
                text: 'Tüm Alanları Doldurunuz!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }

        if (userState.telNo.length < 11) {
            Swal.fire({
                text: 'Telefon Numarası 11 Haneli Olmalıdır!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }

        if (userState.tcNo.length != 11) {
            Swal.fire({
                text: 'Tc Numarası 11 haneli olmalıdır!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }

        if (userState.password != userState.passwordConf) {
            Swal.fire({
                text: 'Girilen Şifreler Uyuşmuyor!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }

        if (userState.isCustomerPolicyConfirmed == false) {
            Swal.fire({
                text: 'Müşteri Politikasını Onaylayınız!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }
        if (userState.isPersonalDataPolicyConfirmed == false) {
            Swal.fire({
                text: 'Kişisel Verilerin Politikasını Onaylayınız!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }

        var ozelChar = '*|,:<>[]{}`;()@&$#%!+-"./';
        var isChar = false;
        for (var i = 0; i < userState.password.length; i++) {
            if (ozelChar.indexOf(userState.password.charAt(i)) != -1) {
                isChar = true;
            }
        }

        if (userState.password != userState.passwordConf) {
            Swal.fire({
                text: 'Girilen Şifreler Uyuşmuyor!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        } else if (userState.password.length < 6) {
            Swal.fire({
                text: 'Şifre 6 karekter veya daha büyük olmalıdır!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        } else if (userState.password.search(/[a-zA-Z]/) < 0) {
            Swal.fire({
                text: 'Şifre en az bir harf içermelidir!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        } else if (userState.password.search(/[0-9]/) < 0) {
            Swal.fire({
                text: 'Şifre en az bir rakam içermelidir!',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            });
            return false;
        }
        //else if (isChar == false) {
        //  alert("Şifre en az bir sembol içermelidir.");
        //  return false;
        //}

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .post(`${API_BASE}Customer/AddCustomer`, userState, config)
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '201') {
                        Swal.fire({
                            text: 'Kullanıcı Sistemde Mevcut!',
                            icon: 'warning',
                            confirmButtonText: 'Giriş Yap'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = '/Login';
                            }
                        });
                    } else if (data.data.code == '200') {
                        setUserState({
                            ...userState,
                            ...data.data
                        });

                        window.location.href = '/RegisterCompleted';
                    }
                }
            });
    };
    const chkIYSChanged = (e) => {
        setUserState({ ...userState, isIysConfirmed: e.target.checked });
    };
    const chkPolicyPermissionChanged = (e) => {
        setUserState({
            ...userState,
            isCustomerPolicyConfirmed: e.target.checked
        });
    };
    const chkPersonalDataPermissionChanged = (e) => {
        setUserState({
            ...userState,
            isPersonalDataPolicyConfirmed: e.target.checked
        });
    };
    function customerPolicyConfirm() {
        setUserState({
            ...userState,
            isCustomerPolicyConfirmed: true
        });
    }
    function personalDataPolicyConfirm() {
        setUserState({ ...userState, isPersonalDataPolicyConfirmed: true });
    }
    return (
        <>
            <section className="login py-sm-5 p-4">
                <div className="container">
                    <div className="d-grid col-sm-6 mx-auto">
                        <div className="header d-flex flex-column mb-3 text-center">
                            <div className="mb-1 h2">
                                <i className="bi bi-person-plus text-primary"></i>
                            </div>
                            <div className="h5 fw-bolder">
                                <span>Yeni Hesap Oluştur</span>
                            </div>
                            <div className="mb-5">
                                <span>
                                    Siparişlerinizde sorun yaşanmaması için,
                                    lütfen ad ve soyad bilgilerinizi
                                    kimliğinizdeki şekilde yazınız.
                                </span>
                            </div>
                        </div>
                        <div>
                            <form id="createUser">
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={(e) => customerFormsChange(e)}
                                        placeholder="Adınız"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="surname"
                                        name="surname"
                                        onChange={(e) => customerFormsChange(e)}
                                        placeholder="Soyadınız"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        onChange={(e) => customerFormsChange(e)}
                                        placeholder="E-Posta Adresiniz"
                                    />
                                </div>
                                <div className="mb-3">
                                    <PhoneInput
                                        onlyCountries={['tr']}
                                        country={'tr'}
                                        inputProps={{
                                            name: 'telNo',
                                            id: 'telNo',
                                            className:
                                                'form-control w-100 rounded-2 py-4',
                                            onKeyUp: (e) => {
                                                KeyCheck(e);
                                            },
                                            onClick: (e) => {
                                                KeyCheck(e);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="tcNo"
                                        name="tcNo"
                                        step="1"
                                        onChange={(e) => customerFormsChange(e)}
                                        placeholder="T.C. Kimlik Numaranız"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        onChange={(e) => customerFormsChange(e)}
                                        step="1"
                                        placeholder="Şifre"
                                    />
                                </div>
                                <div className="mb-5">
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="passwordConf"
                                        name="passwordConf"
                                        onChange={(e) => customerFormsChange(e)}
                                        step="1"
                                        placeholder="Şifreyi Tekrarlayın"
                                    />
                                </div>
                                <div className="mb-3 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="promotionPermission"
                                        defaultChecked
                                        onChange={chkIYSChanged}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="promotionPermission"
                                    >
                                        Aydınlatma Metninde belirtilen ilkeler
                                        nezdinde Elektronik Ticaret İletisi
                                        almak istiyorum.
                                    </label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="customerPolicyPermission"
                                        onChange={chkPolicyPermissionChanged}
                                        checked={
                                            userState.isCustomerPolicyConfirmed ==
                                            true
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="customerPolicyPermission"
                                    >
                                        <a
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#customerPolicyModal"
                                        >
                                            Üyelik sözleşmesini kabul ediyorum.
                                        </a>
                                    </label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="personalDataPermission"
                                        onChange={
                                            chkPersonalDataPermissionChanged
                                        }
                                        checked={
                                            userState.isPersonalDataPolicyConfirmed ==
                                            true
                                        }
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="personalDataPermission"
                                    >
                                        <a
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#PersonalDataPolicyModal"
                                        >
                                            Kişisel verilerin işlenmesine
                                            ilişkin Aydınlatma Metnini okudum.
                                            Açık rıza veriyorum
                                        </a>
                                    </label>
                                </div>
                                <div className="d-grid my-5">
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={btnCreateClicked}
                                    >
                                        Kaydet
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <CustomerPolicy
                customerPolicyConfirm={customerPolicyConfirm}
            ></CustomerPolicy>
            <PersonalDataPolicy
                personalDataPolicyConfirm={personalDataPolicyConfirm}
            ></PersonalDataPolicy>
        </>
    );
};
export default CreateCustomer;
