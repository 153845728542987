import {
  FETCH_GETVARIANTPICTURE_FULFILLED,
  FETCH_PRODUCT_FULFILLED,
  FETCH_PRODUCT_PENDING,
  FETCH_SEARCH_PRODUCT_FULFILLED,
  FETCH_PRODUCT_DETAİL,
} from "../actions/product";

const initialState = {
  fetching: false,
  fetched: false,
  product: {},
  error: {},
  productDetail: [],
};
export function productReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_PENDING:
      return {
        ...state,
        product: {},
        fetched: false,
        fetching: true,
      };
    case FETCH_PRODUCT_DETAİL:
      return {
        ...state,
        productDetail: [],
        fetched: false,
        fetching: true,
      };
    case FETCH_PRODUCT_FULFILLED:
      return {
        ...state,
        product: action.payload,
        fetched: true,
        fetching: false,
      };
    case FETCH_SEARCH_PRODUCT_FULFILLED:
      return {
        ...state,
        searchedProduct: action.payload,
        fetched: true,
        fetching: false,
      };
    case FETCH_GETVARIANTPICTURE_FULFILLED:
      return {
        ...state,
        variantPictureList: action.payload,
      };
    default:
      return state;
  }
}
