export default function PersonalDataPolicy(props) {
  return (
    <div
      className="modal fade"
      id="PersonalDataPolicyModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="PersonalDataPolicyModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="PersonalDataPolicyModalLabel">
              Üye ve Ziyaretçi Kişisel Veri Aydınlatma Metni
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body lh-lg">
            <h4>
              <strong>A.Veri Sorumlusunun Kimliği</strong>
            </h4>
            <p>
              07.04.2016 tarihinde 6698 sayılı "Kişisel Verilerin Korunması
              Kanunu" (Bundan böyle kısaca "Kanun&rdquo; olarak anılacaktır.)
              yayınlanarak yürürlüğe girmiştir. Söz konusu kanun kapsamında
              Şirketimiz Tesan İletişim A.Ş., (Bundan böyle kısaca "TESAN"
              olarak anılacaktır.) "Veri Sorumlusu" sıfatına sahip olup, bu
              sıfatın gerektirdiği yükümlülükleri yerine getirmek için gerekli
              uyum çalışmalarını gerçekleştirmektedir. İşbu bilgilendirme,
              TESAN'ın gerçek kişilere ilişkin aydınlatma yükümlülüğünü yerine
              getirmek üzere yapılmaktadır. İşbu Aydınlatma Metni gerekli olduğu
              durumlarda TESAN tarafından güncellenecek ve güncel hali
              yayımlanacaktır.
            </p>
            <p>
              Kanun kapsamında kişisel veri, kimliği belirli veya belirlenebilir
              gerçek kişiye ilişkin her türlü veriyi kapsamaktadır. Kişisel
              verinin özel bir türü olan özel Nitelikli Kişisel Veri, ırk, etnik
              köken, siyasi düşünce, felsefi inanç, din, mezhep, diğer inançlar,
              kılık ve kıyafet, dernek, vakıf veya sendika üyeliği, sağlık,
              cinsel hayat, ceza mahkumiyeti ve güvenlik tedbirleri, biyometrik
              ve genetik verileri ifade eder.
            </p>
            <h4>
              <strong>B. Veri Toplamanın Yöntemi ve Hukuki Sebebi</strong>
            </h4>
            <p>
              Toplanan veriler, otomatik ya da otomatik olmayan yöntemlerle,
              TESAN'a farklı kanallardan (internet sitesi, muhtelif sözleşmeler,
              her türlü bilgi formları, anketler, sosyal medya uygulamaları,
              çağrı merkezi, hizmet aldığımız iş ortakları vasıtasıyla, her
              türlü yazışmalar vs.) sözlü, yazılı veya elektronik yollar ile
              ilettiğiniz verilerdir. Kişisel verileriniz, tamamen veya kısmen
              otomatik yollarla ya da veri kayıt sisteminin parçası olmak
              kaydıyla otomatik olmayan yöntemlerle elde edilmektedir.
            </p>
            <p>
              Bu bilgiler, TESAN'ın müşterilerine daha iyi ve kaliteli hizmet
              verebilmesi,ticari ve idari faaliyetlerini iş ve sosyal hayatı
              düzenleyen yasalar çerçevesinde sunulabilmesi, yasalardan doğan
              mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi
              gayesi ile KVKK 5. maddesinde belirtilen kişisel veri işleme
              şartları ve amaçları dahilinde işlenecektir.
            </p>
            <p>
              KVKK 5. maddesinde belirtilen Kişisel Verilerin İşlenme Şartları
              aşağıda yer almaktadır.
            </p>
            <p>
              (1) Kişisel veriler, ilgili kişinin açık rızası olmaksızın
              işlenemez.
            </p>
            <p>
              (2) Aşağıdaki şartlardan birinin varlığı hâlinde, ilgili kişinin
              açık rızası aranmaksızın kişisel verilerinin işlenmesi mümkündür;
            </p>
            <p>a-Kanunlarda açıkça öngörülmesi,</p>
            <p>
              b-Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
              bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
              kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
              korunması için zorunlu olması,
            </p>
            <p>
              c-Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması,
            </p>
            <p>
              d-Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
              zorunlu olması,
            </p>
            <p>e-İlgili kişinin kendisi tarafından alenileştirilmiş olması,</p>
            <p>
              f-Bir hakkın tesisi, kullanılması veya korunması için veri
              işlemenin zorunlu olması,
            </p>
            <p>
              g-İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması.
            </p>
            <p>
              İşbu madde gereğince açık rıza alınması gereken durumlarda
              müşterilerimizden açık rıza alınmaktadır. Ancak maddenin 2.
              fıkrasında yazan şartlara uygun olması kaydı ile ilgili kişinin
              rızası alınmadan kişisel verilerin işlenmesi mümkündür. TESAN
              tarafından özel nitelikli kişisel verileriniz işlenmemektedir.
            </p>
            <p>
              TESAN'ın sahibi olduğu internet sitesine üye olduğunuzda ve/veya
              ürün satın aldığınızda toplanan kişisel verileriniz ad, soyad,
              doğum tarihi, cinsiyet, vergi dairesi, vergi numarası, T.C. kimlik
              numarası vs. gibi kimlik bilgileriniz, adres, telefon, elektronik
              posta adresi, teslimat alıcı bilgileri gibi iletişim bilgileriniz,
              IP adresi vs. gibi işlem güvenliği bilgileriniz, sipariş notları
              vs. gibi müşteri işlem bilgisi, pazarlama bilgileridir.
            </p>
            <p>
              Bilgilerinizi titizlikle korumanın önemi ve bilinci ile
              ödemelerinizin kredi kartı ile işlem gerçekleştirilmesi
              aşamasında, 3D Secure sistemini kullanmanızı öneririz. ödemenin
              gerçekleştirilmesi için gerekli olan bilgiler (örnek: Kredi kartı
              numarası) TESAN tarafından saklanmamaktadır. Bu bilgiler aracısız
              olarak, anlaşmalı olduğumuz bankaya veya yetkili ödeme kuruluşu
              üzerinden sizin tarafınızdan gerçekleştirilmektedir.
            </p>
            <p>
              Kanunların emredici hükümleri gereği gerçek kişilerin bilgilerinin
              doğru ve güncel tutulması esastır. Bu nedenle, belirli zaman
              aralıkları ile müşterilerden kişisel bilgilerini güncellemesi
              talep edilebilir. Müşterinin sisteme girdiği tüm bilgilere sadece
              müşteri ulaşabilmekte ve sadece müşteri değiştirebilmektedir.
              TESAN, hiçbir koşulda müşteri tarafından sunulan yanlış bilgiden
              sorumlu tutulamaz. Müşteri, kayıt olduğu esnada verilen giriş
              bilgilerini, kullanıcı adını ve şifresini korumakla yükümlüdür.
            </p>
            <p>
              TESAN'ın sahibi olduğu internet sitelerinde; kişilerin sitelerdeki
              ziyaretlerini amaçlarına uygun bir şekilde gerçekleştirmelerini
              temin etmek; kendilerine özelleştirilmiş içerikler gösterebilmek
              ve çevirim içi reklamcılık faaliyetlerinde bulunabilmek maksadıyla
              teknik vasıtalarla (örn: cookie uygulaması gibi) site içerisindeki
              internet hareketleri kaydedilmektedir. Şirketimizin çerez
              Politikasına internet sitemizden ulaşabilirsiniz.
            </p>
            <h4>
              <strong>C.Kişisel Verilerin Hangi Amaçla İşleneceği</strong>
            </h4>
            <p>
              Kişisel verileriniz aşağıdaki durumlarda KVKK'ya uygun olarak veri
              sorumlusu ya da atayacağı tüzel/gerçek kişiler tarafından
              işlenebilmektedir;
            </p>
            <ul type="disc">
              <li>Kimlik doğrulama ve kayıt oluşturulması amacıyla,</li>
              <li>
                Siparişlerinizin işleme konulması ve hesabınızın yönetilmesi,
                ticari faaliyetlerin yerine getirilebilmesi ve sürekliliğinin
                sağlanabilmesi amacıyla,
              </li>
              <li>
                Alınan hizmet süreçleri ile alakalı bilgilendirme amacıyla,
              </li>
              <li>Siparişlerin teslimi/iadesi amacıyla,</li>
              <li>Siparişler ile ilgili şikayetlerin çözülmesi amacıyla,</li>
              <li>Teknik servis hizmetinin verilebilmesi amacıyla,</li>
              <li>
                önceden onay vermeniz kaydıyla yeni ürünler, kampanyalar ve
                promosyonlar hakkında bilgi vermek için elektronik ileti
                gönderilmesi amacıyla,
              </li>
              <li>
                İşlemlerinizle ilgili istatistiklerin yapılabilmesi ve
                bağlantılı listelerin oluşturulması, ticari istatistik ve
                analizlerin bir araya getirilmesi amacıyla,
              </li>
              <li>
                ürünlerimizin, hizmetlerimizin ve kişisel seçim olanaklarınızın
                araştırılması ve geliştirilmesi amacıyla,
              </li>
              <li>
                Yazılım, kurumsal kaynak planlaması, raporlama, pazarlama vs.
                gibi işlevlerin yerine getirilmesi amacıyla,
              </li>
              <li>Promosyon ve hediye verilebilmesi amacıyla,</li>
              <li>
                Gerekli kalite, gizlilik ve standart denetimlerinin yapabilmesi
                amacıyla,
              </li>
              <li>
                Kanun ve yönetmelikler ile belirlenmiş gerekliliklerin ifa
                edilmesi amacıyla (vergi mevzuatı, tüketicilerin korunmasına
                yönelik mevzuat, borçlar hukuku mevzuatı, ticaret hukuku
                mevzuatı, gümrük mevzuatı, elektronik iletişim ile ilgili
                mevzuat vs. ilgili tüm mevzuatlar),
              </li>
              <li>
                e-fatura, e-arşiv ve e-irsaliye ile ilgili yükümlülüklerin
                yerine getirilmesi amacıyla ,
              </li>
              <li>
                Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde
                kamu kurum ve kuruluşlarının taleplerinin yerine getirilmesi
                amacıyla,
              </li>
              <li>
                KVKK'da belirtilmiş yasal yükümlülüklerin yerine getirilmesi
                amacıyla,
              </li>
            </ul>
            <h4>
              <strong>
                D.İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                Aktarılabileceği
              </strong>
            </h4>
            <p>
              İşlenen kişisel verileriniz zorunlu olan bilgiler ile sınırlı
              olmak kaydı ile;
            </p>
            <ul type="disc">
              <li>
                Siparişlerinizin işleme konulması, hesabınızın yönetilmesi,
                ticari faaliyetlerin yerine getirilebilmesi ve sürekliliğinin
                sağlanabilmesi amacıyla hizmet sağlayıcılarıyla,
              </li>
              <li>
                Siparişlerin teslim/iadesi amacıyla lojistik ve kargo
                firmalarıyla,
              </li>
              <li>
                Yazılım, kurumsal kaynak planlaması, raporlama, pazarlama vb.
                gibi işlevlerin yerine getirilmesi amacıyla tedarikçilerle ve
                çözüm ortaklarıyla,
              </li>
              <li>
                ödeme/tahsilat hizmetlerinin yerine getirilmesi amacıyla
                bankalar ve ödeme aracılık sistemleri şirketleriyle,
              </li>
              <li>
                Gerekli kalite, gizlilik ve standart denetimlerinin
                yapılabilmesi amacıyla denetim firmaları ve bilgi güvenliği
                firmalarıyla,
              </li>
              <li>
                TESAN'ın kullandığı bilişim teknolojileri gereği bulut bilişim
                hizmetini veren firmalarla,
              </li>
              <li>
                TESAN tarafından kullanılan ERP vs. diğer bilişim sistemlerinin
                işlerliği ve güvenirliliği açısından ilgili tedarikçilerle ve iş
                ortaklarıyla,
              </li>
              <li>
                İlgili mevzuat hükümlerine istinaden yükümlülüklerimizin yerine
                getirilmesi ve ticari faaliyetlerimizin denetimi amacıyla ilgili
                denetim firmalarıyla, özel entegratör firmalarıyla, bağımsız
                denetim firmalarıyla, gümrük firmalarıyla, mali müşavir/muhasebe
                firmalarıyla, hukuk bürolarıyla,
              </li>
              <li>
                Promosyon ve hediye verilebilmesi amacıyla ilgili iş ortakları
                ve tedarikçilere,
              </li>
              <li>TESAN'ın ortakları/hissedarlarıyla,</li>
              <li>
                Yasal gereklilikleri ifa etmek ve/veya resmi mercilerin
                taleplerini yerine getirmek amacıyla kamu kurum ve
                kuruluşlarıyla.
              </li>
            </ul>
            <p>
              Kanunen açık rıza alınması gereken hallerde açık rıza alınarak
              (kanunen açık rıza alınması gerekli olmayan durumlar hariç olmak
              kaydı ile) ve kanun ile belirlenmiş koşullar çerçevesinde
              aktarılabilecektir.
            </p>
            <h4>
              <strong>E.Süre</strong>
            </h4>
            <p>
              TESAN, müşterilerine ait kişisel bilgileri ticari ilişki devam
              ettiği sürece yukarıdaki amaçlar doğrultusunda saklayacaktır.
              TESAN, ticari ilişki sona erdikten sonra Türk Ticaret Kanuna göre
              10 yıl boyunca verileri saklamakla yükümlüdür. Süre geçtikten
              sonra kişisel verileriniz ilk imha süresinde Kişisel Veri Saklama
              ve İmha Politikası uyarınca silinecek, yok edilecek ve/veya anonim
              hale getirilecektir. Müşteri, ilgili kanunun 5. maddesi 2.
              fıkrasındaki şartlar ve kanunen işlenmesi gereken veriler hariç
              olma kaydı ile kişisel verilerinin işlenmesi için verdiği izni her
              zaman geri alabilir.
            </p>
            <h4>
              <strong>F.Veri Sahibinin Hakları</strong>
            </h4>
            <p>
              KVKK kapsamında, kişisel verilerinize ilişkin olarak aşağıdaki
              haklara sahipsiniz;
            </p>
            <ul type="disc">
              <li>Kişisel verilerin işlenip işlenmediğini öğrenme,</li>
              <li>
                Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesini isteme,
              </li>
              <li>
                Kişisel verinizin işlenmesini gerektiren sebeplerin ortadan
                kalkması hâlinde, verilerin silinmesini veya yok edilmesini
                isteme,
              </li>
              <li>
                Talebinizce düzeltilen ya da silinen bilgilerinizin, eğer
                aktarılmış ise kişisel verilerin aktarıldığı üçüncü kişilere
                bildirilmesini isteme,
              </li>
              <li>
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması hâlinde zararın giderilmesini talep etme.
              </li>
            </ul>
            <p>
              Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit
              edici gerekli bilgiler ve kullanmak istediğiniz hakkınıza yönelik
              açıklamalarınızla birlikte yazılı talebinizi "çobançeşme Mah.
              Bilge 1 Sok. No:17 Yenibosna Bahçelievler/İstanbul adresine
              konunun KVKK ile ilgili olduğunu açıkça belirterek ıslak imzalı
              olarak veyatesan@hs01.kep.trkayıtlı elektronik posta adresimize
              güvenli elektronik imza, mobil imza ya da ilgili kişi tarafından
              TESAN'a daha önce bildirilen ve veri sorumlusunun sisteminde
              kayıtlı bulunan elektronik posta adresini kullanmak suretiyle
              gönderebilirsiniz.Başvurular Türkçe yapılmalıdır.
            </p>
            <p>
              Başvurularda, ad, soyad ve başvuru yazılı ise imza, Türkiye
              Cumhuriyeti vatandaşları için T.C. kimlik numarası, yabancılar
              için uyruğu, pasaport numarası/kimlik numarası, tebligata esas
              yerleşim yeri veya işyeri adresi, varsa bildirime esas elektronik
              posta adresi, telefon veya faks numarası ve talep konusu bulunması
              zorunludur.
            </p>
            <p>
              Kişisel veri sahibi olarak sahip olduğunuz ve yukarıda belirtilen
              haklarınızı kullanmak için yapacağınız ve kullanmayı talep
              ettiğiniz hakka ilişkin açıklamalarınızı içeren başvuruda; talep
              ettiğiniz hususun açık ve anlaşılır olması, talep ettiğiniz
              konunun şahsınız ile ilgili olması veya başkası adına hareket
              ediyor iseniz bu konuda özel olarak yetkili olmanız ve yetkinizin
              belgelendirilmesi, başvurunun kimlik ve adres bilgilerini içermesi
              ve başvuruya kimliğinizi tevsik edici belgelerin eklenmesi
              gerekmektedir.
            </p>
            <p>
              Bu kapsamda yapacağınız başvurular en kısa sürede ve en geç 30 gün
              içerisinde sonuçlandırılacaktır. İlgili kişinin başvurusuna yazılı
              olarak cevap verilecekse, on sayfaya kadar ücret alınmaz. On
              sayfanın üzerindeki her sayfa için 1 TL işlem ücreti alınabilir.
              Başvuruya cevabın CD, flaş bellek gibi bir kayıt ortamında
              verilmesi halinde TESAN tarafından talep edilebilecek ücret kayıt
              ortamının maliyetini geçemez.
            </p>
            <p>
              <strong>REFERANS ve İLGİLİ DOKüMANLAR</strong>
            </p>
            <p>Referans</p>
            <p>
              07.04.2016 tarihli 6698 sayılı "Kişisel Verilerin Korunması
              Kanunu&rdquo; ve ilgili Yönetmelikler
            </p>
            <p>İlgili Dokümanlar</p>
            <p>
              KVKK FRM HK-05 Gerçek Kişi Tüketicilere Yönelik Kişisel Verilerin
              Toplanması Açık Rıza Metni
            </p>
            <p>KVKK PLT-03 çerez Politikası</p>
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={()=>props.personalDataPolicyConfirm()}
            >
              Okudum Açık Rıza Veriyorum.
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
