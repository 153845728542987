import axios from "axios";
import { API_BASE } from "../../config/env";
export const FETCH_PRODUCT_PENDING = "FETCH_PRODUCT_PENDING";
export const FETCH_PRODUCT_FULFILLED = "FETCH_PRODUCT_FULFILLED";

export const FETCH_SEARCH_PRODUCT_PENDING = "FETCH_SEARCH_PRODUCT_PENDING";
export const FETCH_SEARCH_PRODUCT_FULFILLED = "FETCH_SEARCH_PRODUCT_FULFILLED";

export const FETCH_GETVARIANTPICTURE_PENDING =
  "FETCH_GETVARIANTPICTURE_PENDING";
export const FETCH_GETVARIANTPICTURE_FULFILLED =
  "FETCH_GETVARIANTPICTURE_FULFILLED";
export const FETCH_PRODUCT_DETAİL = "FETCH_PRODUCT_DETAİL";

export function fetchProduct(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PRODUCT",
      payload: axios
        .get(`${API_BASE}Product/GetProductById?id=${id}`)
        .then((data) => data.data.set),
    });
  };
}

export function fetchProductDetail(id) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_PRODUCT_DETAİL",
      payload: axios
        .get(`${API_BASE}Product/GetProductSpecificationsByProductId?id=${id}`)
        .then((data) => data.data.set),
    });
  };
}

export function searchProduct(word) {
    if (word !== "" && word.length >= 3) {
    return (dispatch) => {
      dispatch({
        type: "FETCH_SEARCH_PRODUCT",
        payload: axios
          .post(`${API_BASE}Product/SearchProductByName`, {
            Name: word,
          })
          .then((data) => data.data.set),
      });
    };
  }
}
export function getAllVariantPictureByProductId(productId) {
  return (dispatch) => {
    dispatch({
      type: "FETCH_GETVARIANTPICTURE",
      payload: axios
        .post(`${API_BASE}Product/GetAllVariantPictureByProductId`, {
          productId: productId,
        })
        .then((data) => data.data.set),
    });
  };
}

//Ürün stok kontrolü.
export function getStockControlByProductId(productId) {
  const data = {};
  data.productId = productId;
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  axios
    .post(`${API_BASE}Basket/ValidateProductStock`, data, config)
    .then((data) => {
      console.log(data.data.set);
      return data.data.set;
    });
}
