const IptalveIade = (props) => {
    return (
        <div className="container">
            <div className="lh-lg py-5">
                <h2 className="fw-bold mb-4">
                    Verilen Sipariş Nasıl İptal Edilir?
                </h2>
                <p className="mb-4">
                    İptal talebinizi iletmek için{' '}
                    <a href="mail:takssiparis@tesan.com.tr">
                        takssiparis@tesan.com.tr
                    </a>
                    adresine e-posta gönderebilir veya 0850 222 88 32 müşteri
                    hizmetlerimizi arayarak siparişinizi iptal edebilirsiniz.
                </p>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">
                        Aldığım Ürünü Nasıl İade Edebilirim?
                    </h4>
                    <li>
                        İade işlemlerinin gerçekleşmesi için öncelikli olarak
                        fatura alt kısmında bulunan iade bölümünün doldurması
                        gerekmektedir. Bu bölüm doldurulmadan yapılacak iade
                        talepleri kabul edilmez.
                    </li>
                    <li>
                        İade edilecek ürünü orijinal ambalajında ve kutu içeriği
                        eksiksiz şekilde fatura ile birlikte MNG Kargo{' '}
                        <strong>(anlaşma kodu: 60 194 003)</strong> ile karşı
                        ödemeli olarak iade notu ile{' '}
                        <strong>
                            Çobançeşme Mah. Bilge 1 Sok. No:17 Yenibosna 34196
                            Bahçelievler / İstanbul
                        </strong>{' '}
                        adresine Depo Bölümü dikkatine gönderebilirsiniz.
                    </li>
                </div>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">İade Süresi Nedir?</h4>
                    <li>
                        İade hakkından faydalanabilmeniz için; satın alınan
                        ürünü kutusu, ambalajı, varsa standart aksesuarları ile
                        birlikte eksiksiz ve hasarsız olarak teslim tarihinden
                        itibaren herhangi bir gerekçe göstermeden ve cezai şart
                        ödemeden ürünün tesliminden itibaren 14 (ondört) günlük
                        süre içinde iade edebilirsiniz.
                    </li>
                    <li>
                        Kredi kartına yapılan iadelerin kredi kart hesaplarına
                        yansıma süresi ilgili bankanın tasarrufundadır.
                    </li>
                    <li>
                        Alışveriş kredi kartıyla ve taksitli olarak yapılmışsa,
                        ürünü kaç taksit ile aldıysanız, banka geri ödemesini
                        taksitle yapmaktadır.
                    </li>
                    <li>
                        İadeler dönem içi işlemlerde gözükmez, bu nedenle hesap
                        ekstrenizde görebilirsiniz.
                    </li>
                </div>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">
                        Hangi Ürünlerde İade Kabul Edilmez?
                    </h4>
                    <li>
                        ALICI’nın özel istekleri ve kişisel ihtiyaçları
                        doğrultusunda üretilen veya üzerinde değişiklik ya da
                        ilaveler yapılarak kişiye özel hale getirilen ürünlerde
                        cayma hakkı kullanılamaz.
                    </li>
                    <li>
                        Tesliminden sonra ambalaj, bant, mühür, paket gibi
                        koruyucu unsurları açılmış olan ürünlerden, kullanım
                        esnasında ALICI tarafından vücut ile birebir temas
                        gerektiren iadesi sağlık ve hijyen açısından uygun
                        olmayan ürünlerde (kulaklıklar v.s.), tek kullanımlık
                        ürünlerde ve buna benzer ürünlerde arızalı/ ayıplı
                        olması halleri dışında cayma hakkı kullanılamaz.
                    </li>
                </div>
                <div className="mb-4">
                    <h4 className="fw-bold mb-3">
                        Zarar Görmüş Ürünlerde İşleyiş Nasıl Oluyor?
                    </h4>
                    <li>
                        Biz ürünlerin en uygun şekilde size ulaşması için düzgün
                        bir şekilde paketleyip ya da poşetleyip kargoya
                        veriyoruz. Kargo şirketinden kaynaklanan sorunlarda;
                        zarar görmüş paketler teslim alınmayarak teslimatçı
                        firmaya tutanak tutturulmalıdır.
                    </li>
                    <li>
                        Eğer teslimatçı firma yetkilisi paketin hasarlı
                        olmadığını düşünüyorsa, orada paketin açılarak ürünlerin
                        hasarsız teslim edildiğini kontrol ettirme ve durumun
                        yine bir tutanakla tespit edilmesini isteme hakkınız
                        vardır. Ancak paket tarafınızdan teslim alındıktan sonra
                        teslimatçı firmanın görevini layıkıyla yaptığı kabul
                        edilmiş sayılır.
                    </li>
                    <li>
                        Paket kabul edilmemiş ve tutanak tutulmuş ise, durum
                        tutanağın sizde kalan kopyasıyla birlikte en kısa
                        zamanda bize bildirilmelidir. En kısa sürede yeni ve
                        sağlıklı bir teslimatın gerçekleşmesini sağlayacağız.
                    </li>
                </div>
            </div>
        </div>
    );
};
export default IptalveIade;
