import { API_BASE, cargoPriceFree } from '../../config/env';
import { BrowserView, MobileView } from 'react-device-detect';
import {
    IconArrowRight,
    IconDiscount,
    IconPackage,
    IconReceipt,
    IconReceiptTax
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Accordion } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';
import axios from 'axios';
import { tesanCDNURL } from '../../config/env';

export default function Basket(props) {
    let params = useParams();
    const [customerId, SetCustomerId] = useState(0);
    const [selectedCampaign, SetSelectedCampaign] = useState(0);
    const [kuponKod, SetKuponKod] = useState('');
    const [basketId, SetBasketId] = useState(0);
    const [basket, setBasket] = useState();
    const [campaigns, setCampaigns] = useState();
    const [isEmployee, SetIsEmployee] = useState(false);
    const [isCampaign, SetIsCampaign] = useState(true);
    const [havePromoCode, SetHavePromoCode] = useState(false);
    let location = useLocation();
    var basketChangeArray = [];
    const cookies = new Cookies();
    const [totalInfo, setTotalInfo] = useState({
        totalPrice: 0,
        totalDiscountedPrice: 0,
        KDV: 0,
        Kargo: 0
    });
    var resumeState = true;
    const [kontrol, setKontrol] = useState(true);
    const [basketQuantity, setBasketQuantity] = useState([]);
    const [stockQuantities, setStockQuantities] = useState([]);
    const changeCookie = cookies.get('changeBasketCookie');
    function selectCampaignHandler(campaignId) {
        if (selectedCampaign == campaignId) {
            SetSelectedCampaign(0);
            delCouponCampaign();
        } else {
            SetSelectedCampaign(campaignId);
            AddCampaign(campaignId);
        }
    }

    useEffect(() => {
        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        if (data.data.set.isEmployee == false) {
                            GetActiveBasket(data.data.set.customerId);
                        }
                        SetCustomerId(data.data.set.customerId);
                        SetIsEmployee(data.data.set.isEmployee);
                        CheckCustomerId(data.data.set.customerId);
                    } else {
                        CheckCustomerId(0);
                    }
                }
            });

        function GetActiveBasket(customerId) {
            //apiden ilgili basketin kupon kodunu veya kampanyalarını çekiyoruz.
            axios
                .get(
                    `${API_BASE}basket/GetActiveBasket?customerId=${customerId}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            SetBasketId(data.data.set.id);
                            if (data.data.set.id != 0) {
                                CouponCampaignControl(data.data.set.id);
                            }
                            if (data.data.set.couponCode != null) {
                                SetKuponKod(data.data.set.couponCode);
                            } else if (data.data.set.campaignId != null) {
                                SetSelectedCampaign(data.data.set.campaignId);
                            }
                        }
                    }
                });
        }

        async function GetCampaignList(basketId) {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios
                .get(
                    `${API_BASE}Campaign/GetCampaignList?basketId=${basketId}`,
                    config
                )
                .then((data) => {
                    if (data.data) {
                        if (data.data.code == '200') {
                            setCampaigns(data.data.set);
                        }
                    }
                });
        }

        async function CouponCampaignControl(basketId) {
            //SP kupon kodu ve basket id gönderilecek gelen değer true ya da false olacak;

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            await axios
                .get(
                    `${API_BASE}Campaign/CouponCampaignControl?basketId=${basketId}`,
                    config
                )
                .then((data) => {
                    var useCampaign = data.data.set;
                    //SetIsCampaign(data.data.set);
                    if (useCampaign == 0) {
                        SetIsCampaign(false);
                        SetSelectedCampaign(0);
                        SetKuponKod('');
                        document.getElementById('couponCode').value = null;
                    } else if (useCampaign == 2) {
                        SetSelectedCampaign(0);
                        SetKuponKod('');
                        document.getElementById('couponCode').value = null;
                    }
                    //setCampaigns(data.data.set);
                })
                .then(() => {
                    GetCampaignList(basketId);
                });
        }

        function CheckCustomerId(customerId) {
            if (customerId == 0) {
                var cookiedBasket = cookies.get('basket');
                cookiedBasket && setBasket(cookiedBasket);
                SepetIslemleri(cookiedBasket, customerId);
            } else {
                const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                const config = {
                    headers: { 'Content-Type': 'application/json' }
                };
                axios
                    .get(
                        `${API_BASE}${getBasketServiceUrl}${customerId}`,
                        config
                    )
                    .then((data) => {
                        setBasket(data.data.set);
                        SepetIslemleri(data.data.set, customerId);
                    })
                    .catch();
            }
        }

        function SepetIslemleri(basket, customerId) {
            var totalPrice = 0;
            var totalDiscountedPrice = 0;
            var basketQuantities = [];
            basket &&
                basket.basketProducts &&
                basket.basketProducts.map((bp) => {
                    basketQuantities.push({
                        productId: bp.productId,
                        stockId: bp.stockId,
                        quantity: bp.quantity,
                        price: bp.price
                    });
                    totalPrice += bp.price * bp.quantity;
                    totalDiscountedPrice +=
                        bp.campaignPrice !== undefined &&
                        bp.campaignPrice !== null
                            ? bp.campaignPrice * bp.quantity
                            : bp.discountPrice !== undefined
                            ? bp.discountPrice * bp.quantity
                            : bp.price * bp.quantity;

                    setTotalInfo({
                        totalPrice: totalPrice,
                        totalDiscountedPrice: totalDiscountedPrice,
                        KDV: 18,
                        Kargo: 0
                    });
                    //StockCheck All
                    const stockCheckAll =
                        basket &&
                        basket.basketProducts &&
                        basket.basketProducts.map((bp) => ({
                            productId: bp.productId,
                            stockId: bp.stockId
                        }));
                    if (stockCheckAll != null) {
                        const config = {
                            headers: { 'Content-Type': 'application/json' }
                        };
                        axios
                            .post(
                                `${API_BASE}basket/StockCheckAll`,
                                stockCheckAll,
                                config
                            )
                            .then((data) => {
                                setStockQuantities(data.data.set);
                                const stockQuantity = data.data.set.filter(
                                    (sq) => sq.stockId == bp.stockId
                                )[0];
                                if (stockQuantity.quantity < bp.quantity) {
                                    var obj = {};
                                    obj.productId = bp.productId;
                                    obj.stockId = bp.stockId;
                                    obj.stockQuantity = stockQuantity.quantity;
                                    obj.productName = bp.productName;
                                    obj.variant =
                                        bp.variantInfo.productAttributeValueValue;
                                    basketChangeArray.push(obj);
                                    cookies.set(
                                        'changeBasketCookie',
                                        basketChangeArray,
                                        { path: '/' }
                                    );
                                    stockChange(
                                        bp.productId,
                                        bp.stockId,
                                        stockQuantity.quantity,
                                        customerId,
                                        basket
                                    );
                                }
                            })
                            .catch((error) => {
                                console.error('axios hata: ' + error);
                            });
                    }
                });

            setBasketQuantity(basketQuantities);

            if (totalInfo.totalPrice) {
                var wc = basket.basketProducts.map((wcp, i) => ({
                    item_id: wcp.productId,
                    item_name: wcp.productName,
                    item_variant: wcp.variantInfo.productAttributeValueValue,
                    quantity: wcp.quantity,
                    price: wcp.price.toFixed(2),
                    currency: 'TRY',
                    index: i
                }));

                window.dataLayer.push({
                    event: 'view_cart',
                    currency: 'TRY',
                    value: totalInfo.totalPrice.toFixed(2),
                    viewcart: {
                        items: [wc]
                    }
                });
            }
        }
    }, [totalInfo.totalPrice]);

    // gtag Ürün Silindi
    function removeFromCart(productId, productName) {
        window.dataLayer.push({
            event: 'remove_from_cart',
            removeitem: {
                items: [
                    {
                        item_id: productId,
                        item_name: productName
                    }
                ]
            }
        });
    }

    function stockChange(
        productId,
        stockId,
        stockQuantity,
        customerId,
        basket
    ) {
        if (stockQuantity == 0) {
            removeCartItem(productId, stockId, customerId, true);
        } else if (stockQuantity > 0) {
            QuantityChange(stockId, stockQuantity, customerId, basket);
        }
    }
    function removeCartItem(productId, stockId, customerId, stokKontrol) {
        if (customerId == 0) {
            var basket = cookies.get('basket');
            if (basket) {
                const product = basket.basketProducts.filter(
                    (bp) => bp.stockId === stockId
                )[0];
                if (product) {
                    const index = basket.basketProducts.indexOf(product);
                    basket.basketProducts.splice(index, 1);
                    cookies.set('basket', basket, { path: '/' });
                    window.location.reload();
                }
            }
        } else {
            const delToCartServiceUrl = 'basket/DeleteBasketItem';
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };

            const body = JSON.stringify({
                customerId: customerId,
                productId: productId,
                stockId: stockId
            });

            axios
                .post(`${API_BASE}${delToCartServiceUrl}`, body, config)
                .then((data) => {
                    setBasket(data.data.set);
                    window.location.reload();
                })
                .catch((error) => alert(error));
        }
        if (stokKontrol == false) {
            cookies.remove('changeBasketCookie');
        }
    }

    function applyCoupon() {
        var couponCode = document.getElementById('couponCode').value;

        //SP kupon kodu ve basketId gönderilecek gelen değer true ya da false olacak;
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(
                `${API_BASE}Campaign/ApplyCoupon?shoppingCartId=${basketId}&couponCode=${couponCode}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        window.location.reload();
                    }
                }
            });
    }

    function AddCampaign(campaignId) {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Campaign/ApplyCampaign?shoppingCartId=${basketId}&campaignId=${campaignId}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.set == true) {
                        window.location.reload();
                    } else {
                        alert('Kampanya uygulanamadı');
                    }
                }
            });
    }

    function delCouponCampaign() {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .post(
                `${API_BASE}Campaign/DeleteBasketCampaign/${customerId}`,
                config
            )
            .then((data) => {
                window.location.reload();
            })
            .catch();
    }

    function redirectToAddressCheck() {
        if (
            campaigns &&
            campaigns.length != 0 &&
            selectedCampaign == 0 &&
            kuponKod == ''
        ) {
            Swal.fire({
                title: 'Emin Misiniz?',
                text: 'Kampanya seçmeden devam edilecektir. Onaylıyor Musunuz?',
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Evet',
                denyButtonText: 'Hayır'
            }).then((result) => {
                if (result.isConfirmed) {
                    cookies.set('redirectUrl', '/basket', { path: '/' });
                    if (customerId == 0) {
                        window.location.href = '/login';
                    } else {
                        window.location.href = '/AddressCheck';
                    }
                } else if (result.isDenied) {
                    return false;
                }
            });
        } else {
            cookies.set('redirectUrl', '/basket', { path: '/' });
            if (customerId == 0) {
                window.location.href = '/login';
            } else {
                window.location.href = '/AddressCheck';
            }
        }
    }

    const handleQuantityBlur = (e) => {
        const quantity = Number(e.currentTarget.value);
        if (!quantity || quantity == '' || quantity == null) {
            var tmpBasket;
            if (customerId == 0) {
                tmpBasket = cookies.get('basket');
            } else {
                tmpBasket = basket;
            }
            const stockId = e.currentTarget.name.substring(9);
            var stock = tmpBasket.basketProducts.filter(
                (bq) => bq.stockId == stockId
            )[0];
            document.getElementById(e.currentTarget.name).value =
                stock.quantity;
            cookies.remove('changeBasketCookie');
        }
    };

    function QuantityChange(stockId, stockQuantity, customerId, basket) {
        var basketPlus;

        basketPlus = basket;

        const product = basketPlus.basketProducts.filter(
            (bp) => bp.stockId == stockId
        )[0];

        product.quantity = stockQuantity;
        if (customerId == 0) {
            cookies.set('basket', basketPlus, { path: '/' });
            setBasket(cookies.get('basket'));
            window.location.reload();
        } else {
            var obj = {};
            obj.customerId = customerId;
            obj.productId = product.productId;
            obj.stockId = stockId;
            obj.quantity = stockQuantity;
            obj.plusMinus = 2;

            const plusToCartServiceUrl = 'basket/UpdateBasket';
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };

            axios
                .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                .then((data) => {
                    if (data.data.code == '200') {
                        setBasket(data.data.set);
                    } else if (data.data.code == '201') {
                        alert('Stok Yeterli Değil');
                        document.getElementById('quantity-' + stockId).value =
                            product.quantity;
                        return false;
                    }
                })
                .catch((error) => alert(error));
            window.location.reload();
        }
        setBasketQuantity([...basketQuantity]);
    }

    const handleQuantityChange = (e) => {
        const quantity = Number(e.currentTarget.value);
        if (!quantity || quantity == '' || quantity == null) {
            return false;
        }
        const stockId = e.currentTarget.name.substring(9);
        const price = Number(e.currentTarget.dataset.price);
        const stockQuantity = stockQuantities.filter(
            (sq) => sq.stockId == stockId
        )[0];
        if (basketQuantity && basketQuantity.length > 0) {
            var qu = basketQuantity.filter((bq) => bq.stockId == stockId)[0];
            var basketPlus;

            if (customerId == 0) {
                basketPlus = cookies.get('basket');
            } else {
                basketPlus = basket;
            }

            const product = basketPlus.basketProducts.filter(
                (bp) => bp.stockId == stockId
            )[0];
            if (qu != null) {
                if (stockQuantity.quantity < Number(quantity)) {
                    alert('Stok yeterli değil');
                    document.getElementById(e.currentTarget.name).value =
                        product.quantity;
                    qu.quantity = stockQuantity.quantity;
                    product.quantity = stockQuantity.quantity;
                }
                qu.quantity = quantity;
                product.quantity = quantity;
                if (customerId == 0) {
                    cookies.set('basket', basketPlus, { path: '/' });
                    setBasket(cookies.get('basket'));
                } else {
                    var obj = {};
                    obj.customerId = customerId;
                    obj.productId = product.productId;
                    obj.stockId = stockId;
                    obj.quantity = quantity;
                    obj.plusMinus = 2;

                    const plusToCartServiceUrl = 'basket/UpdateBasket';

                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };

                    axios
                        .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                        .then((data) => {
                            if (data.data.code == '200') {
                                setBasket(data.data.set);
                            } else if (data.data.code == '201') {
                                alert('Stok Yeterli Değil');
                                document.getElementById(
                                    e.currentTarget.name
                                ).value = product.quantity;
                                return false;
                            }
                        })
                        .catch((error) => alert(error));
                }
                setBasketQuantity([...basketQuantity]);
            } else {
                setBasketQuantity([
                    ...basketQuantity,
                    { stockId: stockId, quantity: 1, price: price }
                ]);
            }
        } else {
            setBasketQuantity([
                { stockId: stockId, quantity: 1, price: price }
            ]);
        }

        cookies.remove('changeBasketCookie');

        setTimeout(function () {
            if (resumeState == true) {
                window.location.reload();
            }
        }, 500);
    };
    const minusClickHandle = (e) => {
        const stockId = e.currentTarget.name.substring(6);
        const price = Number(e.currentTarget.dataset.price);
        var basketMinus;
        if (customerId == 0) {
            basketMinus = cookies.get('basket');
        } else {
            basketMinus = basket;
        }

        const product = basketMinus.basketProducts.filter(
            (bp) => bp.stockId == stockId
        )[0];
        if (basketQuantity && basketQuantity.length > 0) {
            var qu = basketQuantity.filter((bq) => bq.stockId == stockId)[0];

            if (qu != null) {
                if (qu.quantity > 1) {
                    qu.quantity--;
                    product.quantity--;
                    if (customerId == 0) {
                        cookies.set('basket', basketMinus, { path: '/' });
                        setBasket(cookies.get('basket'));
                    } else {
                        var obj = {};
                        obj.customerId = customerId;
                        obj.productId = product.productId;
                        obj.stockId = stockId;
                        obj.plusMinus = 0;

                        const minusToCartServiceUrl = 'basket/UpdateBasket';

                        const config = {
                            headers: { 'Content-Type': 'application/json' }
                        };

                        axios
                            .post(
                                `${API_BASE}${minusToCartServiceUrl}`,
                                obj,
                                config
                            )
                            .then((data) => {
                                setBasket(data.data.set);
                            })
                            .catch((error) => alert(error));
                    }
                    setBasketQuantity(basketQuantity);
                }
            } else {
                product.quantity = 1;
                if (customerId == 0) {
                    cookies.set('basket', basketMinus, { path: '/' });
                    setBasket(cookies.get('basket'));
                } else {
                    var obj = {};
                    obj.customerId = customerId;
                    obj.productId = product.productId;
                    obj.stockId = stockId;
                    obj.plusMinus = 0;

                    const plusToCartServiceUrl = 'basket/UpdateBasket';

                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };

                    axios
                        .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                        .then((data) => {
                            setBasket(data.data.set);
                        })
                        .catch((error) => alert(error));
                }
                setBasketQuantity([
                    ...basketQuantity,
                    { stockId: stockId, quantity: 1 }
                ]);
            }
        } else {
            setBasketQuantity([
                { stockId: stockId, quantity: 1, price: price }
            ]);
        }
        cookies.remove('changeBasketCookie');
        window.location.reload();
        // calculateTotalPrice();
    };
    const plusClickHandle = (e) => {
        const stockId = e.currentTarget.name.substring(5);
        const price = Number(e.currentTarget.dataset.price);
        const stockQuantity = stockQuantities.filter(
            (sq) => sq.stockId == stockId
        )[0];
        if (basketQuantity && basketQuantity.length > 0) {
            var qu = basketQuantity.filter((bq) => bq.stockId == stockId)[0];
            var basketPlus;

            if (customerId == 0) {
                basketPlus = cookies.get('basket');
            } else {
                basketPlus = basket;
            }
            const product = basketPlus.basketProducts.filter(
                (bp) => bp.stockId == stockId
            )[0];
            if (qu != null) {
                qu.quantity++;
                product.quantity++;
                if (stockQuantity.quantity < product.quantity) {
                    alert('Stok yeterli değil');
                    qu.quantity = stockQuantity.quantity;
                    product.quantity = stockQuantity.quantity;
                    return false;
                }

                if (customerId == 0) {
                    cookies.set('basket', basketPlus, { path: '/' });
                    setBasket(cookies.get('basket'));
                } else {
                    var obj = {};
                    obj.customerId = customerId;
                    obj.productId = product.productId;
                    obj.stockId = stockId;
                    obj.plusMinus = 1;

                    const plusToCartServiceUrl = 'basket/UpdateBasket';

                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };

                    axios
                        .post(`${API_BASE}${plusToCartServiceUrl}`, obj, config)
                        .then((data) => {
                            if (data.data.code == '200') {
                                setBasket(data.data.set);
                            } else if (data.data.code == '201') {
                                alert('Stok Yeterli Değil');
                                return false;
                            }
                        })
                        .catch((error) => alert(error));
                }
                setBasketQuantity([...basketQuantity]);
            } else {
                setBasketQuantity([
                    ...basketQuantity,
                    { stockId: stockId, quantity: 1, price: price }
                ]);
            }
        } else {
            setBasketQuantity([
                { stockId: stockId, quantity: 1, price: price }
            ]);
        }

        cookies.remove('changeBasketCookie');

        setTimeout(function () {
            if (resumeState == true) {
                window.location.reload();
            }
        }, 500);
    };

    return (
        <section className="cart-content py-5 flex-fill">
            <BrowserView>
                <div className="container">
                    {changeCookie &&
                        changeCookie.map((item) => (
                            <div
                                className="alert alert-warning alert-dismissible fade show"
                                role="alert"
                                key={item.stockId}
                            >
                                <h5 className="fw-bold mb-1">
                                    <i className="bi bi-info-circle-fill me-2"></i>{' '}
                                    Sepetiniz Düzenlendi
                                </h5>
                                <p className="mb-0">
                                    <span className="fw-bold me-2">
                                        {item.productName} - {item.variant}
                                    </span>
                                    ürünü stok durumu değişti verebileceğiniz
                                    maximum sipariş :{' '}
                                    <span className="fw-bold">
                                        {item.stockQuantity}
                                    </span>
                                </p>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                ></button>
                            </div>
                        ))}
                    {basket == undefined ||
                    basket.basketProducts == undefined ||
                    basket.basketProducts.length < 1 ? (
                        <div className="empty-basket p-4 text-primary text-center d-flex align-items-center gap-3 justify-content-center">
                            <i className="bi bi-cart3 h3"></i>
                            <h5 className="fw-bold">
                                Alışveriş sepetinizde ürün bulunmamaktadır.
                            </h5>
                        </div>
                    ) : (
                        <div className="row">
                            {/* Basket Header */}
                            <div className="col-sm-9">
                                <div className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-5">
                                    {/* Sepetteki Ürün Adedi */}
                                    <h5 className="fw-bold">
                                        <i className="bi bi-bag me-2"></i>
                                        Sepetinizde{' '}
                                        <strong>
                                            {basket &&
                                            basket.basketProducts &&
                                            basket.basketProducts.length > 0
                                                ? basket.basketProducts.length
                                                : 0}{' '}
                                            ürün
                                        </strong>{' '}
                                        Bulunuyor
                                    </h5>
                                    {/* Ücretsiz Kargo Bildirimi */}
                                    {totalInfo.totalPrice.toFixed(2) <
                                    cargoPriceFree ? (
                                        <div className="text-center small border border-danger text-danger rounded-1 py-2 px-4">
                                            <p className="mb-0">
                                                <i
                                                    className="bi bi-box-seam 
                                            me-2"
                                                ></i>{' '}
                                                <span className="fw-bold">
                                                    {cargoPriceFree} TL
                                                </span>{' '}
                                                ve üzeri alışverişlerde{' '}
                                                <span className="fw-bold">
                                                    KARGO ÜCRETSİZ!
                                                </span>
                                            </p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Sepetteki Ürünler */}
                                <div className="cart-item d-flex flex-column gap-5 mb-5">
                                    <div className="d-flex flex-column gap-2">
                                        {/* Ürün */}
                                        {basket &&
                                            basket.basketProducts &&
                                            basket.basketProducts.map(
                                                (item) => (
                                                    <div
                                                        key={item.stockId}
                                                        id={item.stockId}
                                                        className="product d-flex flex-row align-items-center gap-3 border rounded-2 pe-5 position-relative"
                                                    >
                                                        <div className="image border-end rounded-start flex-shrink-1 p-3">
                                                            <img
                                                                src={
                                                                    item.productImage !=
                                                                    null
                                                                        ? item.productImage
                                                                        : '/assets/images/noimage.jpg'
                                                                }
                                                                className="img-fluid"
                                                                alt={
                                                                    item.productName
                                                                }
                                                            />
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="d-flex flex-row align-items-center gap-3">
                                                                <div className="w-100">
                                                                    {/* Ürün Adı ve Renk */}
                                                                    <div className="d-flex flex-column">
                                                                        <a
                                                                            href={`/detail/${item.productId}`}
                                                                            className="title text-dark fw-bold"
                                                                        >
                                                                            {
                                                                                item.productName
                                                                            }
                                                                        </a>
                                                                        <span className="d-sm-block d-none description text-black-50">
                                                                            {
                                                                                item.productShortDesc
                                                                            }{' '}
                                                                            {item
                                                                                .variantInfo
                                                                                .productAttributeValueValue &&
                                                                                `Renk: ${item.variantInfo.productAttributeValueValue}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-1">
                                                                    <div className="piece d-flex flex-row gap-3 align-items-center">
                                                                        <div className="price d-flex flex-column w-100">
                                                                            <span>
                                                                                {' '}
                                                                                Fiyat{' '}
                                                                            </span>
                                                                            <div className="price isdiscount flex-grow-1 flex-shrink-0">
                                                                                <div className="priceGroup">
                                                                                    {item.campaignPrice !=
                                                                                    null ? (
                                                                                        <div className="mainprice text-black-50 text-decoration-line-through lh-1">
                                                                                            {item.price.toFixed(
                                                                                                2
                                                                                            )}{' '}
                                                                                            TL
                                                                                        </div>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                    <div className="discountprice fw-bold text-secondary lh-1">
                                                                                        {item.campaignPrice !=
                                                                                        null
                                                                                            ? item.campaignPrice.toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : item.discountPrice !=
                                                                                              null
                                                                                            ? item.discountPrice.toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : item.price.toFixed(
                                                                                                  2
                                                                                              )}{' '}
                                                                                        TL
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="quantity">
                                                                            <div className="input-group">
                                                                                <button
                                                                                    className="btn minus"
                                                                                    type="button"
                                                                                    name={
                                                                                        'minus-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    onClick={
                                                                                        minusClickHandle
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-dash"></i>
                                                                                </button>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name={
                                                                                        'quantity-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    id={
                                                                                        'quantity-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    defaultValue={
                                                                                        item.quantity
                                                                                    }
                                                                                    onChange={
                                                                                        handleQuantityChange
                                                                                    }
                                                                                    onBlur={
                                                                                        handleQuantityBlur
                                                                                    }
                                                                                />
                                                                                <button
                                                                                    className="btn plus btn-outline-light"
                                                                                    type="button"
                                                                                    name={
                                                                                        'plus-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    onClick={
                                                                                        plusClickHandle
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-plus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        className="remove-to-cart"
                                                                        onClick={() => {
                                                                            removeCartItem(
                                                                                item.productId,
                                                                                item.stockId,
                                                                                customerId,
                                                                                false
                                                                            );
                                                                            removeFromCart(
                                                                                item.productId,
                                                                                item.productName,
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>

                                {campaigns && campaigns.length > 0 ? (
                                    <div className="available-campaigns">
                                        <header className="mb-5">
                                            <h5 className="fw-bold">
                                                <i className="bi bi-bookmark-star me-2"></i>
                                                Kullanılabilir Kampanyalar
                                            </h5>
                                        </header>
                                        <div className="row g-3">
                                            {/* Kampanya */}
                                            {campaigns &&
                                                campaigns.map((cmp) => (
                                                    <div
                                                        key={cmp.campaignId}
                                                        className="col-sm-6 h-100"
                                                        onClick={() =>
                                                            selectCampaignHandler(
                                                                cmp.campaignId
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`campaign-items selectable ${
                                                                selectedCampaign ==
                                                                cmp.campaignId
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <div className="campaign-thumb">
                                                                <img
                                                                    src={
                                                                        cmp.campaignImage !=
                                                                        null
                                                                            ? tesanCDNURL +
                                                                              cmp.campaignImage
                                                                            : 'assets/images/noimage.jpg'
                                                                    }
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="campaign-detail">
                                                                <div className="stack">
                                                                    <h6 className="campaign-title">
                                                                        {
                                                                            cmp.campaignName
                                                                        }
                                                                    </h6>
                                                                    <span className="campaign-description">
                                                                        {
                                                                            cmp.campaignDetail
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {/* Sipariş Özeti */}
                            <div className="col-sm-3 mb-5">
                                <div className="summary border rounded-2 sticky-upper overflow-hidden">
                                    <header className="border-bottom p-4 h5 fw-bold">
                                        Sipariş Özeti
                                    </header>
                                    <div className="d-flex flex-column">
                                        <div className="detail d-flex flex-column gap-3 p-4 border-bottom">
                                            {/* Özet Elemanı */}
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <IconReceipt
                                                    size={24}
                                                    className="text-secondary"
                                                />
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        İndirimsiz Toplam
                                                    </span>
                                                    <span className="fw-bold">
                                                        {totalInfo.totalPrice.toFixed(
                                                            2
                                                        )}{' '}
                                                        TL{' '}
                                                        <span className="text-danger small fw-normal">
                                                            KDV Dahil
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            {totalInfo.totalPrice >
                                            totalInfo.totalDiscountedPrice ? (
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <IconReceiptTax
                                                        size={24}
                                                        className="text-secondary"
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            İndirimli Tutar
                                                        </span>
                                                        <span className="fw-bold">
                                                            {totalInfo.totalDiscountedPrice.toFixed(
                                                                2
                                                            )}{' '}
                                                            TL
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {totalInfo.totalPrice >
                                            totalInfo.totalDiscountedPrice ? (
                                                <div className="d-flex justify-content-start align-items-start gap-3">
                                                    <IconDiscount
                                                        size={24}
                                                        className="text-secondary"
                                                    />
                                                    <div className="d-flex flex-column">
                                                        <span className="text-black-50 small">
                                                            Toplam İndirim
                                                        </span>
                                                        <span className="fw-bold">
                                                            {(
                                                                totalInfo.totalPrice -
                                                                totalInfo.totalDiscountedPrice
                                                            ).toFixed(2)}{' '}
                                                            TL
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <IconPackage
                                                    size={24}
                                                    className="text-secondary"
                                                />
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        Kargo Bedeli
                                                    </span>
                                                    {Number(
                                                        totalInfo.totalDiscountedPrice
                                                    ) >= cargoPriceFree ? (
                                                        <>
                                                            <span className="fw-bold text-success">
                                                                ÜCRETSİZ
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <span className="text-black-50 small">
                                                            Sonraki adımda
                                                            hesaplanacak
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="total px-4 pt-4">
                                            <div className="fw-normal text-black-50">
                                                Ödenecek Tutar
                                            </div>
                                            <div className="fw-bolder h4">
                                                {' '}
                                                {(
                                                    totalInfo.totalDiscountedPrice +
                                                    totalInfo.Kargo
                                                ).toFixed(2)}{' '}
                                                TL
                                            </div>
                                        </div>
                                        {/* İndirim Kodu Girişi */}
                                        {isEmployee == false ? (
                                            <div>
                                                {customerId ? (
                                                    <div className="d-grid">
                                                        <div className="mx-4 mt-4 px-3 py-2 border rounded-2">
                                                            {!kuponKod ? (
                                                                <small className="fw-bold">
                                                                    İndirim Kodu
                                                                </small>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {!kuponKod ? (
                                                                <div className="input-group coupon-code">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border-0 p-0 small"
                                                                        id="couponCode"
                                                                        name="couponCode"
                                                                        placeholder="İndirim Kodunuzu Yazınız"
                                                                        disabled={
                                                                            kuponKod
                                                                                ? 'disabled'
                                                                                : ''
                                                                        }
                                                                        defaultValue={
                                                                            kuponKod
                                                                                ? kuponKod
                                                                                : ''
                                                                        }
                                                                        aria-label="Kupon"
                                                                        aria-describedby="coupon-apply"
                                                                    />
                                                                    <button
                                                                        onClick={
                                                                            applyCoupon
                                                                        }
                                                                        disabled={
                                                                            kuponKod
                                                                                ? 'disabled'
                                                                                : ''
                                                                        }
                                                                        className="btn rounded-2"
                                                                        type="button"
                                                                        id="coupon-apply"
                                                                    >
                                                                        <i className="bi bi-check"></i>
                                                                    </button>
                                                                    {isCampaign ==
                                                                    false ? (
                                                                        <div
                                                                            id="passwordHelpBlock"
                                                                            className="form-text text-danger"
                                                                        >
                                                                            Girdiğiniz
                                                                            kod
                                                                            geçerli
                                                                            değildir.
                                                                            Lütfen
                                                                            kodu
                                                                            kontrol
                                                                            ediniz.
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {kuponKod ? (
                                                                <div
                                                                    className="alert alert-dismissible fade show d-flex flex-column p-0 m-0"
                                                                    role="alert"
                                                                >
                                                                    <span className="small text-black-50">
                                                                        Kod
                                                                        Uygulandı
                                                                    </span>
                                                                    <span className="fw-bold text-primary">
                                                                        {
                                                                            kuponKod
                                                                        }
                                                                    </span>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close small"
                                                                        data-bs-dismiss="alert"
                                                                        aria-label="Close"
                                                                        onClick={
                                                                            delCouponCampaign
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex flex-column small mx-4 mt-4 px-3 py-2 border rounded-2">
                                                        <span className="fw-bold">
                                                            İndirim Kodun mu var
                                                            ?
                                                        </span>
                                                        <span className="d-flex">
                                                            <a
                                                                href="/Login"
                                                                className="text-dark"
                                                            >
                                                                Üye Girişi Yap
                                                            </a>
                                                            <IconArrowRight
                                                                size={16}
                                                                className="ms-auto"
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className="d-grid mt-4">
                                            <button
                                                onClick={redirectToAddressCheck}
                                                className="btn btn-lg btn-primary py-3 fw-bold rounded-2 mx-4 mb-4 d-flex justify-content-between
                                            "
                                                type="button"
                                            >
                                                <span>Devam Et</span>
                                                <i className="bi bi-chevron-right ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </BrowserView>
            <MobileView>
                <div className="container">
                    {changeCookie &&
                        changeCookie.map((item) => (
                            <div
                                className="alert alert-warning alert-dismissible fade show"
                                role="alert"
                                key={item.stockId}
                            >
                                <h5 className="fw-bold mb-1">
                                    <i className="bi bi-info-circle-fill me-2"></i>{' '}
                                    Sepetiniz Düzenlendi
                                </h5>
                                <p className="mb-0">
                                    <span className="fw-bold me-2">
                                        {item.productName} - {item.variant}
                                    </span>
                                    ürünü stok durumu değişti verebileceğiniz
                                    maximum sipariş :{' '}
                                    <span className="fw-bold">
                                        {item.stockQuantity}
                                    </span>
                                </p>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                ></button>
                            </div>
                        ))}
                    {basket == undefined ||
                    basket.basketProducts == undefined ||
                    basket.basketProducts.length < 1 ? (
                        <div className="empty-basket p-4 text-primary text-center d-flex align-items-center gap-3 justify-content-center">
                            <i className="bi bi-cart3 h3"></i>
                            <h5 className="fw-bold">
                                Alışveriş sepetinizde ürün bulunmamaktadır.
                            </h5>
                        </div>
                    ) : (
                        <div className="row">
                            {/* Basket Header */}
                            <div className="col-sm-9">
                                <div className="header d-flex flex-wrap gap-3 justify-content-between align-items-center mb-5">
                                    {/* Sepetteki Ürün Adedi */}
                                    <h5 className="fw-bold">
                                        <i className="bi bi-bag me-2"></i>
                                        Sepet İçeriği
                                    </h5>
                                </div>
                                {/* Sepetteki Ürünler */}
                                {/* Ücretsiz Kargo Bildirimi */}
                                {totalInfo.totalPrice.toFixed(2) <
                                cargoPriceFree ? (
                                    <div className="text-center border border-danger text-danger rounded-3 py-2 px-4 w-100 mb-3">
                                        <p className="mb-0">
                                            <i
                                                className="bi bi-box-seam 
                                            me-2"
                                            ></i>{' '}
                                            <span className="fw-bold">
                                                {cargoPriceFree} TL
                                            </span>{' '}
                                            ve üzeri alışverişlerde <br />
                                            <span className="fw-bold">
                                                KARGO ÜCRETSİZ!
                                            </span>
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="cart-item d-flex flex-column gap-5 mb-5">
                                    <div className="d-flex flex-column gap-2">
                                        {/* Ürün */}
                                        {basket &&
                                            basket.basketProducts &&
                                            basket.basketProducts.map(
                                                (item) => (
                                                    <div
                                                        key={item.stockId}
                                                        id={item.stockId}
                                                        className="product d-flex flex-row align-items-stretch gap-3 border rounded-3 pe-5 position-relative overflow-hidden shadow-sm"
                                                    >
                                                        <div className="image d-flex align-items-center border-end rounded-start flex-shrink-1 p-3">
                                                            <img
                                                                src={
                                                                    item.productImage !=
                                                                    null
                                                                        ? item.productImage
                                                                        : '/assets/images/noimage.jpg'
                                                                }
                                                                className="img-fluid"
                                                                alt={
                                                                    item.productName
                                                                }
                                                            />
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="d-flex flex-column align-items-center gap-3 py-4">
                                                                <div className="w-100">
                                                                    {/* Ürün Adı ve Renk */}
                                                                    <div className="d-flex flex-column">
                                                                        <a
                                                                            href={`/detail/${item.productId}`}
                                                                            className="title text-dark fw-bold"
                                                                        >
                                                                            {
                                                                                item.productName
                                                                            }
                                                                        </a>
                                                                        <span className="d-sm-block d-none description text-black-50">
                                                                            {
                                                                                item.productShortDesc
                                                                            }{' '}
                                                                            {item
                                                                                .variantInfo
                                                                                .productAttributeValueValue &&
                                                                                `Renk: ${item.variantInfo.productAttributeValueValue}`}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-1">
                                                                    <div className="piece d-flex flex-row gap-3 align-items-center">
                                                                        <div className="price d-flex gap-2 w-100">
                                                                            <span>
                                                                                Fiyat
                                                                            </span>

                                                                            <div className="priceGroup">
                                                                                {item.campaignPrice !=
                                                                                null ? (
                                                                                    <div className="mainprice text-black-50">
                                                                                        <span
                                                                                            style={{
                                                                                                textDecoration:
                                                                                                    'line-through'
                                                                                            }}
                                                                                        >
                                                                                            {item.price.toFixed(
                                                                                                2
                                                                                            )}{' '}
                                                                                            TL
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                <div className="discountprice">
                                                                                    <span className="fw-bold text-secondary">
                                                                                        {item.campaignPrice !=
                                                                                        null
                                                                                            ? item.campaignPrice.toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : item.discountPrice !=
                                                                                              null
                                                                                            ? item.discountPrice.toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : item.price.toFixed(
                                                                                                  2
                                                                                              )}{' '}
                                                                                        TL
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="quantity">
                                                                            <div className="input-group">
                                                                                <button
                                                                                    className="btn minus"
                                                                                    type="button"
                                                                                    name={
                                                                                        'minus-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    onClick={
                                                                                        minusClickHandle
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-dash"></i>
                                                                                </button>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name={
                                                                                        'quantity-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    id={
                                                                                        'quantity-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    defaultValue={
                                                                                        item.quantity
                                                                                    }
                                                                                    onChange={
                                                                                        handleQuantityChange
                                                                                    }
                                                                                    onBlur={
                                                                                        handleQuantityBlur
                                                                                    }
                                                                                />
                                                                                <button
                                                                                    className="btn plus btn-outline-light"
                                                                                    type="button"
                                                                                    name={
                                                                                        'plus-' +
                                                                                        item.stockId
                                                                                    }
                                                                                    onClick={
                                                                                        plusClickHandle
                                                                                    }
                                                                                >
                                                                                    <i className="bi bi-plus"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        className="remove-to-cart"
                                                                        onClick={() => {
                                                                            removeCartItem(
                                                                                item.productId,
                                                                                item.stockId,
                                                                                customerId,
                                                                                false
                                                                            );
                                                                            removeFromCart(
                                                                                item.productId,
                                                                                item.productName,
                                                                                false
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>

                                {campaigns && campaigns.length > 0 ? (
                                    <div className="available-campaigns">
                                        <header className="mb-5">
                                            <h5 className="fw-bold">
                                                <i className="bi bi-bookmark-star me-2"></i>
                                                Kullanılabilir Kampanyalar
                                            </h5>
                                        </header>
                                        <div className="row g-3">
                                            {campaigns &&
                                                campaigns.map((cmp) => (
                                                    <div
                                                        key={cmp.campaignId}
                                                        className="col-6 h-100"
                                                        onClick={() =>
                                                            selectCampaignHandler(
                                                                cmp.campaignId
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`campaign-items selectable ${
                                                                selectedCampaign ==
                                                                cmp.campaignId
                                                                    ? 'selected'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <div className="campaign-thumb">
                                                                <img
                                                                    src={
                                                                        cmp.campaignImage !=
                                                                        null
                                                                            ? tesanCDNURL +
                                                                              cmp.campaignImage
                                                                            : 'assets/images/noimage.jpg'
                                                                    }
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="campaign-detail">
                                                                <div className="stack">
                                                                    <h6 className="campaign-title">
                                                                        {
                                                                            cmp.campaignName
                                                                        }
                                                                    </h6>
                                                                    <span className="campaign-description">
                                                                        {
                                                                            cmp.campaignDetail
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="position-fixed bottom-0 p-0 z-index-3 w-100">
                    <div className="d-flex flex-column gap-3 bg-white p-3 m-3 rounded-3 border shadow">
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className="rounded-3">
                                    <i className="bi bi-receipt me-2"></i>
                                    <span>Detayları Göster</span>
                                </Accordion.Header>
                                <Accordion.Body className="pt-2 pb-0">
                                    <div className="detail d-flex flex-column gap-3 py-4">
                                        {/* Özet Elemanı */}
                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <IconReceipt
                                                size={24}
                                                className="text-secondary"
                                            />
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    İndirimsiz Toplam
                                                </span>
                                                <span className="fw-bold">
                                                    {totalInfo.totalPrice.toFixed(
                                                        2
                                                    )}{' '}
                                                    TL{' '}
                                                    <span className="text-danger small fw-normal">
                                                        ({totalInfo.KDV}% KDV
                                                        Dahil)
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        {totalInfo.totalPrice >
                                        totalInfo.totalDiscountedPrice ? (
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <IconReceiptTax
                                                    size={24}
                                                    className="text-secondary"
                                                />
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        İndirimli Tutar
                                                    </span>
                                                    <span className="fw-bold">
                                                        {totalInfo.totalDiscountedPrice.toFixed(
                                                            2
                                                        )}{' '}
                                                        TL
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {totalInfo.totalPrice >
                                        totalInfo.totalDiscountedPrice ? (
                                            <div className="d-flex justify-content-start align-items-start gap-3">
                                                <IconDiscount
                                                    size={24}
                                                    className="text-secondary"
                                                />
                                                <div className="d-flex flex-column">
                                                    <span className="text-black-50 small">
                                                        Toplam İndirim
                                                    </span>
                                                    <span className="fw-bold">
                                                        {(
                                                            totalInfo.totalPrice -
                                                            totalInfo.totalDiscountedPrice
                                                        ).toFixed(2)}{' '}
                                                        TL
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        <div className="d-flex justify-content-start align-items-start gap-3">
                                            <IconPackage
                                                size={24}
                                                className="text-secondary"
                                            />
                                            <div className="d-flex flex-column">
                                                <span className="text-black-50 small">
                                                    Kargo Bedeli
                                                </span>
                                                {Number(
                                                    totalInfo.totalDiscountedPrice
                                                ) >= cargoPriceFree ? (
                                                    <>
                                                        <span className="fw-bold text-success">
                                                            ÜCRETSİZ
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span className="text-black-50 small">
                                                        Sonraki adımda
                                                        hesaplanacak
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* İndirim Kodu Girişi */}
                        {isEmployee == false ? (
                            <div>
                                {customerId ? (
                                    <div className="d-flex flex-column gap-3">
                                        <div className="d-flex flex-column gap-3">
                                            {!kuponKod ? (
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="havePromoCode"
                                                        onClick={() =>
                                                            SetHavePromoCode(
                                                                !havePromoCode
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label fw-bold"
                                                        htmlFor="havePromoCode"
                                                    >
                                                        İndirim Kodu / Hediye
                                                        Çeki Kullan
                                                    </label>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {!kuponKod ? (
                                                <div
                                                    className={
                                                        havePromoCode
                                                            ? 'input-group border rounded-2 overflow-hidden ps-3'
                                                            : 'd-none'
                                                    }
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 p-0 small"
                                                        id="couponCode"
                                                        name="couponCode"
                                                        placeholder="İndirim Kodunuzu Yazınız"
                                                        disabled={
                                                            kuponKod
                                                                ? 'disabled'
                                                                : ''
                                                        }
                                                        defaultValue={
                                                            kuponKod
                                                                ? kuponKod
                                                                : ''
                                                        }
                                                        aria-label="Kupon"
                                                        aria-describedby="coupon-apply"
                                                    />
                                                    <button
                                                        onClick={applyCoupon}
                                                        disabled={
                                                            kuponKod
                                                                ? 'disabled'
                                                                : ''
                                                        }
                                                        className="btn btn-dark rounded-2"
                                                        type="button"
                                                        id="coupon-apply"
                                                    >
                                                        <i className="bi bi-check h2"></i>
                                                    </button>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {isCampaign == false ? (
                                                <div
                                                    id="passwordHelpBlock"
                                                    className="form-text text-danger mt-2"
                                                >
                                                    Girdiğiniz kod geçerli
                                                    değildir. Lütfen kodu
                                                    kontrol ediniz.
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {kuponKod ? (
                                                <div
                                                    className="alert alert-dismissible fade show d-flex flex-column p-0 m-0"
                                                    role="alert"
                                                >
                                                    <span className="small text-black-50">
                                                        Kod Uygulandı
                                                    </span>
                                                    <span className="fw-bold text-primary">
                                                        {kuponKod}
                                                    </span>
                                                    <button
                                                        type="button"
                                                        className="btn-close small"
                                                        data-bs-dismiss="alert"
                                                        aria-label="Close"
                                                        onClick={
                                                            delCouponCampaign
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column small px-3 py-2 border rounded-2">
                                        <span className="fw-bold">
                                            İndirim Kodun mu var ?
                                        </span>
                                        <span className="d-flex">
                                            <a href="/Login">Üye Girişi Yap</a>
                                            <IconArrowRight
                                                size={16}
                                                className="ms-auto"
                                            />
                                        </span>
                                    </div>
                                )}
                            </div>
                        ) : (
                            ''
                        )}

                        <div className="d-flex justify-content-between align-items-center">
                            <div className="total">
                                <div className="fw-normal text-black-50">
                                    Ödenecek Tutar
                                </div>
                                <div className="fw-bolder h4 text-success">
                                    {' '}
                                    {(
                                        totalInfo.totalDiscountedPrice +
                                        totalInfo.Kargo
                                    ).toFixed(2)}{' '}
                                    TL
                                </div>
                            </div>
                            <button
                                onClick={redirectToAddressCheck}
                                className="btn btn-lg btn-primary py-3 fw-bold rounded-3 d-flex justify-content-between"
                                type="button"
                            >
                                <span>Devam Et</span>
                                <i className="bi bi-chevron-right ms-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </MobileView>
        </section>
    );
}
