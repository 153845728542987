import React from "react";

export default function MailValidationSuccess() {
  return (
    <div className="d-flex mailValidationSuccess py-5 flex-fill align-items-center">
      <div className="container">
        <div className="d-flex justify-content-center flex-column text-center">
          <div
            className="d-flex justify-content-center align-items-center bg-success rounded-circle mx-auto mb-4 shadow"
            style={{ width: "7rem", height: "7rem" }}
          >
            <i className="bi bi-envelope-open display-3 text-white"></i>
          </div>
          <h3 className="fw-bold mb-3 text-success">
            Mail Adresi Doğrulama İşlemi
            <br />
            Başarıyla Tamamlandı
          </h3>
          <p className="text-black-50">
            Giriş yaparak kullanıcı paneline erişebilir, alışveriş
            yapabilirsiniz.
          </p>
          <div className="d-grid col-3 mx-auto mt-5">
            <a href="/Login" type="button" className="btn btn-lg btn-primary">
              Giriş Yap
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
