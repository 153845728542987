export const ADD_BASKET = 'ADD_BASKET';
export const OPEN_MODAL = 'OPEN_MODAL';

export const openModal = (productId) => ({
    type: OPEN_MODAL,
    payload: productId
});

export const addBasket = (product) => ({
    type: ADD_BASKET,
    payload: product
});



