export default function PreInformationForm(props) {
    const fullname = `${props && props.userInfo.firstName}  ${
        props && props.userInfo.lastname
    }`;
    const shippingAddress = props && props.userInfo.shippingAddress;
    const billingAddress = props && props.userInfo.billingAddress;
    return (
        <>
            <p>
                <strong>1.KONU</strong>
            </p>
            <p>
                İşbu Satış Sözleşmesi Ön Bilgi Formu’nun konusu, SATICI' nın,
                SİPARİŞ VEREN/ALICI'ya satışını yaptığı, aşağıda nitelikleri ve
                satış fiyatı belirtilen ürün/ürünlerin satışı ve teslimi ile
                ilgili olarak 6502 sayılı Tüketicilerin Korunması Hakkındaki
                Kanun ve Mesafeli Sözleşmeler Yönetmeliği (RG:27.11.2014/29188)
                hükümleri gereğince tarafların hak ve yükümlülüklerini
                kapsamaktadır. İş bu ön bilgilendirme formunu kabul etmekle
                ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş
                konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek
                ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda
                bilgilendirildiğini peşinen kabul eder.
            </p>
            <p>
                <strong>
                    2. SATICI BİLGİLERİ (Bundan sonra “SATICI” olarak
                    anılacaktır.)
                </strong>
            </p>
            <p>
                <strong>Unvanı : TESAN İletişim A.Ş.</strong>
            </p>
            <p>
                <strong>
                    Çobançeşme Mahallesi, Sanayi Caddesi, Bilge 1 Sokak No: 17
                    Yenibosna 34196 Bahçelievler / İstanbul
                </strong>
            </p>
            <p>
                <strong>Telefon : 0212 454 60 00</strong>
            </p>
            <p>
                <strong>Eposta : </strong>
                <a href="mailto:tesan@tesan.com.tr">
                    <strong>tesan@tesan.com.tr</strong>
                </a>
                <strong></strong>
            </p>
            <p>
                <strong>Mersis No: 0840004806900010</strong>
            </p>
            <p>
                <strong>
                    3. ALICI BİLGİLERİ (Bundan sonra “ALICI” olarak
                    anılacaktır.)
                </strong>
            </p>
            <p>
                <strong>Teslim edilecek kişi : </strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.firstName}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.lastName}
            </p>
            <p>
                <strong>Teslimat Adresi : </strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address1}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address2}{' '}
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.district}
                /
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.stateProvince}
            </p>
            <p>
                <strong>Telefon :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.phoneNumber}
            </p>
            <p>
                <strong>Eposta / Kullanıcı adı :</strong>
                {props &&
                    props.unregisterEmail &&
                    props.unregisterEmail.unregisterMail}
            </p>
            <p>
                <strong>4. SÖZLEŞME KONUSU ÜRÜN/ÜRÜNLER BİLGİLERİ</strong>
            </p>
            <p>
                <strong>4.1.</strong>
                Malın /Ürün/Ürünlerin/Hizmetin temel özellikleri (türü, miktarı,
                marka/modeli, rengi, adedi) SATICI’ya ait internet sitesinde yer
                almaktadır. Ürünün temel özelliklerini kampanya süresince
                inceleyebilirsiniz. Kampanya belirtilen geçerlilik tarihine
                kadar geçerlidir.
            </p>
            <p>
                <strong>4.2.</strong>
                Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
                edilen fiyatlar ve vaatler güncelleme yapılana ve değiştirilene
                kadar geçerlidir. Süreli olarak ilan edilen fiyatlar ise
                belirtilen süre sonuna kadar geçerlidir.
            </p>
            <p>
                <strong>4.3.</strong>
                Sözleşme konusu mal ya da hizmetin tüm vergiler dâhil satış
                fiyatı aşağıdaki tabloda gösterilmiştir.
            </p>
            <p>
                <strong>Kargo Tutarı : </strong>
                {props && props.totalInfo.Kargo} TL
            </p>
            <p>
                <strong>Ödeme Şekli ve Planı :</strong>
                Kredi Kartı
            </p>
            <p>
                {' '}
                {props && props.instalment} x{' '}
                {(
                    props &&
                    props.totalInfo &&
                    (props.totalInfo.totalDiscountedPrice +
                        props.totalInfo.Kargo) /
                        (props && props.instalment)
                ).toFixed(2)}{' '}
                ={' '}
                {props &&
                    props.totalInfo &&
                    (
                        props.totalInfo.totalDiscountedPrice +
                        props.totalInfo.Kargo
                    ).toFixed(2)}{' '}
                TL
            </p>
            <p>
                <strong>Teslimat Adresi :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address1}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address2}{' '}
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.district}
                /
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.stateProvince}
            </p>
            <p>
                <strong>Fatura Adresi :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address1}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address2}{' '}
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.district}
                /
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.stateProvince}
            </p>
            <p>
                <strong>Sipariş Tarihi :</strong>
                {new Date().toLocaleDateString('tr')}
            </p>
            <p>
                <strong>5. GENEL HÜKÜMLER</strong>
            </p>
            <p>
                <strong>5.1.</strong>
                ALICI, SATICI’ya ait internet sitesinde sözleşme konusu ürünün
                temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata
                ilişkin ön bilgileri okuyup, bilgi sahibi olduğunu, elektronik
                ortamda gerekli teyidi verdiğini kabul, beyan ve taahhüt eder.
                ALICI’NIN; Ön Bilgilendirmeyi elektronik ortamda teyit etmesi,
                mesafeli satış sözleşmesinin kurulmasından evvel, SATICI
                tarafından ALICI'ya verilmesi gereken adresi, siparişi verilen
                ürünlere ait temel özellikleri, ürünlerin vergiler dâhil
                fiyatını, ödeme ve teslimat bilgilerini de doğru ve eksiksiz
                olarak edindiğini kabul, beyan ve taahhüt eder.
            </p>
            <p>
                <strong>5.2.</strong>
                Sözleşme konusu her bir ürün, yazılı olarak veya bir sürekli
                veri taşıyıcıyla bildirmek koşuluyla en geç 30 günlük yasal
                süreyi aşmamak kaydı ile ALICI'nın yerleşim yeri uzaklığına
                bağlı olarak internet sitesindeki ön bilgiler kısmında
                belirtilen süre zarfında ALICI veya ALICI’nın gösterdiği
                adresteki kişi ve/veya kuruluşa teslim edilir. Bu süre içinde
                ürünün ALICI’ya teslim edilememesi durumunda, ALICI’nın
                sözleşmeyi feshetme hakkı saklıdır.
            </p>
            <p>
                <strong>5.3.</strong>
                SATICI, sözleşme konusu ürünü eksiksiz, siparişte belirtilen
                niteliklere uygun ve varsa garanti belgeleri, kullanım
                kılavuzları ile teslim etmeyi, her türlü ayıptan arî olarak
                yasal mevzuat gereklerine sağlam, standartlara uygun bir şekilde
                işin gereği olan bilgi ve belgeler ile işi doğruluk ve dürüstlük
                esasları dâhilinde ifa etmeyi, hizmet kalitesini koruyup
                yükseltmeyi, işin ifası sırasında gerekli dikkat ve özeni
                göstermeyi, ihtiyat ve öngörü ile hareket etmeyi kabul, beyan ve
                taahhüt eder.
            </p>
            <p>
                <strong>5.4.</strong>
                SATICI, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan
                ALICI’yı bilgilendirmek ve açıkça onayını almak suretiyle eşit
                kalite ve fiyatta farklı bir ürün tedarik edebilir.
            </p>
            <p>
                <strong>5.5.</strong>
                ALICI, sözleşme konusu ürünün teslimatı için işbu Ön
                Bilgilendirme Formunu elektronik ortamda teyit edeceğini,
                herhangi bir nedenle sözleşme konusu ürün bedelinin ödenmemesi
                ve/veya banka kayıtlarında iptal edilmesi halinde, SATICI’nın
                sözleşme konusu ürünü teslim yükümlülüğünün sona ereceğini
                kabul, beyan ve taahhüt eder.
            </p>
            <p>
                <strong>5.6.</strong>
                ALICI, Sözleşme konusu ürünün ALICI veya ALICI’nın gösterdiği
                adresteki kişi ve/veya kuruluşa tesliminden sonra ALICI'ya ait
                kredi kartının yetkisiz kişilerce haksız kullanılması sonucunda
                sözleşme konusu ürün bedelinin ilgili banka veya finans kuruluşu
                tarafından SATICI'ya ödenmemesi halinde, ALICI Sözleşme konusu
                ürünü 3 gün içerisinde nakliye gideri SATICI’ya ait olacak
                şekilde SATICI’ya iade edeceğini kabul, beyan ve taahhüt eder.
            </p>
            <p>
                <strong>5.7.</strong>
                SATICI, tarafların iradesi dışında gelişen, önceden
                öngörülemeyen ve tarafların borçlarını yerine getirmesini
                engelleyici ve/veya geciktirici hallerin oluşması gibi mücbir
                sebepler halleri nedeni ile sözleşme konusu ürünü süresi içinde
                teslim edemez ise, durumu ALICI' ya bildireceğini kabul, beyan
                ve taahhüt eder. ALICI da siparişin iptal edilmesini, sözleşme
                konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat
                süresinin engelleyici durumun ortadan kalkmasına kadar
                ertelenmesini SATICI’ dan talep etme hakkına haizdir. ALICI
                tarafından siparişin iptal edilmesi halinde ALICI’nın nakit ile
                yaptığı ödemelerde, ürün tutarı 14 gün içinde kendisine nakden
                ve defaten ödenir. ALICI’nın kredi kartı ile yaptığı ödemelerde
                ise, ürün tutarı, siparişin ALICI tarafından iptal edilmesinden
                sonra 14 gün içerisinde ilgili bankaya iade edilir. ALICI,
                SATICI tarafından kredi kartına iade edilen tutarın banka
                tarafından ALICI hesabına yansıtılmasına ilişkin ortalama
                sürecin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya
                iadesinden sonra ALICI’nın hesaplarına yansıması halinin tamamen
                banka işlem süreci ile ilgili olduğundan, ALICI, olası
                gecikmeler için SATICI’yı sorumlu tutamayacağını kabul, beyan ve
                taahhüt eder.
            </p>
            <p>
                <strong>6. FATURA BİLGİLERİ</strong>
            </p>
            <p>
                <strong>Ad/Soyad/Unvan :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.firstName}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.lastName}
            </p>
            <p>
                <strong>Adres :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address1}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.address2}{' '}
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.district}
                /
                {props &&
                    props.shippingAddressDetail &&
                    props.shippingAddressDetail.stateProvince}
            </p>
            <p>
                <strong>Telefon :</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.phoneNumber}
            </p>
            <p>
                <strong>Eposta/kullanıcı adı :</strong>
                {props &&
                    props.unregisterEmail &&
                    props.unregisterEmail.unregisterMail}
            </p>
            <p>
                <strong>Fatura teslim :</strong>
                Fatura sipariş teslimatı sırasında fatura adresine sipariş ile
                birlikte
            </p>
            <p>teslim edilecektir.</p>
            <p>
                <strong>7. CAYMA HAKKI</strong>
            </p>
            <p>
                <strong>7.1.</strong>
                ALICI’nın cayma hakkından faydalanabilmesi için; yapılan satışın
                6502 sayılı Tüketicinin Korunması Hakkındaki Kanun Kapsamına
                giren bir satış sözleşmesine dayanması; iadenin, satın alınan
                ürünü kutusu, ambalajı, varsa standart aksesuarları ile birlikte
                eksiksiz ve hasarsız olarak teslim tarihinden itibaren herhangi
                bir gerekçe göstermeden ve cezai şart ödemeden ürünün
                tesliminden itibaren 14 (ondört) günlük süre içinde
                gerçekleştirilmesi gereklidir. İade gönderilirken ALICI’nın
                kullanması gereken anlaşmalı kargo firmaları ve anlaşma kodları
                aşağıda belirtilmiştir.
            </p>
            <p>
                Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre
                sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakkı
                süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına
                başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz.
            </p>
            <p>
                <strong>7.2.</strong>
                Cayma hakkının kullanılması için 14 (ondört) günlük süre içinde
                SATICI'ya yazılı bildirimde bulunulması veya Cayma Formu’nu
                doldurması ve ürünün işbu sözleşmede düzenlenen "Cayma Hakkı
                Kullanılamayacak Ürünler" hükümleri çerçevesinde kullanılmamış
                olması şarttır. Bu hakkın kullanılması halinde,
            </p>
            <p>
                <strong>7.2.1.</strong>
                3. kişiye veya ALICI’ ya teslim edilen ürünün faturası, (İade
                edilmek istenen ürünün faturası kurumsal ise, geri iade ederken
                kurumun düzenlemiş olduğu iade faturası ile birlikte
                gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen
                sipariş iadeleri İADE FATURASI kesilmediği takdirde
                tamamlanamayacaktır.)
            </p>
            <p>
                <strong>7.2.2.</strong>
                İade formu,
            </p>
            <p>
                <strong>7.2.3.</strong>
                İade edilecek ürünlerin kutusu, ambalajı, varsa standart
                aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim
                edilmesi gerekmektedir.
            </p>
            <p>
                <strong>7.3.</strong>
                SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en
                geç 14g ünlük süre içerisinde toplam bedeli ve ALICI’yı borç
                altına sokan belgeleri ALICI’ya iade etmekle yükümlüdür. ALICI,
                cayma hakkını kullandığına ilişkin bildirimi yönelttiği tarihten
                itibaren 10 gün içinde ürünü SATICI’ya geri göndermekle
                yükümlüdür.
            </p>
            <p>
                <strong>7.4.</strong>
                ALICI’nın kusurundan kaynaklanan bir nedenle malın değerinde bir
                azalma olursa veya iade imkânsızlaşırsa ALICI kusuru oranında
                SATICI nın zararlarını tazmin etmekle yükümlüdür. Ancak cayma
                hakkı süresi içinde malın veya ürünün usulüne uygun kullanılması
                sebebiyle meydana gelen değişiklik ve bozulmalardan ALICI
                sorumlu değildir.
            </p>
            <p>
                <strong>7.5.</strong>
                Cayma hakkının kullanılması nedeniyle SATICI tarafından
                düzenlenen kampanya limit tutarının altına düşülmesi halinde
                kampanya kapsamında faydalanılan indirim miktarı iptal edilir.
            </p>
            <p>
                <strong>MNG Kargo anlaşma kodu: 60194003</strong>
            </p>
            <p>
                <strong>Yurtiçi Kargo anlaşma kodu: 11000316</strong>
                <strong></strong>
            </p>
            <p>
                <strong>8. CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</strong>
            </p>
            <p>
                Mesafeli Sözleşmeler Yönetmeliği’nin 15. Maddesinde belirtilen
                istisnaların olması durumunda ALICI cayma hakkını kullanamaz.
            </p>
            <p>
                a- Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak
                değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya
                hizmetlere ilişkin sözleşmeler.
            </p>
            <p>
                b- Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda
                hazırlanan mallara ilişkin sözleşmeler.
            </p>
            <p>
                c- Çabuk bozulabilen veya son kullanma tarihi geçebilecek
                malların teslimine ilişkin sözleşmeler.
            </p>
            <p>
                ç- Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                unsurları açılmış olan mallardan; iadesi sağlık ve hijyen
                açısından uygun olmayanların teslimine ilişkin sözleşmeler.
            </p>
            <p>
                d- Tesliminden sonra başka ürünlerle karışan ve doğası gereği
                ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.
            </p>
            <p>
                e- Malın tesliminden sonra ambalaj, bant, mühür, paket gibi
                koruyucu unsurları açılmış olması halinde maddi ortamda sunulan
                kitap, dijital içerik ve bilgisayar sarf malzemelerine, veri
                kaydedebilme ve veri depolama cihazlarına ilişkin sözleşmeler.
            </p>
            <p>
                f- Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve
                dergi gibi süreli yayınların teslimine ilişkin sözleşmeler.
            </p>
            <p>
                g- Belirli bir tarihte veya dönemde yapılması gereken,
                konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki
                ve eğlence veya dinlenme amacıyla yapılan boş zamanın
                değerlendirilmesine ilişkin sözleşmeler.
            </p>
            <p>
                ğ- Elektronik ortamda anında ifa edilen hizmetler veya
                tüketiciye anında teslim edilen gayrimaddi mallara ilişkin
                sözleşmeler.
            </p>
            <p>
                h- Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile
                ifasına başlanan hizmetlere ilişkin sözleşmeler.
            </p>
            <p>
                Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                unsurları açılmış olan ürünlerden, kullanım esnasında ……..
                tarafından vücut ile birebir temas gerektiren iadesi sağlık ve
                hijyen açısından uygun olmayan ürünler (kulaklıklar vs.), tek
                kullanımlık ürünler ve buna benzer ürünler arızalı/ ayıplı
                olması halleri dışında niteliği itibariyle cayma hakkı
                kullanılamayacak ürünler arasında bulunmaktadır.
            </p>
            <p>
                <strong>8. UYUŞMAZLIKLARIN ÇÖZÜMÜ</strong>
            </p>
            <p>
                Tarafların İcra ve İflas Kanunundaki hakları saklı kalmak
                kaydıyla; değeri otuz bin Türk Lirasının altında bulunan
                uyuşmazlıklarda tüketici hakem heyetlerine başvuru zorunludur.
                Bu değerlerin üzerindeki uyuşmazlıklar için tüketici hakem
                heyetlerine başvuru yapılamaz. Başvurular, ALICI’nın yerleşim
                yerinin bulunduğu veya ALICI işleminin yapıldığı yerdeki
                tüketici hakem heyetine yapılabilir. Tüketici hakem heyetinin
                bulunmadığı yerlerde ise başvurular o ilçe kaymakamlığına
                yapılabilir. Yapılan bu başvurular, kaymakamlıklarca gereği
                yapılmak üzere Bakanlıkça belirlenen yetkili tüketici hakem
                heyetine iletilir.
            </p>
            <p>
                (Bu maddede belirtilen parasal sınırlar her takvim yılı başından
                itibaren geçerli olmak üzere, o yıl için 4/1/1961 tarihli ve 213
                sayılı Vergi Usul Kanununun mükerrer 298 inci maddesi hükümleri
                uyarınca tespit ve ilan edilen yeniden değerleme oranında
                artırılarak uygulanır. Bu artışların hesabında bin Türk
                Lirasının küsuru dikkate alınmaz.
            </p>
            <p>
                Yukarıdaki bilgiler uzaktan iletişim araçlarına uygun olarak ve
                iyi niyet ilkeleri çerçevesinde ergin olmayanlar ile ayırtım
                gücünden yoksun veya kısıtlı erginleri koruyacak şekilde ticari
                amaçlarla verilmiştir <strong>.</strong>
            </p>
            <p>
                <strong>SATICI:</strong>
                TESAN İLETİŞİM A.Ş.
            </p>
            <p>
                <strong>ALICI:</strong>
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.firstName}{' '}
                {props &&
                    props.shippingAddress &&
                    props.shippingAddress.lastName}
            </p>
            <p>
                <strong>TARİH:</strong>
                {new Date().toLocaleDateString('tr')}
            </p>
        </>
    );
}
