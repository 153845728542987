import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { searchProduct } from '../../redux/actions/product';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_BASE, tesanCDNURL } from '../../config/env';
import { FillBasketItemsFromCookie } from '../../redux/actions/BASKET/BasketCookieActions';
import Checkoutaddress from '../basket/AddressCheck';
import Checkoutpayment from '../basket/Checkoutpayment';
import { Offcanvas } from 'react-bootstrap';

const NavMenu = (props) => {
    const cookies = new Cookies();
    const [userInfo, setUserInfo] = useState({});
    const [searchData, setSearchData] = useState({
        searchWord: '',
        isSearch: false,
        products: []
    });
    const [navigations, setNavigations] = useState({
        navigations: [],
        isLoading: true
    });
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .get(`${API_BASE}Navigation/GetHomeCategories`, config)
            .then((data) => {
                //console.log(data.data.set);
                setNavigations({
                    navigations: data.data.set
                });
            });
        const userToken = localStorage.getItem('token');
        if (userToken != '') {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            axios
                .get(
                    `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                    config
                )
                .then((data) => {
                    setUserInfo(data.data.set);
                });
        }
    }, []);
    const clickLogout = () => {
        localStorage.setItem('token', '');
        window.location.href = '/Login';
    };
    return (
        <div className="header-navigation position-relative">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div
                    className="offcanvas offcanvas-start auto-canvas"
                    tabIndex="-1"
                    id="mainMenu"
                    aria-labelledby="mainMenuLabel"
                    data-bs-scroll="false"
                    data-bs-backdrop="true"
                >
                    <div className="offcanvas-header p-5">
                        <div className="offcanvas-title" id="mainMenuLabel">
                            <div className="userHead d-flex flex-column">
                                {userInfo && userInfo.customerId ? (
                                    <div>
                                        <h4 className="fw-bold text-white">
                                            {userInfo.firstName +
                                                ' ' +
                                                userInfo.lastname[0] +
                                                '.'}
                                        </h4>
                                        <span className="me-2">
                                            <a
                                                href="/Profile"
                                                className="text-white-50"
                                            >
                                                <i className="bi bi-gear me-1"></i>
                                                K.Paneli
                                            </a>
                                        </span>{' '}
                                        <span>
                                            <button
                                                href="#"
                                                onClick={() => clickLogout()}
                                                className="text-white-50"
                                            >
                                                <i className="bi bi-box-arrow-left me-1"></i>
                                                Çıkış Yap
                                            </button>
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        <h4 className="fw-bold text-white">
                                            Hoşgeldiniz
                                        </h4>
                                        <span className="me-2">
                                            <a
                                                href="/Login"
                                                className="text-white-50"
                                            >
                                                <i className="bi bi-box-arrow-right me-1"></i>
                                                Giriş Yap
                                            </a>
                                        </span>{' '}
                                        <span>
                                            <a
                                                href="/CreateCustomer"
                                                className="text-white-50"
                                            >
                                                <i className="bi bi-person-plus me-1"></i>
                                                Kayıt Ol
                                            </a>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav">
                            {navigations.navigations.map((navi) =>
                                navi.category.showSubCategories == true ? (
                                    <li
                                        key={navi.category.id}
                                        className="nav-item dropdown px-4 px-sm-0 border-0"
                                    >
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id={`navbarDropdown-${navi.category.id}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                className="me-3 icons d-inline-block d-sm-none"
                                                src={`${tesanCDNURL}${
                                                    navi.category.icon &&
                                                    navi.category.icon
                                                        .virtualPath
                                                }`}
                                                alt={navi.category.name}
                                            />
                                            {navi.category.name}
                                        </a>
                                        <ul
                                            key={`subCat-${navi.category.id}`}
                                            className="dropdown-menu"
                                            aria-labelledby={`navbarDropdown-${navi.category.id}`}
                                        >
                                            {navi.category.showSubCategories ==
                                                true &&
                                                navi.subCategories.map(
                                                    (subCat) =>
                                                        subCat.isActive ==
                                                        true ? (
                                                            <li key={subCat.id}>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    to={`/category/${subCat.id}`}
                                                                >
                                                                    {
                                                                        subCat.name
                                                                    }
                                                                </Link>
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )
                                                )}
                                        </ul>
                                    </li>
                                ) : (
                                    <li
                                        key={navi.category.id}
                                        className="nav-item px-4 px-sm-0 border-0"
                                    >
                                        <Link
                                            className="nav-link"
                                            aria-current="page"
                                            to={`/category/${navi.category.id}`}
                                        >
                                            <img
                                                className="me-3 icons d-inline-block d-sm-none"
                                                src={`${tesanCDNURL}${
                                                    navi.category.icon &&
                                                    navi.category.icon
                                                        .virtualPath
                                                }`}
                                                alt={navi.category.name}
                                            />
                                            {navi.category.name}
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default NavMenu;
