import React, { useState, useEffect } from 'react';
import Product from './Product';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { API_BASE } from '../../config/env';

function ProductDetail(props) {
    let params = useParams();
    const showToastHandle = (productName) => {
        props.openToast({ productName: productName, isShow: true });
        //setProductState({productName:productName});
    };
    const [product, setProduct] = useState({});
    const [productDetail, setProductDetail] = useState([]);
    useEffect(() => {
        const productId = params.id;
        axios
            .get(`${API_BASE}Product/GetProductById?id=${productId}`)
            .then((data) => setProduct({ ...data.data.set }));
        axios
            .get(
                `${API_BASE}Product/GetProductSpecificationsByProductId?productId=${productId}`
            )
            .then((data) => setProductDetail(data.data.set));
    }, [params.id]);

    return (
        <Product
            showToast={(productName) => showToastHandle(productName)}
            product={product}
            productDetail={productDetail}
        />
    );
}
export default ProductDetail;
