import { height, width } from 'dom-helpers';
import React from 'react';

export default function RegisterCompleted() {
    return (
        <div className="d-flex registerCompleted py-5 flex-fill align-items-center">
            <div className="container">
                <div className="d-flex justify-content-center flex-column text-center">
                    <div
                        className="d-flex justify-content-center align-items-center bg-success rounded-circle mx-auto mb-4 shadow"
                        style={{ width: '7rem', height: '7rem' }}
                    >
                        <i class="bi bi-person-check display-3 text-white"></i>
                    </div>
                    <h3 className="fw-bold mb-3 text-success">
                        Kayıt İşleminiz
                        <br />
                        Başarıyla Tamamlandı
                    </h3>
                    <p className="text-danger">
                        Size üyeliğinizi aktifleştirmeniz için bir e-posta
                        gönderdik. Gönderdiğimiz e-postada
                        <span className="fw-bold">
                            "Aktifleştirmek için tıklayınız"
                        </span>
                        linkine tıklayarak, aktifleştirme işleminizi
                        gerçekleştirebilirsiniz.
                    </p>
                    <div className="d-grid col-3 mx-auto mt-5">
                        <a
                            href="/"
                            type="button"
                            className="btn btn-lg btn-primary"
                        >
                            Ana Sayfa
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
