import { FETCH_CATEGORY_FULFILLED, FETCH_CATEGORY_PENDING } from "../actions/category";

const initialState = {
    fetching: false,
    fetched: false,
    categories: [],
    error: {}
};
export function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_CATEGORY_PENDING:
            return {
                ...state,
                categories: [],
                fetched: false,
                fetching: true
            }
        case FETCH_CATEGORY_FULFILLED:
            return {
                ...state,
                categories: action.payload,
                fetched: true,
                fetching: false
            }
        default:
            return state;
    }
}
