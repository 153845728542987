import { ModalBody } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

const OrderDetails = () => {
    return (
        <ModalBody>
            <div className="orderDetails d-flex flex-column gap-2">
                <div className="orderProductList d-flex flex-column gap-2">
                    <div className="orderProductItem border rounded-2 p-4 d-flex position-relative justify-content-start align-items-start gap-3">
                        <div
                            className="orderProductImg bg-white rounded-2"
                            style={{ maxWidth: 'clamp(5rem, 1vw, 10rem)' }}
                        >
                            <img
                                src="https://erp.tesan.net/documents/product/54C6E7C3-9C7E-15BB-87510C58788BCEC5_b.png"
                                className="img-fluid"
                                alt="ttec PowerLite S 20.000 mAh Taşınabilir Şarj
                                Aleti / Powerbank"
                            />
                        </div>
                        <div className="orderProductDetail d-flex flex-column gap-1">
                            <h6 className="orderProductName fw-bold">
                                ttec PowerLite S 20.000 mAh Taşınabilir Şarj
                                Aleti / Powerbank
                            </h6>
                            <div className="orderProductVariant text-black-50">
                                <span>Renk: Siyah</span>
                            </div>
                            <div className="orderProductPrice text-primary fw-bold">
                                <span>1 x 449,90 TL</span>
                            </div>
                        </div>
                        <div
                            className="orderOptions position-absolute"
                            style={{ bottom: '1.35rem', right: '1.35rem' }}
                        >
                            <Button
                                variant="link"
                                size="sm"
                                style={{
                                    backgroundColor: '#fbe9e7',
                                    color: '#ff5722',
                                    textDecoration: 'none'
                                }}
                            >
                                İptal / İade
                            </Button>
                        </div>
                    </div>
                    <div className="orderProductItem border rounded-2 p-4 d-flex position-relative justify-content-start align-items-start gap-3">
                        <div
                            className="orderProductImg bg-white rounded-2"
                            style={{ maxWidth: 'clamp(5rem, 1vw, 10rem)' }}
                        >
                            <img
                                src="https://erp.tesan.net/documents/product/59FDA117-B58D-EDCB-75D71FDFE3E84B7C_b.png"
                                className="img-fluid"
                                alt="ttec Dynamite Quattro 40W HiFi Kablosuz
                                Bluetooth Hoparlör"
                            />
                        </div>
                        <div className="orderProductDetail d-flex flex-column gap-1">
                            <h6 className="orderProductName fw-bold">
                                ttec Dynamite Quattro 40W HiFi Kablosuz
                                Bluetooth Hoparlör
                            </h6>
                            <div className="orderProductVariant text-black-50">
                                <span>Renk: Siyah</span>
                            </div>
                            <div className="orderProductPrice text-primary fw-bold">
                                <span>1 x 1299,90 TL</span>
                            </div>
                        </div>
                        <div
                            className="orderOptions position-absolute"
                            style={{ bottom: '1.35rem', right: '1.35rem' }}
                        >
                            <Button
                                variant="link"
                                size="sm"
                                style={{
                                    backgroundColor: '#fbe9e7',
                                    color: '#ff5722',
                                    textDecoration: 'none'
                                }}
                            >
                                İptal / İade
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="border border-light overflow-hidden rounded-2">
                    {/* Kargo Teslimat Detayları */}
                    <div className="orderShippingDetails p-4 mb-4">
                        <h5 className="fw-bold mb-4">Teslimat Detayları</h5>
                        <div className="addressGroup row row-cols-2 g-3">
                            <div className="addressDetail mb-4">
                                <h6 className="fw-bold mb-3 text-primary">
                                    Teslimat Adresi
                                </h6>
                                <h6 className="fw-bold mb-2">İş Yeri</h6>
                                <p className="mb-2 text-black-50">
                                    Tesan İletişim A.Ş Yönetim Binası Sanayi
                                    Cad. Bilge 1 Sk. No: 17 Çobançeşme /
                                    Bahçelievler / İstanbul
                                </p>
                                <h6 className="fw-bold text-black-50">
                                    Ufuk Cergenç - 053******06
                                </h6>
                            </div>
                            <div className="addressDetail mb-4">
                                <h6 className="fw-bold mb-3 text-primary">
                                    Fatura Adresi
                                </h6>
                                <h6 className="fw-bold mb-2">Ev</h6>
                                <p className="mb-2 text-black-50">
                                    Halkalı Cad. Avrupa Konutları Atakent 4 206
                                    / 10 2B Blok Kat 10 Daire 42 İnönü /
                                    Küçükçekmece / İstanbul
                                </p>
                                <h6 className="fw-bold text-black-50">
                                    Ufuk Cergenç - 053******06
                                </h6>
                            </div>
                        </div>
                        <div className="shippingTracking">
                            <Button
                                variant="outline-success"
                                size="sm"
                                className="shadow-sm"
                            >
                                <i className="bi bi-truck"></i> Kargo Takibi
                            </Button>
                        </div>
                    </div>
                    {/* Ödeme Detayları */}
                    <div className="orderPaymentDetails bg-light p-4">
                        <div className="paymentDetail row row-cols-2 justify-content-between">
                            <div className="paymentType">
                                <div className="paymentCard d-flex flex-column small">
                                    <span className="fw-bold">
                                        Ödeme Yöntemi
                                    </span>
                                    <span className="text-primary">
                                        <i className="bi bi-credit-card-2-front me-2"></i>{' '}
                                        Kredi Kartı
                                    </span>
                                    <span className="fw-bold">
                                        44** 11** **** 3917
                                    </span>
                                    <span>3 Taksit</span>
                                </div>
                            </div>
                            <div className="paymentPrice d-flex gap-3 align-items-start">
                                <div className="paymentTotal row row-cols-2 small text-nowrap">
                                    <div className="t fw-bold d-flex flex-column">
                                        <span>Kargo Bedeli</span>
                                        <span>Taksit</span>
                                        <span>Toplam</span>
                                    </div>
                                    <div className="d d-flex flex-column">
                                        <span>Ücretsiz</span>
                                        <span>3 x 582,96 TL</span>
                                        <span>1748,90 TL</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    );
};

export default OrderDetails;
