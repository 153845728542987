import { Modal, ModalTitle } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import { API_BASE } from '../../config/env';
import Axios from 'axios';
import { Button } from 'react-bootstrap';
import { ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import PersonalInfoNavigation from './PersonalInfoNavigation';

const MyOrders = (props) => {
    const [userInfo, setUserInfo] = useState({});
    const [orderInfo, setOrderInfo] = useState([]);
    const [orderDetailInfo, setOrderDetailInfo] = useState([]);
    // const orderShow = useState(true);
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            Axios.get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            ).then((data) => {
                setUserInfo(data.data.set);
                if (data.data) {
                    if (data.data.code === '201') {
                        window.location.href = '/Login';
                    } else if (data.data.code === '200') {
                    }
                }
                Axios.get(
                    `${API_BASE}Order/GetOrdersByCustomerToken?customerToken=${userToken}`,
                    config
                ).then((data) => {
                    setOrderInfo(data.data.set);
                });
            });
        } else {
            window.location.href = '/Login';
        }
    }, [props]);

    function orderDateFormat(orderDate) {
        var date = new Date(orderDate);
        date =
            date.toLocaleDateString(undefined, { day: '2-digit' }) +
            ' ' +
            date.toLocaleDateString(undefined, { month: 'long' }) +
            ' ' +
            date.toLocaleDateString(undefined, { year: 'numeric' });
        return date;
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    function GetOrderDetail(orderID) {
        setShow(true);
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.get(
            `${API_BASE}Order/GetOrderDetail?orderId=${orderID}`,
            config
        ).then((data) => {
            setOrderDetailInfo(data.data.set);
        });
    }

    return (
        <section className="orderlist user-section py-sm-5 p-4 flex-fill">
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <ModalHeader closeButton>
                    <ModalTitle>Sipariş Ayrıntıları</ModalTitle>
                </ModalHeader>
                {orderDetailInfo ? (
                    <ModalBody>
                        <div className="orderDetails d-flex flex-column gap-2">
                            <div className="orderProductList d-flex flex-column gap-2">
                                {orderDetailInfo &&
                                    orderDetailInfo.orderRowDetail &&
                                    orderDetailInfo.orderRowDetail.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="orderProductItem border rounded-2 p-4 d-flex position-relative justify-content-start align-items-start gap-3"
                                                >
                                                    <div
                                                        className="orderProductImg bg-white rounded-2"
                                                        style={{
                                                            maxWidth:
                                                                'clamp(5rem, 1vw, 10rem)'
                                                        }}
                                                    >
                                                        <img
                                                            src={item.picture}
                                                            className="img-fluid"
                                                            alt="taks"
                                                        />
                                                    </div>
                                                    <div className="orderProductDetail d-flex flex-column gap-1">
                                                        <h6 className="orderProductName fw-bold">
                                                            {item.name}
                                                        </h6>
                                                        <div className="orderProductVariant text-black-50">
                                                            <span>
                                                                Renk:{' '}
                                                                {item.renk}
                                                            </span>
                                                        </div>
                                                        <div className="orderProductPrice text-primary fw-bold">
                                                            <span>
                                                                {item.quantity}{' '}
                                                                x{' '}
                                                                {item.price.toFixed(
                                                                    2
                                                                )}{' '}
                                                                TL
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div
                                            className="orderOptions position-absolute"
                                            style={{ bottom: '1.35rem', right: '1.35rem' }}
                                        >
                                            <Button
                                                variant="link"
                                                size="sm"
                                                style={{
                                                    backgroundColor: '#fbe9e7',
                                                    color: '#ff5722',
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                İptal / İade
                                            </Button>
                                        </div> */}
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                            <div className="border border-light overflow-hidden rounded-2">
                                {/* Kargo Teslimat Detayları */}
                                <div className="orderShippingDetails p-4 mb-4">
                                    <h5 className="fw-bold mb-4">
                                        Teslimat Detayları
                                    </h5>
                                    <div className="addressGroup row row-cols-2 g-3">
                                        <div className="addressDetail mb-4">
                                            <h6 className="fw-bold mb-3 text-primary">
                                                Teslimat Adresi
                                            </h6>
                                            <p className="mb-2 text-black-50">
                                                {orderDetailInfo &&
                                                    orderDetailInfo.shippedAddress}
                                            </p>
                                        </div>
                                        <div className="addressDetail mb-4">
                                            <h6 className="fw-bold mb-3 text-primary">
                                                Fatura Adresi
                                            </h6>
                                            <p className="mb-2 text-black-50">
                                                {orderDetailInfo &&
                                                    orderDetailInfo.billingAddress}
                                            </p>
                                        </div>
                                    </div>
                                    {orderDetailInfo &&
                                    orderDetailInfo.cargoUrl &&
                                    orderDetailInfo.cargoUrl !== '' ? (
                                        <div className="shippingTracking">
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                className="shadow-sm"
                                                target="_blank"
                                                href={orderDetailInfo.cargoUrl}
                                            >
                                                <i className="bi bi-truck"></i>{' '}
                                                Kargo Takibi
                                            </Button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Ödeme Detayları */}
                                <div className="orderPaymentDetails bg-light p-4">
                                    <div className="paymentDetail row row-cols-2 justify-content-between">
                                        <div className="paymentType">
                                            <div className="paymentCard d-flex flex-column small">
                                                <span className="fw-bold">
                                                    Ödeme Yöntemi
                                                </span>
                                                <span className="text-primary">
                                                    <i className="bi bi-credit-card-2-front me-2"></i>{' '}
                                                    {orderDetailInfo &&
                                                        orderDetailInfo.payMethod}
                                                </span>
                                                <span className="fw-bold">
                                                    {orderDetailInfo &&
                                                        orderDetailInfo.cardNumber}
                                                </span>
                                                <span>
                                                    {orderDetailInfo &&
                                                    orderDetailInfo.installment ==
                                                        0
                                                        ? 'Tek Çekim'
                                                        : orderDetailInfo &&
                                                          orderDetailInfo.installment +
                                                              ' Taksit'}{' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="paymentPrice d-flex gap-3 align-items-start">
                                            <div className="paymentTotal row row-cols-2 small text-nowrap">
                                                <div className="t fw-bold d-flex flex-column">
                                                    <span>Kargo Bedeli</span>
                                                    <span>Taksit</span>
                                                    <span>Toplam</span>
                                                </div>
                                                <div className="d d-flex flex-column">
                                                    <span>
                                                        {orderDetailInfo &&
                                                        orderDetailInfo.cargoPrice >
                                                            0
                                                            ? orderDetailInfo.cargoPrice.toFixed(
                                                                  2
                                                              )
                                                            : 'Ücretsiz'}
                                                    </span>
                                                    <span>
                                                        {orderDetailInfo &&
                                                        orderDetailInfo.installment ==
                                                            0
                                                            ? 1
                                                            : orderDetailInfo &&
                                                              orderDetailInfo.installment}{' '}
                                                        x{' '}
                                                        {orderDetailInfo &&
                                                            (
                                                                orderDetailInfo.totalPrice /
                                                                (orderDetailInfo.installment ==
                                                                0
                                                                    ? 1
                                                                    : orderDetailInfo.installment)
                                                            ).toFixed(2)}{' '}
                                                        TL
                                                    </span>
                                                    <span>
                                                        {orderDetailInfo &&
                                                            orderDetailInfo.totalPrice &&
                                                            orderDetailInfo.totalPrice.toFixed(
                                                                2
                                                            )}{' '}
                                                        TL
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                ) : (
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-danger">
                                    <strong>Hata!</strong> Bu siparişin
                                    detayları yüklenemedi. Sipariş eksik ya da
                                    hatalı olabilir.
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                )}
            </Modal>
            <div className="container">
                <div className="row">
                    <PersonalInfoNavigation user={userInfo} />
                    <div className="col-sm-9">
                        <div className="bg-white rounded-2 p-3 p-sm-5">
                            <div className="last-order-list d-flex flex-column gap-1">
                                <div className="d-flex align-items-center text-black-50 gap-2 mb-4">
                                    <i className="bi bi-receipt h4"></i>
                                    <span className="fw-bold">
                                        Son Siparişleriniz
                                    </span>
                                </div>
                                {/* Alışveriş Listesi Boş Olduğunda Gösterilecek */}
                                {orderInfo.length < 1 ? (
                                    <div className="empty-order-list p-4 text-black-50 text-center d-flex flex-column align-items-center gap-1 justify-content-center">
                                        <i className="bi bi-basket h3"></i>
                                        <h5 className="fw-bolder">
                                            Henüz Siparişiniz Yok
                                        </h5>
                                        <p>
                                            Sipariş oluşturulduğunda burada
                                            görüntülenecektir.
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {/* Alışveriş Listesi Boş Olduğunda Gösterilecek */}
                                {orderInfo.map((order) => (
                                    <div
                                        key={order.id}
                                        className="order-list-item d-flex flex-wrap position-relative border border-light rounded-2 p-3 align-items-center gap-2"
                                    >
                                        <div className="col-2 col-sm-1">
                                            <h6 className="fw-bold text-center text-wrap text-primary">
                                                {orderDateFormat(
                                                    order.orderDate
                                                )}
                                            </h6>
                                        </div>
                                        <div className="col-3 col-sm-2 d-none d-sm-block">
                                            <small className="d-block text-black-50">
                                                Sipariş No
                                            </small>
                                            <small className="fw-bold">
                                                {order.id}
                                            </small>
                                        </div>
                                        <div className="col-3 col-sm-2">
                                            <small className="d-block text-black-50">
                                                Durumu
                                            </small>
                                            {order.orderStatus &&
                                            order.orderStatus ===
                                                'Gönderildi' ? (
                                                <span className="small px-2 py-1 rounded-1 box-success">
                                                    Gönderildi
                                                </span>
                                            ) : order.orderStatus ===
                                              'Ödeme Bekliyor' ? (
                                                <span className="small px-2 py-1 rounded-1 box-danger">
                                                    Başarısız
                                                </span>
                                            ) : order.orderStatus ===
                                              'Hazırlanıyor' ? (
                                                <span className="small px-2 py-1 rounded-1 box-warning">
                                                    Hazırlanıyor
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="col-3 col-sm-2">
                                            <small className="d-block text-black-50">
                                                Toplam Tutar
                                            </small>
                                            <small className="fw-bold">
                                                {order.totalPrice.toFixed(2)} ₺
                                            </small>
                                        </div>
                                        <div className="col-3 col-sm-2 d-none d-sm-block">
                                            <small className="d-block text-black-50">
                                                Ödeme
                                            </small>
                                            <small className="fw-bold">
                                                {order.paymentMethod}
                                            </small>
                                        </div>
                                        <div className="col-3 col-sm-2">
                                            <small className="d-block text-black-50">
                                                Kargo Takip
                                            </small>
                                            <small className="fw-bold">
                                                <a
                                                    key={order.orderCargoId}
                                                    href={order.cargoUrl}
                                                    target="blank_"
                                                >
                                                    {order.cargoName}
                                                </a>
                                            </small>
                                        </div>
                                        <div className="btn-group tools-menu">
                                            <button
                                                type="button"
                                                className="btn dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                data-bs-display="static"
                                                aria-expanded="false"
                                            >
                                                <i className="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={() =>
                                                            GetOrderDetail(
                                                                order.id
                                                            )
                                                        }
                                                    >
                                                        <i className="bi bi-file-earmark-medical me-2 text-primary"></i>
                                                        Sipariş Ayrıntıları
                                                    </button>
                                                </li>
                                                {/* <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        <i className="bi bi-bag-x me-2 text-danger"></i>
                                                        İptal ve İade İşlemleri
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                    >
                                                        <i className="bi bi-printer me-2 text-success"></i>
                                                        Sipariş Ayrıntılarını
                                                        Yazdır
                                                    </button>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <nav aria-label="Order Pagination" className="mt-5">
                                <ul className="pagination justify-content-start">
                                    <li className="page-item disabled">
                                        <button
                                            className="page-link"
                                            href="#"
                                            tabIndex="-1"
                                            aria-disabled="true"
                                        >
                                            Önceki Sayfa
                                        </button>
                                    </li>
                                    <li
                                        className="page-item active"
                                        aria-current="page"
                                    >
                                        <button className="page-link" href="#">
                                            1
                                        </button>
                                    </li>
                                    <li className="page-item">
                                        <button className="page-link" href="#">
                                            Sonraki Sayfa
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default MyOrders;
