import axios from 'axios';
import { API_BASE } from '../../config/env';
export const FETCH_CATEGORY_PENDING = "FETCH_CATEGORY_PENDING";
export const FETCH_CATEGORY_FULFILLED = "FETCH_CATEGORY_FULFILLED";

export function fetchAllCategories() {
    return dispatch => {
        dispatch({
            type: "FETCH_CATEGORY",
            payload: axios.get(`${API_BASE}Category/GetAllCategories`)
                .then(data => data.data.set)
        })

    }
}