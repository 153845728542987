import React, { useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tr from 'date-fns/locale/tr';
import axios from 'axios';
import { API_BASE } from '../../config/env';
registerLocale('tr', tr);

const ResetPassword = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    const [userState, setUserState] = useState({
        password: '',
        passwordConf: ''
    });

    function customerFormsChange(e) {
        setUserState({
            ...userState,
            [e.target.name]: e.target.value
        });
    }

    const btnCreateClicked = (e) => {
        console.log(userState.password);
        if (!userState.password && !userState.passwordConf) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        // var ozelChar = '*|,:<>[]{}`;()@&$#%!+-"./';
        // var isChar = false;
        // for (var i = 0; i < userState.password.length; i++) {
        //     if (ozelChar.indexOf(userState.password.charAt(i)) != -1) {
        //         isChar = true;
        //     }
        // }

        if (userState.password != userState.passwordConf) {
            alert('Girilen Şifreler Uyuşmuyor');
            return false;
        } else if (userState.password.length < 6) {
            alert('Şifre 6 karekter veya daha büyük olmalıdır.');
            return false;
        } else if (userState.password.search(/[a-zA-Z]/) < 0) {
            alert('Şifre en az bir Büyük harf içermelidir.');
            return false;
        } else if (userState.password.search(/[0-9]/) < 0) {
            alert('Şifre en az bir rakam içermelidir.');
            return false;
        }
        /*else if (isChar == false) {
            alert("Şifre en az bir sembol içermelidir.");
            return false;
        }*/

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        axios
            .post(
                `${API_BASE}Customer/AddPassword?token=${token}&password=${encodeURIComponent(
                    userState.password
                )}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        window.location.href = '/Login';
                    } else if (data.data.code == '202') {
                        alert('Yeni Şifre Eskisiyle Aynı Olamaz!');
                    } else {
                        alert('Hata Oluştu. Başarısız İşlem!');
                    }
                }
            });
    };

    return (
        <section className="login py-sm-5 p-4">
            <div className="container">
                <div className="d-grid col-sm-6 mx-auto">
                    <div className="header d-flex flex-column mb-3 text-center">
                        <div className="mb-1 h2">
                            <i className="bi bi-shield-lock text-primary" />
                        </div>
                        <div className="h5 fw-bolder">
                            <span>Hoşgeldiniz</span>
                        </div>
                        <div className="mb-5">
                            <span>
                                Devam etmek için şifrenizi yenileyin. <br />{' '}
                                Şifrenizi belirlerken (Rakam, büyük harf, küçük
                                Harf) bulunmasına dikkat ediniz.
                            </span>
                        </div>
                    </div>
                    <div>
                        <form id="createUser">
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    onChange={(e) => customerFormsChange(e)}
                                    step="1"
                                    placeholder="Şifre"
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordConf"
                                    name="passwordConf"
                                    onChange={(e) => customerFormsChange(e)}
                                    step="1"
                                    placeholder="Şifreyi Tekrarlayın"
                                />
                            </div>
                            <div className="d-grid my-5">
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    onClick={btnCreateClicked}
                                >
                                    Kaydet
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default ResetPassword;
