import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const OrderError = (props) => {
    const cookies = new Cookies();
    const [stateError, setStateError] = useState('');
    useEffect(() => {
        var error = cookies.get('errorMessage');
        if (!error) {
            setStateError('Bir Hata Oluştu!');
        } else {
            setStateError(error);
        }
    }, []);
    return (
        <>
            <section className="checkout-results py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-8">
                            <div className="finish d-flex col-sm-6 flex-column text-center justify-content-center mx-auto">
                                <i className="bi bi-exclamation-circle mb-4 text-danger display-1" />
                                <h5 className="fw-bolder mb-2 text-danger h3">
                                    Ödemede Sorun Oluştu
                                </h5>
                                <p className="text-black-50">{stateError}</p>
                            </div>
                            <div className="d-grid col-sm-6 mx-auto mt-4">
                                <a
                                    href="/Checkoutpayment"
                                    className="btn btn-lg btn-primary"
                                >
                                    Tekrar Dene
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default OrderError;
