import { ADD_BASKET, OPEN_MODAL } from '../actions/addedBasket';

export function addBasketReducer(state = {}, action) {
    switch (action.type) {
        case ADD_BASKET:
            return {
                ...state, basketProduct: action.payload
            }
        case OPEN_MODAL:
            return {
                ...state, openedProductId: action.payload
            }
        default:
            return state;
    }
}