import { combineReducers } from "redux";
import { productReducer } from "./productReducer";
import homepageReducer from "./homepageReducer";
import { categoryReducer } from './categoryReducer';
import { addBasketReducer } from './addBasketReducer';

export default combineReducers({
    homepageProducts: homepageReducer,
    product: productReducer,
    categories: categoryReducer,
    openedModal: addBasketReducer
});