import * as ReactDOMClient from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore
} from 'redux';

import App from './components/root/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reduxPromise from 'redux-promise-middleware';
import rootReducer from './redux/reducers/rootReducer';
import { thunk } from 'redux-thunk';

// import logger from 'redux-logger';
//, logger
const store = createStore(
    rootReducer,
    compose(applyMiddleware(reduxPromise, thunk))
);

const rootElement = ReactDOMClient.createRoot(document.getElementById('root'));
rootElement.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
