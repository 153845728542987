import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { API_BASE } from '../../config/env';
const OrderComplete = (props) => {
    //order-finished-page
    const cookies = new Cookies();
    const [stateOrder, setStateOrder] = useState({});
    const [stateTotalAndCampaign, setStateTotalAndCampaign] = useState({});
    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        //cookies.set('orderId', 3324);
        var orderId = cookies.get('orderId');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        var purchasedProd = [];
        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data.set) {
                    setUserInfo(data.data.set);
                    var email = data.data.set.email;
                    const getBasketServiceUrl = 'basket/GetCustomerBasket/';
                    const config = {
                        headers: { 'Content-Type': 'application/json' }
                    };
                    axios
                        .get(
                            `${API_BASE}${getBasketServiceUrl}${data.data.set.customerId}`,
                            config
                        )
                        .then((data) => {
                            setStateOrder({
                                basketProducts: data.data.set.basketProducts,
                                email: email,
                                orderId: orderId
                            });
                            var purchasedProd =
                                data.data.set &&
                                data.data.set.basketProducts &&
                                data.data.set.basketProducts.map((pp, i) => ({
                                    index: i,
                                    item_id: pp.productId,
                                    item_name: pp.productName,
                                    quantity: pp.quantity,
                                    price: pp.discountPrice
                                        ? pp.discountPrice.toFixed(2)
                                        : pp.price.toFixed(2)
                                }));

                            const getOrderServiceUrl =
                                'order/GetTotalAndCampaign?orderId=';
                            axios
                                .get(
                                    `${API_BASE}${getOrderServiceUrl}${orderId}`,
                                    config
                                )
                                .then((data) => {
                                    setStateTotalAndCampaign({
                                        totalPrice: data.data.set.totalPrice,
                                        code: data.data.set.code
                                    });

                                    window.dataLayer.push({
                                        event: 'purchase',
                                        transaction_id: orderId,
                                        value:
                                            data.data.set.totalPrice &&
                                            data.data.set.totalPrice.toFixed(2),
                                        currency: 'TRY',
                                        coupon:
                                            data.data.set.code &&
                                            data.data.set.code,
                                        campaignName:
                                            data.data.set.campaignName &&
                                            data.data.set.campaignName,
                                        purchased: {
                                            items: purchasedProd
                                        }
                                    });
                                })
                                .catch();
                        })
                        .catch();
                    const basketComplated = 'order/BasketComplate';
                    axios
                        .get(
                            `${API_BASE}${basketComplated}?customerId=${data.data.set.customerId}`,
                            config
                        )
                        .then((data) => {});
                } else {
                    var cookiedBasket = cookies.get('basket');
                    var urEmail = cookies.get('unregisterEmail');
                    setStateOrder({
                        basketProducts: cookiedBasket.basketProducts,
                        email: urEmail.unregisterMail,
                        orderId: orderId
                    });

                    var purchasedProd =
                        cookiedBasket &&
                        cookiedBasket.basketProducts &&
                        cookiedBasket.basketProducts.map((pp, i) => ({
                            index: i,
                            item_id: pp.productId,
                            item_name: pp.productName,
                            quantity: pp.quantity,
                            price: pp.discountPrice
                                ? pp.discountPrice.toFixed(2)
                                : pp.price.toFixed(2)
                        }));

                    const getOrderServiceUrl =
                        'order/GetTotalAndCampaign?orderId=';
                    axios
                        .get(
                            `${API_BASE}${getOrderServiceUrl}${orderId}`,
                            config
                        )
                        .then((data) => {
                            setStateTotalAndCampaign({
                                totalPrice: data.data.set.totalPrice,
                                code: data.data.set.code
                            });

                            window.dataLayer.push({
                                event: 'purchase',
                                transaction_id: orderId,
                                value:
                                    data.data.set.totalPrice &&
                                    data.data.set.totalPrice.toFixed(2),
                                currency: 'TRY',
                                coupon:
                                    data.data.set.code && data.data.set.code,
                                campaignName:
                                    data.data.set.campaignName &&
                                    data.data.set.campaignName,
                                purchased: {
                                    items: purchasedProd
                                }
                            });
                        })
                        .catch();

                    cookies.remove('basket');
                }
            });

        // if (!orderId) {
        //     window.location.href = '/';
        // }
        cookies.remove('billingaddress');
        cookies.remove('shippingaddress');
        cookies.remove('orderId');
    }, []);

    return (
        <>
            <div className="checkout-header py-4">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center">
                        <a href="/OrderComplete" className="step current">
                            <i className="bi bi-truck me-2" />
                            Teslimat
                        </a>
                        <span className="line current" />
                        <a href="/Checkoutpayment" className="step current">
                            <i className="bi bi-credit-card me-2" /> Ödeme
                        </a>
                        <span className="line current" />
                        <a href="#" className="step current">
                            <i className="bi bi-receipt me-2" />
                            Özet
                        </a>
                    </div>
                </div>
            </div>
            <section className="checkout-results py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-6">
                            <div className="d-flex flex-column border rounded-2 overflow-hidden">
                                <div className="finish d-flex justify-content-between border-bottom p-4 bg-light">
                                    <div>
                                        <h5 className="fw-bold">
                                            Siparişiniz Tamamlandı
                                        </h5>
                                    </div>
                                    <div className="text-primary">
                                        <span>Sipariş Numaranız: </span>
                                        <span className="fw-bold">
                                            3689{stateOrder.orderId}
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex flex-column gap-2 orders-item px-4 pt-4">
                                    <div className="header fw-bolder mb-2">
                                        <i className="bi bi-bag text-primary me-2" />{' '}
                                        <span>Satın Aldıklarınız</span>
                                    </div>
                                    {stateOrder &&
                                        stateOrder.basketProducts &&
                                        stateOrder.basketProducts.map((p) => (
                                            <div
                                                className="order-product border rounded-2 overflow-hidden"
                                                key={p.productId}
                                            >
                                                <div className="row g-4 align-items-stretch">
                                                    <div className="col-3">
                                                        <div className="image d-flex align-items-center bg-light h-100">
                                                            <img
                                                                src={
                                                                    p.productImage
                                                                }
                                                                className="img-fluid"
                                                                alt={
                                                                    p.productName
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="py-4 pe-4">
                                                            <div className="title fw-bold">
                                                                {p.productName}
                                                            </div>
                                                            <div className="price d-flex gap-3 text-primary fw-bold">
                                                                <span className="piece text-black-50">
                                                                    {p.quantity}{' '}
                                                                    Adet
                                                                </span>
                                                                <span>
                                                                    {p.campaignPrice !=
                                                                    null
                                                                        ? p.campaignPrice.toFixed(
                                                                              2
                                                                          )
                                                                        : p.discountPrice.toFixed(
                                                                              2
                                                                          )}{' '}
                                                                    TL
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    <div className="order-total d-flex justify-content-between">
                                        <span className="text-black-50">
                                            Toplam Ödenen
                                        </span>
                                        <span className="fw-bold">
                                            {stateTotalAndCampaign.totalPrice &&
                                                stateTotalAndCampaign.totalPrice.toFixed(
                                                    2
                                                )}{' '}
                                            TL
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex gap-3 bg-light mt-4 mx-4 p-4 rounded-2">
                                    <div className="icon h2">
                                        <i className="bi bi-envelope" />
                                    </div>
                                    <div className="text">
                                        <span>
                                            Sipariş detaylarınız
                                            <span className="fw-bold">
                                                {' '}
                                                {userInfo &&
                                                    userInfo.email}{' '}
                                            </span>
                                            e-posta adresinize gönderilmiştir.
                                        </span>
                                    </div>
                                </div>
                                <div className="row g-3 p-4">
                                    <div className="col-sm-6">
                                        {' '}
                                        <a
                                            href="/MyOrders"
                                            className="btn btn-lg btn-outline-secondary rounded-2 w-100"
                                        >
                                            Siparişlerim
                                        </a>
                                    </div>
                                    <div className="col-sm-6">
                                        {' '}
                                        <a
                                            href="/"
                                            className="btn btn-lg btn-primary rounded-2 w-100"
                                        >
                                            Alışverişe Devam Et
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {stateTotalAndCampaign.totalPrice &&
                    stateTotalAndCampaign.totalPrice.toFixed(2)}{' '}
                {stateTotalAndCampaign.code && stateTotalAndCampaign.code}
                {stateTotalAndCampaign.campaignName &&
                    stateTotalAndCampaign.campaignName}
            </section>
        </>
    );
};
export default OrderComplete;
