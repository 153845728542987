import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export default function ToastComponentCenter(props) {
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    useEffect(() => {
        setShowA(props.toastInfo.isShow);
    }, [props.toastInfo]);
    return (
        <ToastContainer
            className="p-3 position-fixed"
            position="top-end"
            style={{ zIndex: 99999 }}
        >
            <Toast
                show={showA}
                onClose={toggleShowA}
                autohide={true}
                delay={3000}
                bg={'primary'}
            >
                <Toast.Header>
                    <img
                        src="/logo.svg"
                        className="rounded me-auto"
                        alt="Logo"
                        style={{ maxWidth: '44px' }}
                    />
                </Toast.Header>
                <Toast.Body className="text-white">
                    <strong>
                        {props.toastInfo && props.toastInfo.productName}
                    </strong>{' '}
                    sepete eklendi.
                </Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
