import React, { useEffect } from 'react';

const KVKKPage = (props) => {
    useEffect(() => {
        document.getElementById('body').className = 'static-page';
    }, []);
    return (
        <section className="page-section py-sm-5 p-4">
            <div className="container">
                <div className="header d-flex flex-column justify-content-center mb-5">
                    <nav
                        aria-label="breadcrumb"
                        style={{
                            '--bs-breadcrumb-divider':
                                " url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                        }}
                    >
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/">Ana Sayfa</a>
                            </li>
                            {/* <li className="breadcrumb-item">
                <a href="index.html">Sayfalar</a>
              </li> */}
                            <li
                                className="breadcrumb-item active"
                                aria-current="page"
                            >
                                Kişisel Verilere İlişkin Aydınlatma Metni
                            </li>
                        </ol>
                    </nav>
                    <div className="h2 fw-bold mb-2 title">
                        <span>Kişisel Verilere İlişkin Aydınlatma Metni</span>
                    </div>
                </div>

                <div className="content">
                    <p>
                        <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                            A.Veri Sorumlusunun Kimliği
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            07.04.2016 tarihinde 6698 sayılı &quot;Kişisel
                            Verilerin Korunması Kanunu&quot; (Bundan b&ouml;yle
                            kısaca &quot;Kanun&rdquo; olarak anılacaktır.)
                            yayınlanarak y&uuml;r&uuml;rl&uuml;ğe girmiştir.
                            S&ouml;z konusu kanun kapsamında Şirketimiz Tesan
                            İletişim A.Ş., (Bundan b&ouml;yle kısaca
                            &quot;TESAN&rdquo; olarak anılacaktır.) &quot;Veri
                            Sorumlusu&rdquo; sıfatına sahip olup, bu sıfatın
                            gerektirdiği y&uuml;k&uuml;ml&uuml;l&uuml;kleri
                            yerine getirmek i&ccedil;in gerekli uyum
                            &ccedil;alışmalarını ger&ccedil;ekleştirmektedir.
                            İşbu bilgilendirme, TESAN&rsquo;ın ger&ccedil;ek
                            kişilere ilişkin aydınlatma
                            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine
                            getirmek &uuml;zere yapılmaktadır. İşbu Aydınlatma
                            Metni gerekli olduğu durumlarda TESAN tarafından
                            g&uuml;ncellenecek ve g&uuml;ncel hali
                            yayımlanacaktır.
                        </span>
                    </p>
                    <p>
                        <a name="_Hlk26791035">
                            <span lang="TR">
                                Kanun kapsamında kişisel veri, kimliği belirli
                                veya belirlenebilir ger&ccedil;ek kişiye ilişkin
                                her t&uuml;rl&uuml; veriyi kapsamaktadır.
                                Kişisel verinin &ouml;zel bir t&uuml;r&uuml;
                                olan &Ouml;zel Nitelikli Kişisel Veri, ırk,
                                etnik k&ouml;ken, siyasi d&uuml;ş&uuml;nce,
                                felsefi inan&ccedil;, din, mezhep, diğer
                                inan&ccedil;lar, kılık ve kıyafet, dernek, vakıf
                                veya sendika &uuml;yeliği, sağlık, cinsel hayat,
                                ceza mahkumiyeti ve g&uuml;venlik tedbirleri,
                                biyometrik ve genetik verileri ifade eder.&nbsp;
                            </span>
                        </a>
                    </p>
                    <p>
                        <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                            B. Veri Toplamanın Y&ouml;ntemi ve Hukuki Sebebi
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Toplanan veriler, otomatik ya da otomatik olmayan
                            y&ouml;ntemlerle, TESAN&rsquo;a farklı kanallardan
                            (internet sitesi, muhtelif s&ouml;zleşmeler, her
                            t&uuml;rl&uuml; bilgi formları, anketler, sosyal
                            medya uygulamaları, &ccedil;ağrı merkezi, hizmet
                            aldığımız iş ortakları vasıtasıyla, her
                            t&uuml;rl&uuml; yazışmalar vs.) s&ouml;zl&uuml;,
                            yazılı veya elektronik yollar ile ilettiğiniz
                            verilerdir. Kişisel verileriniz, tamamen veya kısmen
                            otomatik yollarla ya da veri kayıt sisteminin
                            par&ccedil;ası olmak kaydıyla otomatik olmayan
                            y&ouml;ntemlerle elde edilmektedir.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Bu bilgiler, TESAN&rsquo;ın m&uuml;şterilerine daha
                            iyi ve kaliteli hizmet verebilmesi,ticari ve idari
                            faaliyetlerini iş ve sosyal hayatı d&uuml;zenleyen
                            yasalar &ccedil;er&ccedil;evesinde sunulabilmesi,
                            yasalardan doğan mesuliyetlerini eksiksiz ve doğru
                            bir şekilde yerine getirebilmesi gayesi ile KVKK 5.
                            maddesinde belirtilen kişisel veri işleme şartları
                            ve ama&ccedil;ları dahilinde işlenecektir.&nbsp;
                        </span>
                    </p>
                    <p>
                        <span lang="TR" style={{ textDecoration: 'underline' }}>
                            KVKK 5. maddesinde belirtilen Kişisel Verilerin
                            İşlenme Şartları aşağıda yer almaktadır.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            (1) Kişisel veriler, ilgili kişinin a&ccedil;ık
                            rızası olmaksızın işlenemez.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            (2) Aşağıdaki şartlardan birinin varlığı
                            h&acirc;linde, ilgili kişinin a&ccedil;ık rızası
                            aranmaksızın kişisel verilerinin işlenmesi
                            m&uuml;mk&uuml;nd&uuml;r;
                        </span>
                    </p>
                    <p>
                        <span lang="TR">a)&nbsp;</span>
                        <span lang="TR">
                            Kanunlarda a&ccedil;ık&ccedil;a
                            &ouml;ng&ouml;r&uuml;lmesi,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">b)&nbsp;</span>
                        <span lang="TR">
                            Fiili imk&acirc;nsızlık nedeniyle rızasını
                            a&ccedil;ıklayamayacak durumda bulunan veya rızasına
                            hukuki ge&ccedil;erlilik tanınmayan kişinin
                            kendisinin ya da bir başkasının hayatı veya beden
                            b&uuml;t&uuml;nl&uuml;ğ&uuml;n&uuml;n korunması
                            i&ccedil;in zorunlu olması,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">c)&nbsp;</span>
                        <span lang="TR">
                            Bir s&ouml;zleşmenin kurulması veya ifasıyla
                            doğrudan doğruya ilgili olması kaydıyla,
                            s&ouml;zleşmenin taraflarına ait kişisel verilerin
                            işlenmesinin gerekli olması,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">d)&nbsp;</span>
                        <span lang="TR">
                            Veri sorumlusunun hukuki
                            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml; yerine
                            getirebilmesi i&ccedil;in zorunlu olması,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">e)&nbsp;</span>
                        <span lang="TR">
                            İlgili kişinin kendisi tarafından alenileştirilmiş
                            olması,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">f)&nbsp;</span>
                        <span lang="TR">
                            Bir hakkın tesisi, kullanılması veya korunması
                            i&ccedil;in veri işlemenin zorunlu olması,
                        </span>
                    </p>
                    <p>
                        <span lang="TR">g)&nbsp;</span>
                        <span lang="TR">
                            İlgili kişinin temel hak ve
                            &ouml;zg&uuml;rl&uuml;klerine zarar vermemek
                            kaydıyla, veri sorumlusunun meşru menfaatleri
                            i&ccedil;in veri işlenmesinin zorunlu olması.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            İşbu madde gereğince a&ccedil;ık rıza alınması
                            gereken durumlarda m&uuml;şterilerimizden
                            a&ccedil;ık rıza alınmaktadır. Ancak maddenin 2.
                            fıkrasında yazan şartlara uygun olması kaydı ile
                            ilgili kişinin rızası alınmadan kişisel verilerin
                            işlenmesi m&uuml;mk&uuml;nd&uuml;r. TESAN tarafından
                            &ouml;zel nitelikli kişisel verileriniz
                            işlenmemektedir.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            TESAN&rsquo;ın sahibi olduğu internet sitesine
                            &uuml;ye olduğunuzda ve/veya &uuml;r&uuml;n satın
                            aldığınızda toplanan kişisel verileriniz ad, soyad,
                            doğum tarihi, cinsiyet, vergi dairesi, vergi
                            numarası, T.C. kimlik numarası vs. gibi kimlik
                            bilgileriniz, adres, telefon, elektronik posta
                            adresi, teslimat alıcı bilgileri gibi iletişim
                            bilgileriniz, IP adresi vs. gibi işlem
                            g&uuml;venliği bilgileriniz, sipariş notları vs.
                            gibi m&uuml;şteri işlem bilgisi, pazarlama
                            bilgileridir.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Bilgilerinizi titizlikle korumanın &ouml;nemi ve
                            bilinci ile &ouml;demelerinizin kredi kartı ile
                            işlem ger&ccedil;ekleştirilmesi aşamasında, 3D
                            Secure sistemini kullanmanızı &ouml;neririz.
                            &Ouml;demenin ger&ccedil;ekleştirilmesi i&ccedil;in
                            gerekli olan bilgiler (&Ouml;rnek: Kredi kartı
                            numarası) TESAN tarafından saklanmamaktadır. Bu
                            bilgiler aracısız olarak, anlaşmalı olduğumuz
                            bankaya veya yetkili &ouml;deme kuruluşu
                            &uuml;zerinden sizin tarafınızdan
                            ger&ccedil;ekleştirilmektedir.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Kanunların emredici h&uuml;k&uuml;mleri gereği
                            ger&ccedil;ek kişilerin bilgilerinin doğru ve
                            g&uuml;ncel tutulması esastır. Bu nedenle, belirli
                            zaman aralıkları ile m&uuml;şterilerden kişisel
                            bilgilerini g&uuml;ncellemesi talep edilebilir.
                            M&uuml;şterinin sisteme girdiği t&uuml;m bilgilere
                            sadece m&uuml;şteri ulaşabilmekte ve sadece
                            m&uuml;şteri değiştirebilmektedir. TESAN,
                            hi&ccedil;bir koşulda m&uuml;şteri tarafından
                            sunulan yanlış bilgiden sorumlu tutulamaz.
                            M&uuml;şteri, kayıt olduğu esnada verilen giriş
                            bilgilerini, kullanıcı adını ve şifresini korumakla
                            y&uuml;k&uuml;ml&uuml;d&uuml;r.
                        </span>
                    </p>
                    <p>
                        <a name="_Hlk26791115">
                            <span lang="TR">
                                TESAN&rsquo;ın sahibi olduğu internet
                                sitelerinde; kişilerin sitelerdeki ziyaretlerini
                                ama&ccedil;larına uygun bir şekilde
                                ger&ccedil;ekleştirmelerini temin etmek;
                                kendilerine &ouml;zelleştirilmiş
                                i&ccedil;erikler g&ouml;sterebilmek ve
                                &ccedil;evirim i&ccedil;i reklamcılık
                                faaliyetlerinde bulunabilmek maksadıyla teknik
                                vasıtalarla (&Ouml;rn: cookie uygulaması gibi)
                                site i&ccedil;erisindeki internet hareketleri
                                kaydedilmektedir. Şirketimizin &Ccedil;erez
                                Politikasına internet sitemizden
                                ulaşabilirsiniz.
                            </span>
                        </a>
                    </p>
                    <p>
                        <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                            C.Kişisel Verilerin Hangi Ama&ccedil;la İşleneceği
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Kişisel verileriniz aşağıdaki durumlarda
                            KVKK&rsquo;ya uygun olarak veri sorumlusu ya da
                            atayacağı t&uuml;zel/ger&ccedil;ek kişiler
                            tarafından işlenebilmektedir;
                        </span>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </p>
                    <ul type="disc">
                        <li>
                            <a name="_Hlk26791142">
                                <span lang="TR">
                                    Kimlik doğrulama ve kayıt oluşturulması
                                    amacıyla,
                                </span>
                            </a>
                            &nbsp; &nbsp; &nbsp;&nbsp;
                        </li>
                        <li>
                            <span lang="TR">
                                Siparişlerinizin işleme konulması ve hesabınızın
                                y&ouml;netilmesi, ticari faaliyetlerin yerine
                                getirilebilmesi ve s&uuml;rekliliğinin
                                sağlanabilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Alınan hizmet s&uuml;re&ccedil;leri ile alakalı
                                bilgilendirme amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Siparişlerin teslimi/iadesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Siparişler ile ilgili şikayetlerin
                                &ccedil;&ouml;z&uuml;lmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Teknik servis hizmetinin verilebilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                &Ouml;nceden onay vermeniz kaydıyla yeni
                                &uuml;r&uuml;nler, kampanyalar ve promosyonlar
                                hakkında bilgi vermek i&ccedil;in elektronik
                                ileti g&ouml;nderilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                İşlemlerinizle ilgili istatistiklerin
                                yapılabilmesi ve bağlantılı listelerin
                                oluşturulması, ticari istatistik ve analizlerin
                                bir araya getirilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                &Uuml;r&uuml;nlerimizin, hizmetlerimizin ve
                                kişisel se&ccedil;im olanaklarınızın
                                araştırılması ve geliştirilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Yazılım, kurumsal kaynak planlaması, raporlama,
                                pazarlama vs. gibi işlevlerin yerine getirilmesi
                                amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Promosyon ve hediye verilebilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Gerekli kalite, gizlilik ve standart
                                denetimlerinin yapabilmesi amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kanun ve y&ouml;netmelikler ile belirlenmiş
                                gerekliliklerin ifa edilmesi amacıyla (vergi
                                mevzuatı, t&uuml;keticilerin korunmasına
                                y&ouml;nelik mevzuat, bor&ccedil;lar hukuku
                                mevzuatı, ticaret hukuku mevzuatı,
                                g&uuml;mr&uuml;k mevzuatı, elektronik iletişim
                                ile ilgili mevzuat vs. ilgili t&uuml;m
                                mevzuatlar),
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                e-fatura, e-arşiv ve e-irsaliye ile ilgili
                                y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine
                                getirilmesi amacıyla ,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Yasal d&uuml;zenlemelerin gerektirdiği veya
                                zorunlu kıldığı şekilde kamu kurum ve
                                kuruluşlarının taleplerinin yerine getirilmesi
                                amacıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                KVKK&rsquo;da belirtilmiş yasal
                                y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine
                                getirilmesi amacıyla,
                            </span>
                        </li>
                    </ul>
                    <p>
                        <a name="_Hlk505261575">
                            <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                                D.İşlenen Kişisel Verilerin Kimlere ve Hangi
                                Ama&ccedil;la Aktarılabileceği
                            </span>
                        </a>
                    </p>
                    <p>
                        <span lang="TR">
                            İşlenen kişisel verileriniz zorunlu olan bilgiler
                            ile sınırlı olmak kaydı ile;
                        </span>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="TR">
                                Siparişlerinizin işleme konulması, hesabınızın
                                y&ouml;netilmesi, ticari faaliyetlerin yerine
                                getirilebilmesi ve s&uuml;rekliliğinin
                                sağlanabilmesi amacıyla hizmet sağlayıcılarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Siparişlerin teslim/iadesi amacıyla lojistik ve
                                kargo firmalarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Yazılım, kurumsal kaynak planlaması, raporlama,
                                pazarlama vb. gibi işlevlerin yerine getirilmesi
                                amacıyla tedarik&ccedil;ilerle ve
                                &ccedil;&ouml;z&uuml;m ortaklarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                &Ouml;deme/tahsilat hizmetlerinin yerine
                                getirilmesi amacıyla bankalar ve &ouml;deme
                                aracılık sistemleri şirketleriyle,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Gerekli kalite, gizlilik ve standart
                                denetimlerinin yapılabilmesi amacıyla denetim
                                firmaları ve bilgi g&uuml;venliği firmalarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                TESAN&rsquo;ın kullandığı bilişim teknolojileri
                                gereği bulut bilişim hizmetini veren firmalarla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                TESAN tarafından kullanılan ERP vs. diğer
                                bilişim sistemlerinin işlerliği ve
                                g&uuml;venirliliği a&ccedil;ısından ilgili
                                tedarik&ccedil;ilerle ve iş ortaklarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                İlgili mevzuat h&uuml;k&uuml;mlerine istinaden
                                y&uuml;k&uuml;ml&uuml;l&uuml;klerimizin yerine
                                getirilmesi ve ticari faaliyetlerimizin denetimi
                                amacıyla ilgili denetim firmalarıyla, &ouml;zel
                                entegrat&ouml;r firmalarıyla, bağımsız denetim
                                firmalarıyla, g&uuml;mr&uuml;k firmalarıyla,
                                mali m&uuml;şavir/muhasebe firmalarıyla, hukuk
                                b&uuml;rolarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Promosyon ve hediye verilebilmesi amacıyla
                                ilgili iş ortakları ve tedarik&ccedil;ilere,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                TESAN&rsquo;ın ortakları/hissedarlarıyla,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Yasal gereklilikleri ifa etmek ve/veya resmi
                                mercilerin taleplerini yerine getirmek amacıyla
                                kamu kurum ve kuruluşlarıyla.
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span lang="TR">
                            Kanunen a&ccedil;ık rıza alınması gereken hallerde
                            a&ccedil;ık rıza alınarak (kanunen a&ccedil;ık rıza
                            alınması gerekli olmayan durumlar hari&ccedil; olmak
                            kaydı ile) ve kanun ile belirlenmiş koşullar
                            &ccedil;er&ccedil;evesinde aktarılabilecektir.
                        </span>
                    </p>
                    <p>
                        <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                            E.S&uuml;re
                        </span>
                    </p>
                    <p>
                        <a name="_Hlk510174176">
                            <span lang="TR">
                                TESAN, m&uuml;şterilerine ait kişisel bilgileri
                                ticari ilişki devam ettiği s&uuml;rece
                                yukarıdaki ama&ccedil;lar doğrultusunda
                                saklayacaktır. TESAN, ticari ilişki sona
                                erdikten sonra T&uuml;rk Ticaret Kanuna
                                g&ouml;re 10 yıl boyunca verileri saklamakla
                                y&uuml;k&uuml;ml&uuml;d&uuml;r. S&uuml;re
                                ge&ccedil;tikten sonra kişisel verileriniz ilk
                                imha s&uuml;resinde Kişisel Veri Saklama ve İmha
                                Politikası uyarınca silinecek, yok edilecek
                                ve/veya anonim hale getirilecektir.
                                M&uuml;şteri, ilgili kanunun 5. maddesi 2.
                                fıkrasındaki şartlar ve kanunen işlenmesi
                                gereken veriler hari&ccedil; olma kaydı ile
                                kişisel verilerinin işlenmesi i&ccedil;in
                                verdiği izni her zaman geri alabilir.
                            </span>
                        </a>
                    </p>
                    <p>
                        <span lang="EN-US" style={{ fontWeight: 'bold' }}>
                            F.Veri Sahibinin Hakları
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            KVKK kapsamında, kişisel verilerinize ilişkin olarak
                            aşağıdaki haklara sahipsiniz;
                        </span>
                        &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                    </p>
                    <ul type="disc">
                        <li>
                            <span lang="TR">
                                Kişisel verilerin işlenip işlenmediğini
                                &ouml;ğrenme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kişisel verileri işlenmişse buna ilişkin bilgi
                                talep etme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kişisel verilerin işlenme amacını ve bunların
                                amacına uygun kullanılıp kullanılmadığını
                                &ouml;ğrenme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Yurt i&ccedil;inde veya yurt dışında kişisel
                                verilerin aktarıldığı
                                &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kişisel verilerin eksik veya yanlış işlenmiş
                                olması h&acirc;linde bunların
                                d&uuml;zeltilmesini isteme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kişisel verinizin işlenmesini gerektiren
                                sebeplerin ortadan kalkması h&acirc;linde,
                                verilerin silinmesini veya yok edilmesini
                                isteme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Talebinizce d&uuml;zeltilen ya da silinen
                                bilgilerinizin, eğer aktarılmış ise kişisel
                                verilerin aktarıldığı
                                &uuml;&ccedil;&uuml;nc&uuml; kişilere
                                bildirilmesini isteme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                İşlenen verilerin m&uuml;nhasıran otomatik
                                sistemler vasıtasıyla analiz edilmesi suretiyle
                                kişinin kendisi aleyhine bir sonucun ortaya
                                &ccedil;ıkmasına itiraz etme,
                            </span>
                        </li>
                        <li>
                            <span lang="TR">
                                Kişisel verilerin kanuna aykırı olarak işlenmesi
                                sebebiyle zarara uğraması h&acirc;linde zararın
                                giderilmesini talep etme.
                            </span>
                        </li>
                    </ul>
                    <p>
                        <span lang="TR">
                            Yukarıda belirtilen haklarınızı kullanmak
                            i&ccedil;in kimliğinizi tespit edici gerekli
                            bilgiler ve kullanmak istediğiniz hakkınıza
                            y&ouml;nelik a&ccedil;ıklamalarınızla birlikte
                            yazılı talebinizi &quot;&Ccedil;oban&ccedil;eşme
                            Mah. Bilge 1 Sok. No:17 Yenibosna
                            Bah&ccedil;elievler/İstanbul&rdquo; adresine konunun
                            KVKK ile ilgili olduğunu a&ccedil;ık&ccedil;a
                            belirterek ıslak imzalı olarak veya{' '}
                            <a href="mailto:tesan@hs01.kep.tr">
                                tesan@hs01.kep.tr
                            </a>{' '}
                            kayıtlı elektronik posta adresimize g&uuml;venli
                            elektronik imza, mobil imza ya da ilgili kişi
                            tarafından TESAN&rsquo;a daha &ouml;nce bildirilen
                            ve veri sorumlusunun sisteminde kayıtlı bulunan
                            elektronik posta adresini kullanmak suretiyle
                            g&ouml;nderebilirsiniz.Başvurular T&uuml;rk&ccedil;e
                            yapılmalıdır.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Başvurularda, ad, soyad ve başvuru yazılı ise imza,
                            T&uuml;rkiye Cumhuriyeti vatandaşları i&ccedil;in
                            T.C. kimlik numarası, yabancılar i&ccedil;in uyruğu,
                            pasaport numarası/kimlik numarası, tebligata esas
                            yerleşim yeri veya işyeri adresi, varsa bildirime
                            esas elektronik posta adresi, telefon veya faks
                            numarası ve talep konusu bulunması zorunludur.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Kişisel veri sahibi olarak sahip olduğunuz ve
                            yukarıda belirtilen haklarınızı kullanmak
                            i&ccedil;in yapacağınız ve kullanmayı talep
                            ettiğiniz hakka ilişkin a&ccedil;ıklamalarınızı
                            i&ccedil;eren başvuruda; talep ettiğiniz hususun
                            a&ccedil;ık ve anlaşılır olması, talep ettiğiniz
                            konunun şahsınız ile ilgili olması veya başkası
                            adına hareket ediyor iseniz bu konuda &ouml;zel
                            olarak yetkili olmanız ve yetkinizin
                            belgelendirilmesi, başvurunun kimlik ve adres
                            bilgilerini i&ccedil;ermesi ve başvuruya kimliğinizi
                            tevsik edici belgelerin eklenmesi gerekmektedir.
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Bu kapsamda yapacağınız başvurular en kısa
                            s&uuml;rede ve en ge&ccedil; 30 g&uuml;n
                            i&ccedil;erisinde sonu&ccedil;landırılacaktır.
                            İlgili kişinin başvurusuna yazılı olarak cevap
                            verilecekse, on sayfaya kadar &uuml;cret alınmaz. On
                            sayfanın &uuml;zerindeki her sayfa i&ccedil;in 1 TL
                            işlem &uuml;creti alınabilir. Başvuruya cevabın CD,
                            flaş bellek gibi bir kayıt ortamında verilmesi
                            halinde TESAN tarafından talep edilebilecek
                            &uuml;cret kayıt ortamının maliyetini
                            ge&ccedil;emez.
                        </span>
                    </p>
                    <p>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            REFERANS ve İLGİLİ DOK&Uuml;MANLAR
                        </span>
                    </p>
                    <p>
                        <span lang="TR">&middot;&nbsp;</span>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            Referans
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            07.04.2016 tarihli 6698 sayılı &quot;Kişisel
                            Verilerin Korunması Kanunu&rdquo; ve ilgili
                            Y&ouml;netmelikler
                        </span>
                    </p>
                    <p>
                        <span lang="TR">&middot;&nbsp;</span>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            İlgili Dok&uuml;manlar
                        </span>
                    </p>
                    <p>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            KVKK FRM HK-05&nbsp;
                        </span>
                        <span lang="TR">
                            Ger&ccedil;ek Kişi T&uuml;keticilere Y&ouml;nelik
                            Kişisel Verilerin Toplanması A&ccedil;ık Rıza Metni
                        </span>
                    </p>
                    <p>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            KVKK PLT-03 &nbsp;
                        </span>
                        <span lang="TR">&Ccedil;erez Politikası</span>
                    </p>
                    <p>
                        <span lang="TR" style={{ fontWeight: 'bold' }}>
                            REVİZYON TAKİBİ
                        </span>
                    </p>
                    <p>
                        <span lang="TR">
                            Bu b&ouml;l&uuml;mde dok&uuml;manın zamanla değişen
                            ihtiya&ccedil;lara g&ouml;re revize edilmesi
                            durumunda bu revizyonlara ilişkin bilgiler yer alır.
                            Revizyon numarası, revizyonun gerek&ccedil;esi,
                            revize edilen ya da eklenen hususlar, değişikliği
                            isteyen birim ya da kişiler belirtilir.
                        </span>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default KVKKPage;
