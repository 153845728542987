import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import HomepageProducts from '../homepage/HomepageProducts';
import Navigation from '../navigation/Navigation';
import Footer from '../navigation/Footer';
import ProductDetail from '../product/ProductDetail';
import Product from '../product/Product';
import Hakkimizda from '../common/Hakkimizda';
import IptalveIade from '../common/IptalveIade';
import OdemeveTeslimat from '../common/OdemeveTeslimat';
import Iletisim from '../common/Iletisim';
import CustomerPolicy from '../common/CustomerPolicy';
import PersonalDataPolicy from '../common/PersonalDataPolicy';
import PreInformationForm from '../common/PreInformationForm';
import SalesAgreement from '../common/SalesAgreement';
import Login from '../login/Login';
import CreateCustomer from '../login/CreateCustomer';
import ResetPassword from '../login/ResetPassword';
import Profile from '../user/Profile';
import SliderBanner from '../sliderBanner/SliderBanner';
//import HomePageBlogPosts from '../homepage/HomePageBlogPosts';
//import BlogsList from '../blogs/BlogsList';
//import BlogDetails from '../blogs/BlogDetails';
import Basket from '../basket/Basket';
import Category from '../category/Category';
import AddressCheck from '../basket/AddressCheck';
import Checkoutpayment from '../basket/Checkoutpayment';
import SearchProducts from '../navigation/SearchProducts';
import MiniCart from '../navigation/MiniCart';
//import HomePageCategories from '../homepage/HomePageCategories';
//import JumbotronBanner from '../homepage/JumbotronBanner';
import SiteAdvantage from '../homepage/SiteAdvantage';
import KVKKPage from '../staticPages/kvkkPage';
import PersonalInfo from '../user/PersonalInfo';
import MyAddressList from '../user/MyAddressList';
import MyOrders from '../user/MyOrders';
import OrderComplete from '../basket/OrderComplete';
//import customerPolicy from '../common/CustomerPolicy';
import MiniBanner from '../sliderBanner/MiniBanner';
import ToastComponentCenter from '../homepage/SubComponents/ToastComponentCenter';
import RegisterCompleted from '../login/RegisterCompleted';
import MailValidationSuccess from '../user/MailValidationSuccess';
import MailValidationError from '../user/MailValidationError';
import OrderError from '../basket/OrderError';
import TagManager from 'react-gtm-module';
import OrderDetails from '../user/orderDetail/OrderDetails';
//import Promotion from '../common/Promotion';
// import WhereToBuy from '../staticPages/wheretobuy';
// import Hellottec from '../common/Hellottec';
// import Lumi from '../videoPages/Lumi';
// import Prizi from '../videoPages/Prizi';
// import Sensi from '../videoPages/Sensi';
// import SensiPro from '../videoPages/SensiPro';
// import Navi from '../videoPages/Navi';
// import NaviPro from '../videoPages/NaviPro';
// import WiziPro from '../videoPages/WiziPro';
// import RobiPro from '../videoPages/RobiPro';
// import RobiProMaster from '../videoPages/RobiProMaster';
// import Guarantee from '../common/Guarantee';

const tagManagerArgs = {
    gtmId: 'GTM-KRKTPWM',
    dataLayer: {}
};

const App = (props) => {
    let location = useLocation();
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, [location]);

    const [displaySearchedProducts, setDisplaySearchedProducts] =
        useState('none');
    const openSearch = () => {
        setDisplaySearchedProducts('block');
    };
    const closeSearch = () => {
        setDisplaySearchedProducts('none');
    };
    const [toastOpenedInfo, setToastOpenedInfo] = useState({ isShow: false });
    const openToastHandler = (productInfo) => {
        setToastOpenedInfo(productInfo);
    };

    const cookies = new Cookies();

    if (location.pathname != '/basket') {
        cookies.remove('changeBasketCookie');
    }

    return (
        <>
            <Navigation openSearch={() => openSearch()} />
            <ToastComponentCenter toastInfo={toastOpenedInfo} />
            <SearchProducts
                display={displaySearchedProducts}
                closeSearch={() => closeSearch()}
            />
            <Routes>
                <Route exact path="/" element={<HomePage />}>
                    <Route
                        index
                        element={
                            <>
                                <SliderBanner />
                                {/*<HomePageCategories />*/}
                                <HomepageProducts
                                    openToast={(productInfo) =>
                                        openToastHandler(productInfo)
                                    }
                                />
                                {/* <JumbotronBanner /> */}
                                <MiniBanner />
                                {/* <HomePageBlogPosts /> */}
                                <SiteAdvantage />
                            </>
                        }
                    />
                    {/* <Route path="Blogs" element={<BlogsList />} />
                    <Route exact path="BlogDetail">
                        <Route path=":id" element={<BlogDetails />} />
                    </Route> */}
                    <Route exact path="detail">
                        <Route
                            path=":id"
                            element={
                                <ProductDetail
                                    openToast={(productInfo) =>
                                        openToastHandler(productInfo)
                                    }
                                />
                            }
                        />
                    </Route>
                    <Route exact path="category">
                        <Route
                            path=":id"
                            element={
                                <Category
                                    openToast={(productInfo) =>
                                        openToastHandler(productInfo)
                                    }
                                />
                            }
                        />
                    </Route>
                </Route>
                <Route path="Hakkimizda" element={<Hakkimizda />} />
                <Route path="IptalveIade" element={<IptalveIade />} />
                <Route path="OdemeveTeslimat" element={<OdemeveTeslimat />} />
                <Route path="Iletisim" element={<Iletisim />} />
                <Route path="Login" element={<Login />} />
                <Route path="ResetPassword" element={<ResetPassword />} />
                <Route path="CreateCustomer" element={<CreateCustomer />} />
                <Route path="Profile" element={<Profile />} />
                {/* <Route path="kurumsal-satis" element={<Promotion />} />
                <Route path="satis-noktalari" element={<WhereToBuy />} />
                <Route path="garanti" element={<Guarantee />} />
                <Route path="lumi" element={<Lumi />} />
                <Route path="prizi" element={<Prizi />} />
                <Route path="sensi" element={<Sensi />} />
                <Route path="sensi-pro" element={<SensiPro />} />
                <Route path="wizipro" element={<WiziPro />} />
                <Route path="navi" element={<Navi />} />
                <Route path="navi-pro" element={<NaviPro />} />
                <Route path="robi-promaster" element={<RobiPro />} />
                <Route path="robi-promaster-plus" element={<RobiProMaster />} /> */}
                <Route
                    path="RegisterCompleted"
                    element={<RegisterCompleted />}
                />
                <Route
                    path="MailValidationSuccess"
                    element={<MailValidationSuccess />}
                />
                <Route
                    path="MailValidationError"
                    element={<MailValidationError />}
                />
                <Route path="basket" element={<Basket />} />
                <Route path="AddressCheck" element={<AddressCheck />} />
                <Route path="Login" element={<Login />} />
                <Route path="Checkoutpayment" element={<Checkoutpayment />} />
                <Route path="KVKKPage" element={<KVKKPage />} />
                <Route path="PersonalInfo" element={<PersonalInfo />} />
                <Route path="MyAddressList" element={<MyAddressList />} />
                <Route path="MyOrders" element={<MyOrders />}>
                    <Route path="OrderDetails" element={<OrderDetails />} />
                </Route>

                <Route path="OrderComplete" element={<OrderComplete />} />
                <Route path="OrderError" element={<OrderError />} />
                <Route path="CustomerPolicy" element={<CustomerPolicy />} />
                <Route
                    path="PersonalDataPolicy"
                    element={<PersonalDataPolicy />}
                />
                <Route
                    path="PreInformationForm"
                    element={<PreInformationForm />}
                />
                <Route path="SalesAgreement" element={<SalesAgreement />} />
            </Routes>
            <Footer />
        </>
    );
};
export default App;

function HomePage() {
    return <Outlet />;
}
