import React, { useState, useEffect } from 'react';
import FeaturedProducts from './FeaturedProducts';
import axios from 'axios';
import { API_BASE } from '../../config/env';
import HomePageProductModal from './HomePageProductModal';

export const HomepageProducts = (props) => {
    const [homepageProductsState, setHomepageProductsState] = useState({
        homepageCategories: [],
        featuredProducts: [],
        //bestSellersProducts: [],
        //newProducts: [],
        featuredProductsCategoryId: 0
        //bestSellersProductsCategoryId: 0,
        //newProductsCategoryId: 0,
    });
    const [modalState, setModalState] = useState({
        isOpen: false,
        openProductId: 0,
        userInfo: {}
    });
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleProductId = (id) => {
        setModalState({
            isOpen: true,
            openProductId: id,
            userInfo: userInfo
        });
        setShow(true);
    };
    const [userInfo, setUserInfo] = useState({});
    const showToastHandle = (productName) => {
        props.openToast({ productName: productName, isShow: true });
        //setProductState({productName:productName});
    };
    const [productState, setProductState] = useState();
    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${API_BASE}HomePage/GetHomePageProducts`)
                .then((data) => {
                    //Categories.
                    const featuredProductsCategory =
                        data.data.set.categories.filter(
                            (c) => c.name == 'Öne Çıkanlar'
                        );
                    const bestSellerCategory = data.data.set.categories.filter(
                        (c) => c.name == 'En Çok Satanlar'
                    );
                    const newProductsCategory = data.data.set.categories.filter(
                        (c) => c.name == 'Yeni Ürünler'
                    );
                    //Featured Products
                    const featuredProducts = data.data.set.products
                        .filter(
                            (p) =>
                                p.categoryProductMappings.filter(
                                    (cpm) => cpm.category.name == 'Öne Çıkanlar'
                                ).length > 0
                        )
                        .sort((a, b) =>
                            a.productProductAttributeValueMappings
                                .productAttributeValueId >
                            b.productProductAttributeValueMappings
                                .productAttributeValueId
                                ? 1
                                : -1
                        )
                        .sort((a) =>
                            a.productProductAttributeValueMappings
                                .productAttributeValueId == 38
                                ? -1
                                : 1
                        );
                    //Bestseller products
                    const bestSellersProducts = data.data.set.products.filter(
                        (p) =>
                            p.categoryProductMappings.filter(
                                (cpm) => cpm.category.name === 'En Çok Satanlar'
                            ).length > 0
                    );
                    //Yeni Ürünler
                    const newProducts = data.data.set.products.filter(
                        (p) =>
                            p.categoryProductMappings.filter(
                                (cpm) => cpm.category.name === 'Yeni Ürünler'
                            ).length > 0
                    );
                    setHomepageProductsState({
                        featuredProducts: featuredProducts,
                        featuredProductsCategoryId:
                            featuredProductsCategory[0].id
                        //bestSellersProducts: bestSellersProducts,
                        //bestSellersProductsCategoryId: bestSellerCategory[0].id,
                        //newProducts: newProducts,
                        //newProductsCategoryId: newProductsCategory[0].id,
                    });
                });
            //console.log(homepageProductsState);
        }
        fetchData();

        const userToken = localStorage.getItem('token');
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };

        axios
            .get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            )
            .then((data) => {
                if (data.data) {
                    if (data.data.code == '200') {
                        setUserInfo(data.data.set);
                    }
                }
            });
    }, []);

    return (
        <>
            <FeaturedProducts
                products={homepageProductsState.featuredProducts}
                openedProductId={(id) => handleProductId(id)}
                categoryId={homepageProductsState.featuredProductsCategoryId}
                userInfo={userInfo}
            />
            {/* <BestSellers
        products={homepageProductsState.bestSellersProducts}
        openedProductId={(id) => handleProductId(id)}
        categoryId={homepageProductsState.bestSellersProductsCategoryId}
      />
      <NewProducts
        products={homepageProductsState.newProducts}
        openedProductId={(id) => handleProductId(id)}
        categoryId={homepageProductsState.newProductsCategoryId}
      />
      */}
            <HomePageProductModal
                showToast={(productName) => showToastHandle(productName)}
                openProductId={modalState.openProductId}
                show={show}
                handleClose={() => handleClose()}
                userInfo={userInfo}
            />
        </>
    );
};
export default HomepageProducts;
