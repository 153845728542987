import { FETCH_HOMEPAGECAETGORIES_FULFILLED, FETCH_HOMEPAGEPRODUCTS_FULFILLED } from "../actions/homepage";
const initialState = {
    fetching: false,
    fetched: false,
    homepageProducts: [],
    error: {}
};
export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOMEPAGEPRODUCTS_FULFILLED:
            return {
                ...state,
                homepageProducts: action.payload
            }
        case FETCH_HOMEPAGECAETGORIES_FULFILLED:
            return {
                ...state,
                homePageCategories: action.payload
            }
        default:
            return state;
    }

}