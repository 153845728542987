import Cookies from "universal-cookie";
const cookies = new Cookies();
//Bütün sepet işlemleri burada yapılacak.
/*
{
    basketId: 0,
    basketProducts: [
      {
        productId: "",
        productImage: "",
        discountPrice: 0,
        price: 0,
        quantity:1,
        variantInfo: [
          {
            productAttributeId: 0,
            productAttributeValue: "",
            productAttributeValueId: 0,
            productAttributeValueValue: "",
            price: 0.0,
            stockId: "",
            variantPictureId: 0,
            variantPicturePath: "",
          },
        ],
      },
    ],
  }



*/
//Sepet İşlemleri:
//Sepet oluşturma.
//Sepet silme.
export function createBasket(basketItem) {
  //basket adında cookie oluşturur.
  const basket = {
    basketId: 0,
    basketProducts: [basketItem],
  };
  cookies.set("basket", basket, { path: "/" });
}
export function deleteBasket() {
  //cookie'deki sepeti siler.
  cookies.remove("basket");
}
export function getCurrentBasket() {
  //mevcut sepeti döner.
  return cookies.get("basket");
}
//Sepet Satırları işlemleri:
//Sepet'e satır ekleme.
//Sepet'te satır güncelleme
//Sepet'ten satır silme.
export function GetBasketItem(basketItem = {}) {
  //ProductItem mevcut mu bilgisi
  //Varsa basketItem döner yoksa null döner.
  const currentBasket = cookies.get("basket");
  if (currentBasket) {
    if (currentBasket.basketProducts) {
      const basketItemExists = currentBasket.basketProducts.map(
        (bi) => bi.productId === basketItem.productId
      );
      if (basketItemExists != null) return basketItemExists;
    }
  }
  return null;
}
export function AddItemToBasket(basketItem = {},count) {
  //mevcut sepete yeni ürün ekleme.
  //1. Ürün'ün var olup olmadığına bakılır.
  const currentBasket = cookies.get("basket");
  if (!currentBasket.basketProducts) {
    //Daha önce basketProducts oluşturulmamış ise.
    currentBasket.basketProducts = [basketItem];
  } else {
      //Ürün varsa miktarını arttır.
      const isProductExists = currentBasket.basketProducts.filter(p => p.productId === basketItem.productId && p.stockId == basketItem.stockId)[0];
    if(isProductExists){
      isProductExists.quantity+=count;
    }
    else{
      currentBasket.basketProducts.push(basketItem);
    } 
    // Yoksa ekle
    cookies.set("basket",currentBasket, { path: "/" });
    console.log(currentBasket.basketProducts, " ürün eklendi")
  }
}
export function UpdateItemToBasket(basketItem = {}) {
  //mevcut sepetteki ürünün miktarını güncelleme.
  const currentBasket = cookies.get("basket");
  var basketProduct = currentBasket.basketProducts.filter(
    (bp) => bp.productId === basketItem.productId
  );
  basketProduct.quantity = basketItem.quantity;
}
export function DeleteItemFromBasket(basketItem = {}) {
  //mevcut sepetteki ürünü silme.
  const currentBasket = cookies.get("basket");
  var willDeleteBasketItem = currentBasket.basketProducts.filter(
    (bp) => bp.productId === basketItem.productId
  );
  currentBasket.basketProduct.remove(willDeleteBasketItem);
}
