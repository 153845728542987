import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_BASE } from '../../config/env';
import PersonalInfoNavigation from './PersonalInfoNavigation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PersonalInfo = (props) => {
    const [userState, setUserState] = useState({
        email: '',
        password: '',
        phone: '',
        tcKimlik: '',
        firstName: '',
        lastname: ''
    });
    useEffect(() => {
        const userToken = localStorage.getItem('token');
        if (userToken) {
            const config = {
                headers: { 'Content-Type': 'application/json' }
            };
            Axios.get(
                `${API_BASE}Customer/GetCustomerByToken?token=${userToken}`,
                config
            ).then((data) => {
                setUserState({
                    ...userState,
                    ...data.data.set
                });
                if (data.data) {
                    if (data.data.code == '201') {
                        window.location.href = '/Login';
                    } else if (data.data.code == '200') {
                    }
                }
            });
        } else {
            window.location.href = '/Login';
        }
    }, [props]);

    const customerFormsChange = (e) => {
        setUserState({
            ...userState,
            [e.target.name]: e.target.value
        });
    };

    const KeyCheck = (e) => {
        if (e.target.id == 'phoneNumberEdit') {
            if (document.getElementById('phoneNumberEdit').value.length <= 2) {
                document.getElementById('phoneNumberEdit').value = '+90';
            } else {
                customerFormsChange(e);
            }
        }
    };

    const btnCreateClicked = (e) => {
        if (
            !userState.firstName &&
            !userState.lastname &&
            !userState.email &&
            !userState.phone &&
            !userState.tcKimlik &&
            !userState.password
        ) {
            alert('Tüm Alanları Doldurunuz!');
            return false;
        }

        if (userState.tcKimlik.length != 11) {
            alert('Tc Numarası 11 haneli olmalıdır!');
            return false;
        }

        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        Axios.post(`${API_BASE}Customer/EditCustomer`, userState, config).then(
            (data) => {
                if (data.data) {
                    if (data.data.code == '201') {
                    } else if (data.data.code == '200') {
                        setUserState({
                            ...userState,
                            ...data.data
                        });

                        window.location.href = '/Profile';
                    }
                }
            }
        );
    };
    return (
        <section className="orderlist user-section py-sm-5 p-4">
            <div className="container">
                <div className="row">
                    <PersonalInfoNavigation user={userState} />
                    <div className="col-sm-9">
                        <div className="last-order-list">
                            <div className="header d-flex align-items-center mb-4">
                                <div className="h5 fw-bold">
                                    <i className="bi bi-person-badge me-2 text-primary"></i>
                                    <span>Hesap Düzenleme</span>
                                </div>
                            </div>
                            <form>
                                <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                    Kişisel Bilgiler
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="firstName"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        Adınız
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="Adınız"
                                            defaultValue={
                                                userState && userState.firstName
                                            }
                                            onChange={(e) =>
                                                customerFormsChange(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="lastname"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        Soyadınız
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastname"
                                            name="lastname"
                                            placeholder="Soyadınız"
                                            defaultValue={
                                                userState && userState.lastname
                                            }
                                            onChange={(e) =>
                                                customerFormsChange(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="email"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        E-Posta
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="E-Posta Adresiniz"
                                            defaultValue={
                                                userState && userState.email
                                            }
                                            onChange={(e) =>
                                                customerFormsChange(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="phone"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        Cep Telefonu
                                    </label>
                                    <div className="col-sm-9">
                                        {/* <input
                      type="tel"
                      className="form-control"
                                          id="phone"
                                          name="phone"
                      placeholder="Cep Telefonu (GSM) No"
                                          defaultValue={userState && userState.phone}
                                          onChange={(e) => customerFormsChange(e)} 
                    /> */}
                                        <PhoneInput
                                            onlyCountries={['tr']}
                                            country={'tr'}
                                            value={'+9' + userState.phone}
                                            inputProps={{
                                                name: 'phoneNumber',
                                                id: 'phoneNumberEdit',
                                                className:
                                                    'form-control w-100 rounded-2 py-4',
                                                placeholder:
                                                    'Cep Telefonu (GSM) No',
                                                onKeyUp: (e) => {
                                                    KeyCheck(e);
                                                },
                                                onClick: (e) => {
                                                    KeyCheck(e);
                                                }
                                            }}
                                            defaultValue={
                                                '+9' + userState.phone
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="tcKimlik"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        T.C Kimlik No
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="tcKimlik"
                                            name="tcKimlik"
                                            step="1"
                                            placeholder="T.C. Kimlik Numaranız"
                                            defaultValue={
                                                userState && userState.tcKimlik
                                            }
                                            onChange={(e) =>
                                                customerFormsChange(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-subheader fw-bold h5 mb-3 mt-5">
                                    Şifre Değiştir
                                </div>
                                <div className="row mb-3">
                                    <label
                                        htmlFor="password"
                                        className="col-sm-3 d-none d-sm-block col-form-label"
                                    >
                                        Yeni Şifre
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Yeni Şifre"
                                            onChange={(e) =>
                                                customerFormsChange(e)
                                            }
                                        />
                                        <div
                                            id="newpasswordHelp"
                                            className="form-text"
                                        >
                                            Bu alanı boş bıraktığınız taktirde
                                            şifreniz güncellenmeyecektir.
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex gap-2 my-5 justify-content-end">
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-light"
                                    >
                                        Geri Dön
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={btnCreateClicked}
                                    >
                                        Kaydet
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default PersonalInfo;
